'use strict';

var page = require('../page'),
    login = require('../login'),
    util = require('../util'),
    dialog = require('../dialog'),
    ajax = require('../ajax');

function removeFromWishListHandler() {
    $('.js-wishlist-removeitem').on('click', function (event) {
    	var anchor = $(event.currentTarget);
    	event.preventDefault();
    	var pid = anchor.data('pid');

		// init ajax call
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.wishlistRemoveItem, {
				pid: pid,
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						anchor.hide();
						var src = anchor.data('source');
						if (src == 'cart') {
							$('.addtocart-'+pid).show();
						} else if (src == 'pdp') {
							$('.js-wishlist-add').show();
						}
					}
				}
			}
		});
    });
    
    $('.js-cartwishlist-add').on('click', function (event) {
    	event.preventDefault();
    	var anchor = $(event.currentTarget);
    	var pid = anchor.data('pid');
    	var qty = $(this).closest('.js-cart-lineitem').find('.js-cart-quantity-select').val();
        var isVariationNotAttrSeleted = ($('.js-no-variation-attr-selected').length > 0) ? true : false;
        if (isVariationNotAttrSeleted) {
            return;
        }
		// init ajax call
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.cartWishlistAddItem, {
				pid: pid,
				qty: qty
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						anchor.hide();
						$('.removefromcart-'+pid).show();
					}
				}
			}
		});
    });
}

function removeWistlistItem(wishlistForm, button) {
    $('<input/>').attr({
        type: 'hidden',
        name: button.getAttribute('name'),
        value: button.getAttribute('value') || 'deleteItem'
    }).appendTo($(wishlistForm));
    var data = $(wishlistForm).serialize();
    $.ajax({
        type: 'POST',
        url: $(wishlistForm).attr('action'),
        data: data
    })
    .done(function () {
        page.reload();
    });
}


exports.removeFromWishListHandler = removeFromWishListHandler;