'use strict';

// var addProductToCart = require('./product/addToCart'),
var	page = require('../page'),
	login = require('../login'),
	util = require('../util'),
	dialog = require('../dialog'),
	ajax = require('../ajax'),
	account = require('./account');
function addToFavoritesAnimation() {
	$('.favorite-header__icon').addClass('favorite-header__icon--fill');
	$('.js-favorite-header').addClass('added-to-favorite').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
		$(this).removeClass('added-to-favorite');
	});
	if (document.querySelector('.hamburger') != null) {
		if (document.querySelector('.js-favorite-header > a > i > img').src.indexOf('favoritos-full') == -1 ) {
			document.querySelector('.js-favorite-header > a > i > img').src=document.querySelector('.js-favorite-header > a > i > img').src.replace("favoritos", "favoritos-full");
		}
	}
}


function removeFromWishListHandler() {
	$('.js-wishlist-removeitem').on('click', function (event) {
		var anchor = $(event.currentTarget);
		event.preventDefault();

		var pid = anchor.data('pid');

		// init ajax call
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.wishlistRemoveItem, {
				pid: pid,
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						anchor.hide();
						var src = anchor.data('source');
						if (src == 'cart') {
							$('.addtocart-'+pid).show();
						} else if (src == 'pdp') {
							$('.js-wishlist-add').show();
						}
					}
				}

				var icon = $('.favorite-header__icon');
				icon.removeClass('favorite-header__icon--fill')
				if (data.listNumbers > 0) {
					icon.addClass('favorite-header__icon--fill');
				}
			}
		});
	});
    
	$('.js-cartwishlist-add').on('click', function (event) {
		event.preventDefault();
		var anchor = $(event.currentTarget);
		var pid = anchor.data('pid');
        var qty = $(this).closest('.js-cart-lineitem').find('.js-cart-quantity-select').val();
        var isVariationNotAttrSeleted = ($('.js-no-variation-attr-selected').length > 0) ? true : false;
        if (isVariationNotAttrSeleted) {
            return;
        }
		// init ajax call
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.cartWishlistAddItem, {
				pid: pid,
				qty: qty
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						anchor.hide();
						$('.removefromcart-'+pid).show();
					}
                }
				addToFavoritesAnimation();
			}
		});
		if($("#box-cta").length){
			var addFav = $('.icon-heart');
			addFav.addClass('add-fav');
		}
	});
	$('.js-pdpwishlist-add').on('click', function (event) {
        event.preventDefault();
		var anchor = $(event.currentTarget);
		var pid = anchor.data('pid');
		var qty = $(this).closest('#product-content').find('.qty-select').val();
        var isVariationNotAttrSeleted = ($('.js-no-variation-attr-selected').length > 0) ? true : false;
        if (isVariationNotAttrSeleted) {
            return;
        }
		// init ajax call
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.cartWishlistAddItem, {
				pid: pid,
				qty: qty
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						$('.js-wishlist-add').hide();
						$('.js-wishlist-removeitem').show();
					}
				}
				addToFavoritesAnimation();
			}
		});
	});
}

$('.product-content .js-wishlist-add').on('click', function () {
	addToFavoritesAnimation();
});


function showDeleteWishlistItemDialog() {
	$('.js-delete-wishlist-item').on('click', function (e) {
		var that = this;
		e.preventDefault();

		if (account.requestLogin($(e.target))) {
			return;
		}

		dialog.openConfirmation(
				$('.wishlist-wrapper'), 
				String.format(Resources.DELETE_WISHLIST_ITEM_TITLE),
				String.format(Resources.DELETE_WISHLIST_ITEM_DESCRIPTION), 
				function () {
					removeWistlistItem($(that).closest('form'), that);
				});
		return false;
	});
}

/**
 * Update saved product quantity when user changes the quantity in wishlist page.
 */
function updateQtyHandler() {
	$('.js-wishlist-qty-select').on('change', function(e){
		var qty = $(this).val();
		var pid = $(this).data('pid');
		
		// always hide the following container when we do ajax call 
		$('ul.js-product-availability-list-show-onpageload').hide();
		
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.updateProductQtyInWishlist, {
				pid: pid,
				quantity: qty
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						$('ul.js-product-availability-list-show-onajaxcall').hide();
					} else {
						$('ul.js-product-availability-list-show-onajaxcall').show();
					}
				}
			}
		});	
	});
}

function removeWistlistItem(wishlistForm, button) {
    $('<input/>').attr({
        type: 'hidden',
        name: button.getAttribute('name'),
        value: button.getAttribute('value') || 'deleteItem'
    }).appendTo($(wishlistForm));
    var data = $(wishlistForm).serialize();
    $.ajax({
        type: 'POST',
        url: $(wishlistForm).attr('action'),
        data: data
    })
    .done(function () {
        page.reload();
    });
}

exports.init = function () {
	updateQtyHandler();
	// 	addProductToCart($('.table-products-row-favourite'));

	$('#editAddress').on('change', function () {
		page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
	});

	//add js logic to remove the , from the qty feild to pass regex expression on client side
	$('.option-quantity-desired input').on('focusout', function () {
			$(this).val($(this).val().replace(',', ''));
	});
    
	showDeleteWishlistItemDialog();
	account.initAnchorBankInfo();

	window.dataLayer.push({
		'userID': userIdDataLayer,
		'page': window.location.pathname, 
		'pagename' : 'Favorite',
	});
	window.dataLayer.push({
		'event':'page-virtual',
	});
    
    login.init();
};

exports.removeFromWishListHandler = removeFromWishListHandler;