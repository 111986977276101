'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    dialog = require('../../dialog'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    selectbox = require('../../selectbox'),
    util = require('../../util'),
    page = require('./../../page'),
    delivery = require('./delivery'),
    sliders = require('./../../sliders'),
    installment = require('./installment'),
    minicart = require('../../minicart'),
    addToCart = require('./addToCart');

var loadStoresFromPDP = function(pid, region) {
    var url = util.appendParamsToUrl(Urls.findStoresFromPDP, {
        pid: pid,
        region: region,
        format: 'ajax'
    });
    ajax.load({
        type: 'GET',
        url: url,

        callback: function(data) {
            progress.hide();
            var result = $(data).find('.map-santiago.map-container');
            if (result.length > 0) {
                $('.map-santiago.map-container').replaceWith(result);
                result = '';
            }
        }
    });
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function(href, target, openSizesBoxInPod = false, completePDP = false) {
    var $pdpForm = $('.pdpForm');
    var $productTile = $(target).parents('.product-tile');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var pdpVersion = $pdpForm.find('input[name="pdpVersion"]').first().val();
    var categoryName = $('#categoryName').val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val(),
        pdpVersion: pdpVersion,
        completePDP: completePDP,
        searchCategory: categoryName
    };
    
    if ($productTile.length > 0) {
        progress.show($productTile);
    } else {
        progress.show($('#pdpMain'));
    }

    var selectedSlickIndex = $productTile.find('.carousel-colors .slick-active').first().attr('data-slick-index');
    selectedSlickIndex = parseInt(selectedSlickIndex, 0) ? selectedSlickIndex : 0;
    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: target,
        callback: function() {
            if (target.hasClass('js-rollover-container') && $(document).find('.h-box-onecolumn').length === 0) {
                var badgesWrapper = $productTile.find('.badges-wrapper');
                $(badgesWrapper[0]).html(target.find('.badges-wrapper').html());
                var imageProductContainer = $productTile.find('.box-image-product');
                var boxImageProduct = $(target[target.length - 1]).find('.box-image-product').html();
                $(imageProductContainer[0]).html(boxImageProduct);
                var boxDescriptionProduct = $productTile.find('.box-desc-product');
                if (target.find('.box-desc-product .colors-plp ').length > 0 || target.find('.box-desc-product .carousel-contenidos').length > 0 ) {
                    boxDescriptionProduct.first().html(target.find('.box-desc-product').html());
                }
                
                if (openSizesBoxInPod) {
                    const $sizesContainer = target.find('.size-selector-container');
                    showSizeSelection($sizesContainer);
                }

                image.lazyLoad();
            } else if ($(document).find('.h-box-onecolumn').length > 0) {
                var productID = $productTile[0].dataset.itemid;
                $(document).find('#' + productID).css('display', 'none');
                var div = "[data-itemid=" + productID + "]";
                var swatches = $(div).find('.colors-plp');
                swatches.html($productTile.find('.box-desc-product .colors-plp').first().html());

                var rating = $(div).find('.item-sale-wrap');
                rating.html($productTile.find('.box-desc-product .item-sale-wrap').first().html());

                var imageProductContainer = $productTile.find('.box-image-product');
                var boxImageProduct = $(target[target.length - 1]).find('.box-image-product').html();
                $(imageProductContainer[0]).html(boxImageProduct);
                image.lazyLoad();
            } else {
                if (SitePreferences.STORE_PICKUP) {
                    productStoreInventory.init();
                }

                delivery.init();
                image.replaceImages();
                tooltip.init();
                installment.init();
                if ($('.pdpForm').length > 0) {
                    var productObj = $('.pdpForm').data('product');
                    delete productObj.image_data;
                    var urlProductName = productObj.name.replace(/\s+/g, '-').toLowerCase();
                    var pdpAll = {
                        'event': 'page-virtual',
                        'page': 'product /detail /' + urlProductName,
                        'pagename': productObj.name,
                        'ecommerce': {
                            'detail': {
                                'products': productObj
                            }
                        }
                    };
                    window.dataLayer.push(pdpAll);
                    image.lazyLoad();
                    updatePdpContent();
                }
            }

            if ($productTile.length > 0) {
                clearColorSwatches($(target).find('.product-variations').first(), $productTile);
                var $productTileCarousel = $(this.target).parents('.product-tile').find('.carousel-colors.swatch-toggle');
                sliders.initializeColorCarousels($productTileCarousel.not('.slick-initialized'), selectedSlickIndex);
            }
            const $pdpMain = $('#pdpMain');

            selectbox.init(target.find('.js-option-select'));
            sliders.initialSwatchesMobSwipe();
            progress.hide();
            dataLayer.push({'event': 'optimize.activate'});
            minicart = require('../../minicart');
            minicart.init(true);
            initShowSizeGuideModal();
            showModalRecicambio();
            showModalInstalacion();
            showModalWarranty();
            showModalArmed();
            showModalExchange();
            showModalConcienciaCeleste();
            addToCart.init($pdpMain);
            image($pdpMain);

            // prevent default behavior of thumbnail link and add this Button
            $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
                e.preventDefault();
            });
        }
    });
};

function showSizeSelection($sizesContainer) {
    $sizesContainer.addClass('size-selector-container--show').show();

    const $closeBtn = $sizesContainer.find('.size-selector-container__close-btn')
    $closeBtn.on('click', () => hideSizeSelection($sizesContainer));

    const $itemSizes = $sizesContainer.find('.size-selector__item');
    const $addToCartBtn = $sizesContainer.find('.add-to-cart-from-plp');
    $itemSizes.each((i, el) => {
        const $item = $(el);

        $item.on('click', () => {
            $itemSizes.removeClass('size-selector__item--selected');
            $item.addClass('size-selector__item--selected');
            $addToCartBtn.prop('disabled', false);

            const $anchor = $item.find('.size-selector__anchor').first();
            const sizeLabel = String($anchor.data('sizes'));

            if (sizeLabel.indexOf('|') !== -1) {
                $sizesContainer.find('.size-selector__description').show();
            } else {
                $sizesContainer.find('.size-selector__description').hide();
            }

            $sizesContainer.find('.size-selector__description > p > span').text(sizeLabel);

            $addToCartBtn.data('src', $anchor.data('src'));
            $addToCartBtn.data('dimension18', sizeLabel);
        });
    });
}

function hideSizeSelection($sizesContainer) {
    $sizesContainer.removeClass('size-selector-container--show').hide();
}

function clearColorSwatches(target, container) {
    var colorsPlp = $(target).find('.colors-plp');
    if (colorsPlp.length > 0) {
        var containerColorPlp = $(container).find('.colors-plp');
        if (containerColorPlp.length > 0) {
            $(containerColorPlp[0]).replaceWith(colorsPlp);
        } else {
            colorsPlp.remove();
        }
    }
}

// Update pdp content on swatch click
function updatePdpContent() {
    $('.product-detail .swatchanchor').on('click', function(e) {
        e.preventDefault();
        progress.show();
        const urlBase = window.location.origin + window.location.pathname;
        const urlDestino = this.href;

        if(urlDestino.split('?').length > 1) {
            let urlFinal = urlBase + '?' + urlDestino.split('?')[1];

            if(window.location.search) {
                let paramsOriginalUrl = window.location.search.substring(1).split('&');

                for (let i = 0; i < paramsOriginalUrl.length; i++) {
                    if (paramsOriginalUrl[i].indexOf('cgid=') !== -1) {
                        urlFinal += '&' + paramsOriginalUrl[i];
                        break;
                    }
                }

                urlFinal += window.location.hash;
            }

            window.location = urlFinal;
        } else {
            window.location = this.href;
        }
    });
}

// Update tile content on swatch click
function updateTileContent() {
    const productActionTile = $('.flex-grid .product-tile .action-buttons').length;

    $(document).off('click').on('click', '.product-tile .swatchanchor', function(e) {
        var productTile = $(this).parents('.product-tile');
        var currentHref = $(this).data('href');
        currentHref = productActionTile ? `${currentHref}&source=plpquickactionview` : `${currentHref}&source=plpquickview`;

        if ($(this).parents('.carousel-colors li').hasClass('unselectable') || $(this).parents('.carousel-colors li').hasClass('selected')) { return; }

        //Analytics, click on swatches
        window.dataLayer.push({
            'event-interactive-category':'swatches plp',
            'event-interactive-accion':'click',
            'event-interactive-tag': window.location.pathname + window.location.search,
            'event':'event-interactive'
        });
        
        updateContent(currentHref, productTile.find('.js-rollover-container').first());

        var boxProduct = $(this).parents('.box-product');
        if (boxProduct.length > 0) {
            boxProduct.addClass('js-update-content');
            boxProduct.addClass('flagged');
        }
    });
}

function loadModal({ data, replace, addAttr, options = {} }) {
    const modalOptions = {
        html: '',
        options: {
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            dialogClass: 'ui-dialog-full',
            width: 900,
        }
    }

    if (typeof data === 'object') {
        if (data.hasOwnProperty('html')){
            modalOptions.html = data.html;
        }
        
        if (data.hasOwnProperty('title')) {
            modalOptions.options.title = data.title;
        }
    } else {
        modalOptions.html = data;
    }

    modalOptions.options = { ...modalOptions.options, ...options };

    const hasTitle = !!modalOptions.options.title?.trim();
    if (!hasTitle) {
        modalOptions.options.dialogClass += ' ui-dialog-remove-titlebar-whitespace';
    }

    progress.show();
    dialog.open(modalOptions);
    if (replace) {
        replaceSpanWithH1();
    }
    if (addAttr) {
        addAttributes();
    }
    $('#dialog-container').scrollTop(0);
    $('.ui-dialog-full').focus();
    progress.hide();
}

function closeModal() {
    $('.ui-dialog-content').dialog('close');
}

function openModal({ targetDomSelector, replace, addAttr, options }) {
    const data = $(document).find(targetDomSelector);
    const elementToRender = data.clone().find('.content-asset');
    const wrapperContent = elementToRender.find('.site-modal');
    let dataToRender = elementToRender.html();

    if (wrapperContent.length && wrapperContent.data('title')) {
        dataToRender = {
            title: wrapperContent.data('title'),
            html: dataToRender,
        }
    }

    loadModal({ data: dataToRender, replace, addAttr, options });
}

function handleOpenModalListeners({ targetSelector, targetDomSelector, replace, addAttr, options }) {
    $(targetSelector).on('click', () => {
        openModal({ targetDomSelector, replace, addAttr, options });
    });

    $(targetSelector).on('keypress', (e) => {
        if(e.keyCode === 13) {
            openModal({ targetDomSelector, replace, addAttr, options });
        }
    });
}

function initShowSizeGuideModal() {
    handleOpenModalListeners({ targetSelector: '.size-guide', targetDomSelector: '#data-size-guide' });
}

function fillContentAssetPlaceholders(contentAssetID, attributesObject) {
    const contentAssetString = $('#' + contentAssetID).find('.content-asset').length ? $('#' + contentAssetID).find('.content-asset')[0].outerHTML : '';
    const placeholdersFilledString = contentAssetString ? contentAssetString.replace(/{{(.*?)}}/gm, (match, key) => attributesObject[key]) : '';
    const $contentAssetFilled = contentAssetString ? $($.parseHTML(placeholdersFilledString)) : null

    return $contentAssetFilled;
}

function removeContainersWithEmptyPlaceholders($contentAssetElement) {
    const $contentAssetCleaned = $contentAssetElement;
    const emptyIndicator = 'undefined';
    const containerTagToRemove = 'section';
    if ($contentAssetCleaned === null) {
        return null
    }
    $contentAssetCleaned.find(`li:contains("${emptyIndicator}")`).remove();
    $contentAssetCleaned.find(`${containerTagToRemove}:contains("${emptyIndicator}")`).remove();

    return $contentAssetCleaned;
}

function processModalConcienciaCeleste(contentAssetID, attributesObject) {
    const $contentAssetFilled = fillContentAssetPlaceholders(contentAssetID, attributesObject);
    const $contentAssetCleaned = removeContainersWithEmptyPlaceholders($contentAssetFilled);
    if ($contentAssetCleaned) {
        $('#' + contentAssetID).find('.content-asset').empty().html($contentAssetCleaned.children());
    }
}

function analyticsModalConcienciaCeleste(isModalOpened) {
    const action = isModalOpened ? 'Conocer más' : 'Cerrar modal';
    const modalTitle = $(this).find('h1').text();

    window.dataLayer.push({
        'event-interactive-category': 'Interacciones Conciencia Celeste PDP',
        'event-interactive-accion': `Click botón ${action}`,
        'event-interactive-tag': modalTitle,
        'event': 'event-interactive'
    });
}

function analyticsLinksConcienciaCeleste() {
    const anchorText = $(this).text();

    window.dataLayer.push({
        'event-interactive-category': 'Interacciones Conciencia Celeste PDP',
        'event-interactive-accion': 'Click link',
        'event-interactive-tag': anchorText,
        'event': 'event-interactive'
    });
}

function showModalConcienciaCeleste() {
    const $concienciaCelesteObjectElement = $('#conciencia-celeste-object');
    if (!$concienciaCelesteObjectElement.length) return;

    const modalOptions = {
        open: function() {
            analyticsModalConcienciaCeleste.call(this, true);
            $('.modal-conciencia-celeste a').on('click', analyticsLinksConcienciaCeleste);
            $('.ui-widget-overlay').on('click', closeModal);
        },
        close: function() {
            analyticsModalConcienciaCeleste.call(this, false);
            $('.modal-conciencia-celeste a').off('click', analyticsLinksConcienciaCeleste);
            $('.ui-widget-overlay').off('click', closeModal);
        }
    }

    const concienciaCelesteObject = JSON.parse($concienciaCelesteObjectElement.val());

    const historyID = 'js-data-info-CC-history';
    const sellID = 'js-data-info-CC-sell';
    const tipsID = 'js-data-info-CC-tips';

    processModalConcienciaCeleste(historyID, concienciaCelesteObject);

    handleOpenModalListeners({ targetSelector: '.js-info-CC-history', targetDomSelector: '#' + historyID, options: modalOptions });
    handleOpenModalListeners({ targetSelector:'.js-info-CC-sell', targetDomSelector: '#' + sellID, options: modalOptions });
    handleOpenModalListeners({ targetSelector: '.js-info-CC-tips', targetDomSelector: '#' + tipsID, options: modalOptions });
}

function showModalInstalacion() {
    handleOpenModalListeners({ targetSelector: '.js-info-instalacion', targetDomSelector: '#js-data-info-instalacion', replace: true, addAttr: true });
}

function showModalRecicambio() {
    handleOpenModalListeners({ targetSelector: '.js-info-recambio', targetDomSelector: '#js-data-info-recambio', replace: true, addAttr: true });
}

function showModalWarranty() {
    handleOpenModalListeners({ targetSelector: '.js-info-warranty', targetDomSelector: '#js-data-info-warranty', replace: true, addAttr: true });
}

function showModalArmed() {
    handleOpenModalListeners({ targetSelector: '.js-info-armed', targetDomSelector: '#js-data-info-armed', replace: true, addAttr: true });
}

function showModalExchange() {
    handleOpenModalListeners({ targetSelector: '.js-info-exchange', targetDomSelector: '#js-data-info-exchange' });
}

function replaceSpanWithH1() {
    const spanText = $('.ui-dialog-title').text();
    $('.ui-dialog-title').replaceWith(`<h1 id="ui-id-1" class="ui-dialog-title">${spanText}</h1>`);
}

function addAttributes() {
    $('.ui-dialog-titlebar-close').attr('title', 'Cerrar');
    $('.ui-dialog-title').attr('tabindex', 0);
    $('.ui-dialog-titlebar-close').attr('tabindex', 0);
    $('.ui-dialog-content').attr('tabindex', 0);
}

function moveRelatedTermsOnDesktop() {
    const relatedTerms = $('.plp-related-terms-wrapper');
    const breadcrumbs = $('.PLPbreadcrumbs');

    if (relatedTerms.length && breadcrumbs.length && !util.isMobile()) {
        relatedTerms.insertBefore(breadcrumbs);
    }
}

function initializeEvents() {
    updatePdpContent();
    updateTileContent();
    moveRelatedTermsOnDesktop();
    initShowSizeGuideModal();
    showModalRecicambio();
    showModalInstalacion();
    showModalWarranty();
    showModalArmed();
    showModalExchange();
    showModalConcienciaCeleste();

    $(document).on('click', '#js-region-to-store-pdp', function() {

        $('#js-region-to-store-pdp').off('change');
        $('#js-region-to-store-pdp').on('change', function() {
            var select = $('.select-region');
            var idRegion = this.value || null;
            var pid = select.find('input[name="pid"]').first().val() || null;
            if (idRegion !== null && pid !== null) {
                progress.show();
                loadStoresFromPDP(pid, idRegion);
            }
        });
    });


    // click on swatch - should replace product content with new variant
    $(document).on('click', '.js-no-variation-attr-selected', function() {
        $('.variant-dropdown').addClass('animation-shake').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
            $(this).removeClass('animation-shake');
        });
    });

    // scrollTo select talla
    $(document).on('click', '.select-options', function() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $('.price').offset().top
        }, 500);
    });

    // change drop down variation attribute - should replace product content with new variant
    $(document).off('change').on('change', '.variation-select', function() {
        if ($(this).val().length === 0) {
            return;
        }
        $(this).addClass('data-selected-size');

        updateContent($(this).val(), $(this).closest('#product-content'), true);
    });

    var variationSelect = $(document).find('.variation-select');

    if (variationSelect.length > 0) {
        $(variationSelect).each(function() {
            $(this).val($(this).find('option[selected]').val());
            //$(this).trigger('change');
        });
    }
}

exports.init = initializeEvents;

exports.updateContent = updateContent;

exports.hideSizeSelection = hideSizeSelection;
