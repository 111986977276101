'use strict';

var util = require('./util'),
    progress = require('./progress');

var pagination = {
	initPaginationEvents: function (container, showLoader) {
		$(container).off('click', 'a.pag');
		$(container).on('click', 'a.pag', function(e) {
			e.preventDefault();
			e.stopPropagation();
			var currentLink = $(this);
            var url = util.ajaxUrl(currentLink.attr('href'));
            if (showLoader) {
                progress.show();
            }
			$.ajax({
	            type: 'GET',
	            url: url,
	            success: function (response) {
	                if (typeof response === 'object' &&
	                        !response.success &&
	                        response.error === Resources.CSRF_TOKEN_MISMATCH) {
	                    page.redirect(Urls.csrffailed);
	                } else if (typeof response === 'string') {
	                	container.html(response);
	                	pagination.initPaginationEvents(container, true);
	                }
	            },
	            failure: function () {
	            	container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                },
                complete: function() {
                    if (showLoader) {
                        progress.hide();
                    }
                }
	        });
		});
	}
};

module.exports = pagination;
