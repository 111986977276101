'use strict';
var dialog = require('../../dialog');
var util = require('../../util');
var qs = require('qs');
var url = require('url');
var _ = require('lodash');
var progress = require('../../progress');
var zoomMediaQuery = matchMedia('(min-width: 960px)');
var listenerModal = false;
var wishlist = require('../wishlist');
/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
function loadZoom () {
    var $imgZoom = $('.main-image'),
    hiresUrl;
    if ($imgZoom.length === 0 || !zoomMediaQuery.matches) {
        $imgZoom.trigger('zoom.destroy');
        return;
    }
    hiresUrl = $imgZoom.attr('href');
    // Make the image not clickable:
    $imgZoom.on('click', function(event){
        event.preventDefault();
    });
    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimage') === -1 && zoomMediaQuery.matches) {
        $imgZoom.trigger('zoom.destroy');
        $imgZoom.zoom({
            url: hiresUrl
        });
    }
}

zoomMediaQuery.addListener(loadZoom);

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
function setMainImage (atts) {
    $('#pdpMain .primary-image').attr({
        src: atts.url,
        srcset: atts.srcset,
        alt: atts.alt,
        title: atts.title
    });
    updatePinButton(atts.url);
    if (!util.isMobile()) {
        $('#pdpMain .main-image').attr('href', atts.hires);
    }
}

function setMainImageModal (atts) {
    if (!util.isMobile()) {
        $('#thumbnails2 .primary-image-v2').attr({
            src: atts.url,
            srcset: atts.srcset,
            alt: atts.alt,
            title: atts.title
        });
        $('#thumbnails2 .product-image').attr({
            href: atts.hires
        });
        $('#thumbnails2 .zoomImg').attr({
            src: atts.hires
        });
        $('#thumbnails2 .primary-image-v2').attr('href', atts.hires);
    }else{
        $('#thumbnails2 .primary-image-v2').attr({
            src: atts.hires,
            alt: atts.alt,
            title: atts.title
        });
        setTimeout(function () {
            var el = document.querySelector("#thumbnails2 > div.product-primary-image.slider-zoom > a > img");
            if(el){
                let pz = new PinchZoom.default(el, {});
            }
        }, 500)
    }
}

function updatePinButton (imageUrl) {
    var pinButton = document.querySelector('.share-icon[data-share=pinterest]');
    if (!pinButton) {
        return;
    }
    var newUrl = imageUrl;
    if (!imageUrl) {
        newUrl = document.querySelector('#pdpMain .primary-image').getAttribute('src');
    }
    var href = url.parse(pinButton.href);
    var query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;
    var newHref = url.format(_.extend({}, href, {
        query: query, // query is only used if search is absent
        search: qs.stringify(query)
    }));
    pinButton.href = newHref;
}

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    wishlist.removeFromWishListHandler();
}

function initThumbnails() {
    //TODO delete this  function
    if (util.isMobile()) {
        $('.slider-nav-mobile ul#GTM_pdp_modal_zoom_mobile').not('slick-initialized').slick({
            vertical: true,
            verticalSwiping: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        responsive: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: true,
                        variableWidth: false,
                        infinite: true
                    }
                },
            ]
        }); 
        
    }
    
    if ($(".js-product-video-link").length > 0) {
        var wrapper = $(".product-image-wrapper");

        $('.product-video-mobile').off('click');

        $(".js-product-video-link").click(function(e){
            e.preventDefault();

            var src = $(this).data('src');
            var video = $('<video />', {
                class: 'product-video',
                src: src,
                controls: true
            });

            wrapper.find("a").hide();

            if ($('.product-video').length == 0) {
                wrapper.append(video);
            }
        });

        $(".productthumbnail").click(function(){
            $(".product-video").remove();
            wrapper.find("a").show();
        });
    }
}

function loadModal(dataToRender, title) {
    progress.show();
    dialog.open({
        html: dataToRender,
        options: {
            title,
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            dialogClass: 'ui-dialog-full',
            width: 882,
            open: function () {
                progress.hide();
                $('.js-hidden').removeClass('hidden');
                $('html').addClass('ui-scroll-disabled');
                $('.ui-widget-overlay').each((i, el) => {
                    $(el).on('click', () => {
                        $(this).dialog('close');
                        $('html').removeClass('ui-scroll-disabled');
                        $('.ui-widget-content').removeClass('forgotten-password forgotten-email');
                    });
                });
                if(listenerModal == false){
                    $(document).on('click', '.thumbnail-link-2, .unselectable a', function (e) {
                        e.preventDefault();
                    });
                    $(document).on('click', '.productthumbnail-2', function () {
                        // switch indicator
                        $(this).closest('.product-thumbnails-2').find('.thumb.selected').removeClass('selected');
                        $(this).closest('.thumb').addClass('selected');
                        setMainImageModal($(this).data('lgimg'));
                    });
                    listenerModal = true;
                    
                }
                if (window.screen.width <= 768) {
                    $('.js-hidden').css('display', 'flex');
                }
                if (!dialog.isActive() || !util.isMobile()) {
                    lazyLoad();
                    initThumbnails();
                };
                $('.js-images').find('img').each(function (index, element) {
                    if (index === 0) {
                        if (util.isMobile()) {
                            var divImg = '<div class="col-sm-9 js-container-image img-border box-foto product-primary-image slider-zoom"> <a href= "' + $(element).data('hires') + '" class="product-image main-image vh-70">' +
                                     '<div class="onboarding-modal"></div>' +
                                     '<img itemprop="image" class="float-right primary-image-v2 loaded"' + 
                                     'src="' + $(element).data('hires') +'"' +
                                     'alt="' + $(element).data('alt') + '"' + '/> </a> </div>';
                        }else{
                            var divImg = '<div class="col-sm-9 js-container-image img-border box-foto product-primary-image slider-zoom"> <a href= "' + $(element).data('hires') + '" class="product-image main-image vh-70">' +
                                     '<div class="onboarding-modal"></div>' +
                                     '<img itemprop="image" class="float-right primary-image-v2 lazy loaded"' + 
                                     'src="' + $(element).data('src') +'"' +
                                     'data-src="' + $(element).data('src') + '"' +
                                     'data-srcset="' + $(element).data('srcset') + '"'+
                                     'alt="' + $(element).data('alt') + '"' + '/> </a> </div>';
                        }
                        
                        var jsContainer = $('.js-container-image'); 
                        if (!jsContainer.length > 0) {
                            $('.js-images').after(divImg);
                        }
                        $(this).closest('.product-thumbnails-2').find('.thumb.selected').removeClass('selected');
                        $(this).closest('.thumb').addClass('selected');
                        setMainImageModal($(this).data('lgimg'));
                        return;   
                    }
                });
                loadZoom();
                zoomMediaQuery.addListener(loadZoom);
               
                setTimeout(function () {
                    $('#thumbnails2 .thumb img').each(function () {
                        var img = new Image();
                        img.src = $(this).data('lgimg').url;
                    });
                }, 3e3);
            },
            close: function () {
                $('html').removeClass('ui-scroll-disabled');
                $('.js-hidden').addClass('hidden');
                document.querySelectorAll('.js-container-image').forEach(el => el.remove());
                document.querySelectorAll('.zoomImg').forEach(el => el.remove());
                
                var $imgZoom = $('#pdpMain .main-image');
                $imgZoom.trigger('zoom.destroy');
            }
        }
    });
    setTimeout(function(){
        $('.onboarding-modal, .onboarding-desktop').addClass('--fade');
        $('.onboarding-modal, .onboarding-desktop').hide();
    }, 3000);
}

function showModalImagesPDP() {
    var data = $(document).find('#thumbnails2');
    var dataToRender = data[0].outerHTML.replace("display: none;", "display: block;");
        listenerModal = false;
    loadModal(dataToRender, data.find('.products-container-name').text());

    $('.primary-image-v2').click(function(){
        return false;
    });
    return false;
}
/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function ($pdpMain) {
    // handle product thumbnail click event
    
    $pdpMain.on('click', '.productthumbnail', function () {
        // switch indicator
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');
        setMainImage($(this).data('lgimg'));
    });

    if (util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    if (document.getElementById("GTM_parisSeller_marketplace")){
        var btnAccionModal = document.getElementById("GTM_parisSeller_marketplace");
    } else if (!document.getElementById("GTM_parisSeller_marketplace") && document.getElementById("GTM_parisSeller_intangible")) {
        var btnAccionModal = document.getElementById("GTM_parisSeller_intangible");
    } else {
        var btnAccionModal = document.getElementById("GTM_parisSeller_paris");
    }
    
    var modalInfoSeller = document.getElementById("myModal");
    
    if( modalInfoSeller ){
        
        var spanCloseModal = document.getElementsByClassName("close")[0]; 

        btnAccionModal.onclick = function() {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            modalInfoSeller.style.display = "block";
        }
    
        spanCloseModal.onclick = function() {
            modalInfoSeller.style.display = "none";
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'inherit';
            document.body.style.width = 'inherit';
        }
    
        window.onclick = function(event) {
            if (event.target == modalInfoSeller) {
                modalInfoSeller.style.display = "none";
                document.body.style.overflow = 'auto';
                document.body.style.position = 'inherit';
                document.body.style.width = 'inherit';
            }
        }
    }
    

    $('.js-modal-images-pdp-mobile').click(function() {
        showModalImagesPDP();

        setTimeout(function(){ 
            $('#thumbnails2 > div.product-thumbnails-2 > ul > li.element-nro-'+ (parseInt(document.querySelector("#alternative-images > ul > ul > li.slick-active > button").textContent) - 1) +'> a > img').trigger('click');
        }, 500);
        return false;
    });
    $('.js-modal-images-pdp').on('click', function () {
        showModalImagesPDP();
        setTimeout(function(){ 
            let selectedLi = $('div.product-thumbnails > ul > li.thumb.selected').data('nli');
            if (selectedLi) {
                $('#thumbnails2 > div.product-thumbnails-2 > ul > li.element-nro-'+ $('div.product-thumbnails > ul > li.thumb.selected').data('nli') +'> a > img').trigger('click');
            } else {
                $('#thumbnails2 > div.product-thumbnails-2 > ul > li.element-nro-0> a > img').trigger('click');
            }  
        }, 500);
        return false;
    });
    setTimeout(function(){ 
        $('.onboarding').addClass('--fade');
        $('.onboarding').hide();
    }, 3000);
    

    setTimeout(function () {
        $('#thumbnails .thumb img').each(function () {
            var img = new Image();
            img.src = $(this).data('lgimg').url;
        });
    }, 3e3)
    updatePinButton();
    initThumbnails();
};

function lazyLoad() {
	$('.lazy').lazy({
		afterLoad: function (element) {
			element.addClass('loaded');
        },
        onError:(element) => {
            element[0].src = window.Urls.noImageURL;
			element.first().addClass('loaded');
        },
        bind: 'event',
		effect: 'fadeIn',
		effectTime: 250,
		threshold: 0
	});
    $('.buttonModiface').on('click', function () {
        progress.show();
        dialog.open({
            url: $(this).attr('href'),
            options: {
                draggable: false,
                // show: { effect: "drop", direction: "up", duration: 100 },
                // hide: { effect: "drop", direction: "up", duration: 100 },
                autoOpen: false,
                responsive: false,
                height: 'auto',
                modal: true,
                fluid: true,
                dialogClass: 'ui-dialog-full ui-dialog-modiface',
                title: 'Prueba los colores en vivo',
            },
            
            callback: function () {
                $('.ui-widget-overlay').on('click', function () {
                    $('#dialog-container').dialog("close");
                }) 
                 //window.scroll(0, 0);
                if (window.matchMedia("(max-width: 767px)").matches){
                    $("html, body").animate({ scrollTop: 0 }, "fast");
                    progress.hide();
                }
            }
        });
        progress.hide();
        return false;
    });


}

module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.initThumbnails = initThumbnails;
module.exports.lazyLoad = lazyLoad;