'use strict';

const { each } = require("lodash");

function isJson(str) {
  if (typeof str !== 'string') return false;
  try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
      return false;
  }
}

function getImgByCommune (service, address) {
  return service.length ? service.filter(comuna => comuna.name == address.comuna.name) : "";
};

function showPictureByCustomerAddress(comuna) {
  const urlBase = "https://www.paris.cl/on/demandware.static/-/Sites/es_CL/dwdf1b07c7/marketing/imagenes/servicios-comuna/";
  let imgSrc = "elige_tu_comuna_desktop.png";
  let imgMob = "elige_tu_comuna_mob.png";
  let imgTitle = "elige comuna";
  let imgAlt = "elige comuna";
  
  if (comuna.length) {
    imgSrc = comuna[0].img_src;
    imgMob = comuna[0].img_mob;
    imgTitle = comuna[0].title_service;
    imgAlt = comuna[0].alt_service;
  }

  const template = '<picture><source media="(min-width: 650px)" srcset="' + urlBase + imgSrc + '"><source media="(min-width: 300px)" srcset="' + urlBase + imgMob + '"><img class="" src="' + imgSrc +'" title="'+ imgTitle +'" alt="'+ imgAlt +'" width="100%"><picture>';
  $('.CA-service-img').html(template);
};

function formatedUrlKeyword(url) {
  if(url.charAt(url.length -1) === "/") {
    return url.substring(0, url.length - 1);
  } 
  else {
    return url
  }
}

var bannerSmartCustomer = {
  init: function () {
      if($('#region-commune-img-json').length) {
        $('#region-commune-img-json').sheetrock({
          url: 'https://docs.google.com/spreadsheets/d/1ZIT3Gk-_PwPL77FqID6t9Xs8rLl1QWGZzU9r_L1dkuM/edit#gid=124824670'
        });

        $(document).ready(function(){
          $('#region-commune-img-json').bind("DOMSubtreeModified",function(){
            bannerSmartCustomer.loadImage();
          });

          let formatedCategory = {};
          let temporalDiv = document.createElement('div');

          $(temporalDiv).sheetrock({
            url: 'https://docs.google.com/spreadsheets/d/1ezAZ_EPbdyvYSOk7hzK3-_0YqQZRVjNwjvvuwgSbU-M/edit#gid=1246685304',
            query: "select A, B",
            fetchSize: 15,
            callback: function(error,options,response){
              response.rows.forEach(function(row){
                formatedCategory[row.cellsArray[0]] = (JSON.parse(row.cellsArray[1]))
              });
    
              let pathName = window.location.pathname;
              let htmlPage = pathName.substring(pathName.lastIndexOf('/') + 1);
              let namePage = htmlPage.substring(0, htmlPage.lastIndexOf('.')); 
    
              if (namePage != "tendencias") {
                bannerSmartCustomer.loadTemplateSeo(formatedCategory[namePage]);
              } else {
                bannerSmartCustomer.loadTemplateSeo(formatedCategory.buscados);
              } 
            }
          });
        });
      }
  },

  loadImage: function() {
    let smartCustomerRegion = localStorage.getItem('smart_customer_region');
    let communeImgJSON = {};

    if ($('#region-commune-img-json').length && isJson($('#region-commune-img-json').text()) && smartCustomerRegion !== null && isJson(smartCustomerRegion)) {
      smartCustomerRegion = JSON.parse(smartCustomerRegion);
      communeImgJSON = JSON.parse($('#region-commune-img-json').text());
      let imgByComuna = getImgByCommune(communeImgJSON, smartCustomerRegion);
      showPictureByCustomerAddress(imgByComuna);
    }
  },

  loadTemplateSeo: function(categorySelected) {
    if(!categorySelected) return
    var templateList4 = "";
    var templateList40 = "";
    var categoryName = "";

    for (var i = 0; i < categorySelected.length; i++) {
      
      if (categorySelected[i].POSICIÓN <= 4 ) {
        templateList4 += '<li class="topListItem"><span class="topListItemBadge"><span class="topListNumber">' + categorySelected[i].POSICIÓN + '°</span><span class="topListPlace">Lugar</span></span><a target="_blank" href=' + formatedUrlKeyword(categorySelected[i].URL) + '><img class="topListImg" src='+ formatedUrlKeyword(categorySelected[i].IMAGE) +'><p class="topListName">' + categorySelected[i].KEYWORD + '</p></a></li>';
      };
      if (categorySelected[i].POSICIÓN > 4 && categorySelected[i].POSICIÓN < 41 ) {
        templateList40 += '<li class="topListItem"><span class="topListItemBadge"><span class="topListNumber">' + categorySelected[i].POSICIÓN + '°</span><span class="topListPlace">Lugar</span></span><a target="_blank" href=' + formatedUrlKeyword(categorySelected[i].URL) + '><img class="topListImg" src='+ formatedUrlKeyword(categorySelected[i].IMAGE) +'><p class="topListName">' + categorySelected[i].KEYWORD + '</p></a></li>';
      };
      categoryName = categorySelected[i].CATEGORIA;
    };

    $('#top4').html(templateList4);
    $('#top40').html(templateList40);
    $('#titleCategory').html(categoryName);
  }

};

module.exports = bannerSmartCustomer;