'use strict';

var _ = require('lodash');
var newRegionMappingUtil = require('./newRegionMappingUtil');
var util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        var windowYOffset = window.pageYOffset,
            windowXOffset = window.pageXOffset,
            windowInnerHeight = window.innerHeight,
            windowInnerWidth = window.innerWidth;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof (offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (windowXOffset !== null) {
            return (
                top < (windowYOffset + windowInnerHeight) &&
                left < (windowXOffset + windowInnerWidth) &&
                (top + height) > windowYOffset &&
                (left + width) > windowXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                //$form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
        if (Resources.SITE_ID == "Paris") {
            var dataObject = newRegionMappingUtil.checkComunas(address.city.toUpperCase());
            if (dataObject.isNewRegion) {
                if ($('[data-autocomplete=administrative_area_level_1] option:contains(' + dataObject.newRegion + ')').length) {
                    $('[data-autocomplete=administrative_area_level_1] option').each(function () {
                        if ($(this).text() == dataObject.newRegion) {
                            $(this).prop('selected', true);
                        }
                    });
                }
            }
        }
        $form.find('[name$="state"]').trigger('change');
        if (Resources.SITE_ID == "ParisPe") {
            $form.find('[name$="province"]').val(address.province);
            $form.find('[name$="province"]').trigger('change');
        }
        $form.find('[name$="city"]').val(address.city);

    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({ scrollTop: xLocation }, 500);
    },

    isIE11: function () {
        if (navigator.appName == "Netscape" && navigator.userAgent.match(/Trident.*rv:11/))
            return true;
        return false;
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    /**
     * detect IE / Edge
     * returns version of IE or false, if browser is not Internet Explorer
     */
    isIE: function () {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },

    detectMedia: function (viewportWidth) {
        var mediaMatches = window.matchMedia("(max-width: " + viewportWidth + "px)").matches;
        return mediaMatches;
    },

    matchMedia: function (viewportWidth, functionName, param) {
        var mq = window.matchMedia("(max-width: " + viewportWidth + "px)");
        mq.addListener(functionName);
        functionName(mq, param)
    },

    truncateProductNames: function ($container) {
        $container.find('.name-product-plp').each(function () {
            $(this).ThreeDots({
                ellipsis_string: '...',
                whole_word: 'false',
                max_rows: 2
            });
        });
    },

    obfuscate: function (text) {
        var result = '';

        if (text.indexOf(' ') > 0) {
            var words = text.split(' ');
            words.forEach(function (value) {
                result = result + toObfuscate(value) + ' ';
            });
            result = result.trim();
        } else {
            result = toObfuscate(text);
        }

        return result.trim();
    },

    /**
     * @function
     * @description Calculates the number of columns based on the current grid (useful for responsive changes)
     * @param {NodeListOf<Element>} productList Total products listed on current PLP page
     */
    getColumnsQty(productList) {
        for (var i = 0, currentPosition = 0, previousPosition = 0; i < productList.length; i++) {
            currentPosition = productList[i].getBoundingClientRect().left;

            if (currentPosition > previousPosition || previousPosition === 0) {
                previousPosition = currentPosition;
            } else {
                return i;
            }
        }
    },

    /**
     * @function
     * @description Calculates remaining products outside the grid
     * @param {number} columns Number of columns in the grid
     * @param {number} productsInPLP Total products listed on current PLP page
     */
    getNumberOfRemainingProductsInGrid(columns, productsInPLP) {
        return (columns - productsInPLP % columns) % columns;
    },

    helperRut: {
        /*
         * @param paramrut {string}= 016.761.256-9
         * @return {string} = 167512569
         */
        rutClean: paramrut => `${paramrut}`
            .toString().replace(/^\s*0+|\s*[^0-9kK]+/g, '').toUpperCase(),

        /*
         * @param paramrut {number/string} = 16751256 / 16.751.256
         * @return {string} = 9
         */
        rutCalcDv(paramrut) {
            const rut = this.rutClean(`${paramrut}`.toString());
            const reverseRut = `${rut}`.split('').reverse().join('');
            let result = 0;
            let n = 1;

            Object.keys(reverseRut).forEach((i) => {
                n += 1;
                result += parseInt(reverseRut[i], 10) * n;
                n = n === 7 ? 1 : n;
            });

            result = 11 - (result % 11);
            result = result === 11 ? 0 : result;
            return result === 10 ? 'K' : `${result}`;
        },

        /*
         * @example =
         RUTvalidate('167512569') -> true
         RUTvalidate('167512568') -> false
         RUTvalidate('16.751.256-8') -> false
         RUTvalidate('16.751.256-9') -> true
         * @param paramrut {string} = 16.761.256-9
         * @return {boolean} = true
         */
        rutValidate(paramrut) {
            let rut = paramrut.toString();
            if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
                return false;
            }
            rut = this.rutClean(rut);
            const rutLength = rut.slice(0, -1);

            if (rutLength.length > 8) {
                return false;
            }

            const dv = rut.slice(-1);
            const rutNumber = parseInt(rut.slice(0, -1), 10);
            return this.rutCalcDv(rutNumber) === dv;
        },

        /*
         * @param paramrut {number/string} = 167512569
         * @return {string} = 16.761.256-9
         */
        rutFormat(paramrut) {
            const rut = this.rutClean(paramrut);
            let result = '';

            if (rut) {
                result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
            }

            for (let i = 4; i < rut.length; i += 3) {
                result = `${rut.slice(-3 - i, -i)}.${result}`;
            }

            return result;
        },

        /*
         * @param paramrut {string} = 16.751.256-9 / 16751256-9
         * @return {string} = 16751256
         */
        rutGetNumber(paramrut) {
            return this.rutClean(`${paramrut}`.split('-')[0]);
        },

        /*
         * @param paramrut {string} = 16.751.256-9 / 16751256-9
         * @return {string} = 9
         */
        rutGetDv(paramrut) {
            return this.rutClean(`${paramrut}`.split('-')[1]);
        },

        /*
        * @param paramrut {string} = 16751256
        * @return {string} = 167512569
        */
        rutAddDv(paramrut) {
            return `${paramrut}${this.rutCalcDv(paramrut)}`;
        },
    },

    /**
     * Secure Hash Algorithm (SHA1)
     **/
    SHA1: function(msg) {
        function rotate_left(n, s) {
            var t4 = (n << s) | (n >>> (32 - s));
            return t4;
        }
        function lsb_hex(val) {
            var str = '';
            var i;
            var vh;
            var vl;
            for (i = 0; i <= 6; i += 2) {
                vh = (val >>> (i * 4 + 4)) & 0x0f;
                vl = (val >>> (i * 4)) & 0x0f;
                str += vh.toString(16) + vl.toString(16);
            }
            return str;
        }
        function cvt_hex(val) {
            var str = '';
            var i;
            var v;
            for (i = 7; i >= 0; i--) {
                v = (val >>> (i * 4)) & 0x0f;
                str += v.toString(16);
            }
            return str;
        }
        function Utf8Encode(string) {
            string = string.replace(/\r\n/g, '\n');
            var utftext = '';
            for (var n = 0; n < string.length; n++) {
                var c = string.charCodeAt(n);
                if (c < 128) {
                    utftext += String.fromCharCode(c);
                } else if (c > 127 && c < 2048) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                } else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
            }
            return utftext;
        }
        var blockstart;
        var i, j;
        var W = new Array(80);
        var H0 = 0x67452301;
        var H1 = 0xefcdab89;
        var H2 = 0x98badcfe;
        var H3 = 0x10325476;
        var H4 = 0xc3d2e1f0;
        var A, B, C, D, E;
        var temp;
        msg = Utf8Encode(msg);
        var msg_len = msg.length;
        var word_array = new Array();
        for (i = 0; i < msg_len - 3; i += 4) {
            j =
                (msg.charCodeAt(i) << 24) |
                (msg.charCodeAt(i + 1) << 16) |
                (msg.charCodeAt(i + 2) << 8) |
                msg.charCodeAt(i + 3);
            word_array.push(j);
        }
        switch (msg_len % 4) {
            case 0:
                i = 0x080000000;
                break;
            case 1:
                i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000;
                break;
            case 2:
                i =
                    (msg.charCodeAt(msg_len - 2) << 24) |
                    (msg.charCodeAt(msg_len - 1) << 16) |
                    0x08000;
                break;
            case 3:
                i =
                    (msg.charCodeAt(msg_len - 3) << 24) |
                    (msg.charCodeAt(msg_len - 2) << 16) |
                    (msg.charCodeAt(msg_len - 1) << 8) |
                    0x80;
                break;
        }
        word_array.push(i);
        while (word_array.length % 16 != 14) word_array.push(0);
        word_array.push(msg_len >>> 29);
        word_array.push((msg_len << 3) & 0x0ffffffff);
        for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
            for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
            for (i = 16; i <= 79; i++)
                W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
            A = H0;
            B = H1;
            C = H2;
            D = H3;
            E = H4;
            for (i = 0; i <= 19; i++) {
                temp =
                    (rotate_left(A, 5) +
                        ((B & C) | (~B & D)) +
                        E +
                        W[i] +
                        0x5a827999) &
                    0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 20; i <= 39; i++) {
                temp =
                    (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) &
                    0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 40; i <= 59; i++) {
                temp =
                    (rotate_left(A, 5) +
                        ((B & C) | (B & D) | (C & D)) +
                        E +
                        W[i] +
                        0x8f1bbcdc) &
                    0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 60; i <= 79; i++) {
                temp =
                    (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) &
                    0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            H0 = (H0 + A) & 0x0ffffffff;
            H1 = (H1 + B) & 0x0ffffffff;
            H2 = (H2 + C) & 0x0ffffffff;
            H3 = (H3 + D) & 0x0ffffffff;
            H4 = (H4 + E) & 0x0ffffffff;
        }
        var temp =
            cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

        return temp.toLowerCase();
    },
    /**
     * This function return true o false if exists event interactive
     * @param eventAction
     * @param eventTag
     * @returns {boolean}
     */
    checkIfExistsPushEventInteractive: function(eventCategory, eventAction, eventTag) {
        if (!eventCategory || !eventAction || !eventTag) {
            return false;
        }
        if (!window.dataLayer || !window.dataLayer.length) {
            return false;
        }
        const exists = window.dataLayer.some(function(dataLayerItem) {
            return  dataLayerItem['event-interactive-action'] === eventAction && 
                    dataLayerItem['event-interactive-tag'] === eventTag && 
                    dataLayerItem['event-interactive-category'] === eventCategory;
        });
        return exists;
    },
    getProductListKeyBySKU(sku) {
        return "pl-" + sku;
    },
    cleanProductListBySKU(sku) {
        try {
            const productKey = this.getProductListKeyBySKU(sku);
            localStorage.removeItem(productKey);
        } catch (error) {
            console.error(error)
        }
    }
};

function toObfuscate(text) {
    if ((typeof (text) === 'number' || typeof (text) === "string" && text.trim() !== '') && !isNaN(text)) {
        return text.toString().replace(/^.{1}/g, '*');
    } else {
        return text.toString().replace(/^.{2}/g, '**');
    }
}

module.exports = util;
