'use strict';

function initializeEvents() {
	$('.fee-details, .fee-details-2').on('click', function (e) {
		e.preventDefault();
		$(this).closest('.fee, .fee-2').find('.table-fee').toggle();
		$(this).find('.icon-fee').toggleClass('show');
	});
}

exports.init = function () {
	initializeEvents();
};
