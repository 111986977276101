'use strict';

var util = require('../../util'),
	formPrepare = require('./formPrepare'),
	validator = require('../../validator'),
	smartCustomer = require('../../smartCustomer'),
    formatRut = require('./../../components/formatRut');

function selectIdentityInput(val) {
	formatRut.initFormatRutInstance('#js-identityOption-rut-container');
	
	if (null === val) {
		return;
	}

	var requiredPlaceHolder = ' ';

	if ('RUT' == val) {
		hideIdentityOption('#js-identityOption-dni-container', 'passport', requiredPlaceHolder);
		showIdentityOption('#js-identityOption-rut-container', 'rut', requiredPlaceHolder);
	} else if ('DNI' == val){
		hideIdentityOption('#js-identityOption-rut-container', 'rut', requiredPlaceHolder);
		showIdentityOption('#js-identityOption-dni-container', 'passport', requiredPlaceHolder);
	} else if ('DNIPERU' == val){
		hideIdentityOption('#js-identityOption-dni-container', 'passport', requiredPlaceHolder);
		showIdentityOption('#js-identityOption-rut-container', 'dniPeru', requiredPlaceHolder);
	}
}

function showIdentityOption(identityContainer, addClass, requiredPlaceHolder) {
	var containerTextInput = $(identityContainer).find('input[type="text"]');
	var placeholder = containerTextInput.attr('placeholder');
	containerTextInput.attr('placeholder', placeholder + requiredPlaceHolder);
	containerTextInput.addClass(addClass).attr('required', true);
	containerTextInput.removeClass('error');
	if(containerTextInput.siblings('.error').length > 0) {
		containerTextInput.siblings('.error').remove();
	}
	containerTextInput.parents('.form-row').addClass('required');
	$(identityContainer).show();
}

function hideIdentityOption(identityContainer, removeClass, requiredPlaceHolder) {
	var containerTextInput = $(identityContainer).find('input[type="text"]');
	containerTextInput.parents('.form-row').removeClass('required');
	containerTextInput.removeClass(removeClass);
	containerTextInput.val('');
	if (containerTextInput.prop('required')) {
		containerTextInput.removeAttr('required');
	}
	$(identityContainer).hide();
}

function initializeChangeIdentityEvents(continueSelector, formSelector) {
	selectIdentityInput($('input[id*="personaldetails_identityOption"]:checked').val());
	$('input[id*="personaldetails_identityOption"]').click(function(e) {	
		selectIdentityInput($(this).val());
		formPrepare.updateRequiredFields({
			continueSelector: continueSelector,
			formSelector: formSelector
		});
	});
}
const initializeSendAnalytics = (formSelector)=>{
	let formPersonalDetail = document.querySelector(formSelector);
	formPersonalDetail.addEventListener('submit', ()=>{
		analyticsGuestUser();
	});
}

function analyticsGuestUser() {
	var smartCustomerRegion = localStorage.getItem('smart_customer_region');
	let category = window.Resources.GTM_STEP_2_GUEST_IDENT_CATEGORY;
	let accion = window.Resources.GTM_STEP_2_GUEST_IDENT_ACTION;
	let tag = window.Resources.GTM_STEP_2_GUEST_IDENT_TAG;
	let encodedRut = btoa($('#dwfrm_personaldetails_rut').val());
	var smartCustomerGARegion = '';
	var smartCustomerGAComuna = '';
	if (!_.isEmpty(smartCustomerRegion)) {
		smartCustomerRegion = JSON.parse(smartCustomerRegion);
		smartCustomerGARegion = smartCustomerRegion.name;
		smartCustomerGAComuna = smartCustomerRegion.comuna.name;
	}
	if (!_.isEmpty($('#dwfrm_personaldetails_rut').val()) && !_.isEmpty(smartCustomerGARegion) && !_.isEmpty(smartCustomerGAComuna)) {
		let ga = {
			'event-interactive-category': category,
			'event-interactive-accion': accion,
			'event-interactive-tag': tag,
			'event-interactive-rut': encodedRut,
			'event-interactive-tipoLogin': '',
			'event-interactive-region': smartCustomerGARegion,
			'event-interactive-comuna': smartCustomerGAComuna,
			'event': 'event-interactive',
		};
		window.dataLayer.push(ga);
	}
}

function initializeFormEvents() {
	var continueSelector = '[name$="personaldetails_continue"]';
	var formSelector = '#personal-details-form';
	
    if ($('.personaldetails_identityOption_container').length) {
        initializeChangeIdentityEvents(continueSelector, formSelector);
    } else {
        formatRut.initFormatRutInstance('#js-rut-container');
    }

	initializeSendAnalytics(formSelector);
	formPrepare.init({
		continueSelector: continueSelector,
		formSelector: formSelector,
		enabledByDefault: true
	});
	
}


exports.init = function () {
	//this must be here, otherwise is empty object
	var account = require('../account');
    account.initPhoneCodeEvents();
    account.initPhoneFieldEvents();
	account.initMaxLengthEvents();
	initializeFormEvents();
	validator.init();
};

exports.selectIdentityInput = selectIdentityInput;
