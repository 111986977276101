'use strict'

var newRegionMappingUtils = {};


newRegionMappingUtils.checkComunas = function(comunaName) {
	var objectKeys = Object.keys(newRegionsComunas);
	var resultObj = {
		'isNewRegion' : false,
		'newRegion' : ''
	}
	
	for (var key in objectKeys) {
		var comunaArr = newRegionsComunas[objectKeys[key]];
		if (comunaArr.includes(comunaName)) {
			resultObj.isNewRegion = true;
			resultObj.newRegion = objectKeys[key];
		}
		
	}
	return resultObj;
}


module.exports = newRegionMappingUtils;