'use strict'

exports.initializeMobileMeganav = function initializeMobileMeganav() {
	
	var menuItem = $("#navigation li:has(ul)");
	$(menuItem).append( "<span class='js-menu-arrow'></span>" );

	$( window ).resize(function() {
		
		if(window.matchMedia("(max-width: 970px)").matches){
            $('.btn-close-menu').show();
		} else{			
            $('.btn-close-menu').hide();
            $('.js-open-menu').removeClass('open-menu');
            $('.result-search').removeClass('nav-search');
        }
	});
	
	if (window.matchMedia("(max-width: 970px)").matches) {
			
		$(".js-btn-burger").click(function() {
            $(".js-open-menu").addClass('open-menu');
			$('.result-search').addClass('nav-search');
			$('#secondary.refinements').hide();
		});

		$(".js-close-menu").click(function() {
            $(".js-open-menu").removeClass('open-menu');
			$('.result-search').removeClass('nav-search');
			$('#secondary.refinements').show();
		});

		$('.js-top-category > .js-menu-arrow').on('click touchstar', function() {
					$(this).closest('.js-top-category').addClass(
							'open-sub-mobile');
					$('.btn-close-menu').hide();
					if ($('nav > ul > li').hasClass('open-sub-mobile')) {
						$('.js-volver-nivel-1').addClass('visible');
					}
				});

		$('.js-volver-nivel-1').on('click touchstar', function() {
			$('.js-top-category').removeClass('open-sub-mobile');
			$('.js-volver-nivel-1').removeClass('visible');
			$('.btn-close-menu').show();
		});

		$('.nivel-2 > li > .js-menu-arrow').on(
				'click touchstar',
				function() {
					$(this).closest('.nivel-2 li').addClass(
							'open-terminal-mobile');
					if ($('.nivel-2 > li')
							.hasClass('open-terminal-mobile')) {
						$('.js-volver-nivel-2').addClass('visible');
					}
					$('.js-volver-nivel-1').removeClass('visible');
				});

		$('.js-volver-nivel-2').on('click touchstar', function() {
			$('.nivel-2 li').removeClass('open-terminal-mobile');
			$('.js-volver-nivel-2').removeClass('visible');
			$('.js-volver-nivel-1').addClass('visible');
		});
		
	}
	
	$('#clickLoginRWD').on('click', function(event){
		event.preventDefault();
		$('.js-account-panel').toggleClass('hidden');
	});
	
	$('.js-account-panel-close').on('click', function(event){
		$('.js-account-panel').addClass('hidden');
	});
}


