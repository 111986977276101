'use strict';

var progress = require('./progress'),
    dialog = require('./dialog'),
    _ = require('lodash'),
    regions = require('./regions'),
    util = require('./util'),
    shippingData = require('./pages/product/delivery');

function loadModal($target) {
    dialog.open({
        url: $target.attr('src-data'),
        options: {
            title: window.Resources.COMUNASOURCE_TITLE,
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            height: 618,
            dialogClass: 'ui-dialog-full ui-dialog-auto-height ui-dialog-comuna-source',
            close() {
                $('html').removeClass('ui-scroll-disabled');
                closeComunaPopup();
            }
        },
        callback() {
            closeComunaPopup();
            regions.initializeRegionsComunasSmartCustomer();
        },
    });
    $('.ui-button').attr('aria-label', 'Cerrar');
    $('#ui-id-1').attr('role', 'status');

    window.dataLayer.push({
        'event-interactive-category': 'Agregar comuna y región',
        'event-interactive-accion': 'Clic',
        'event-interactive-tag': 'Definir Región y Comuna',
        'event': 'event-interactive'
    });
    progress.hide();
}

function handleOpenModal(e) {
    let $el = $(e.target);
    const tagName = $el.prop('tagName');

    if (['BUTTON', 'A', 'SPAN'].indexOf(tagName.toUpperCase()) === -1) {
        $el = $el.closest('a').length ? $el.closest('a') : 
                $el.closest('button').length ? $el.closest('button') : 
                    $el.closest('span');

        if($el.closest('a').length) {
            $el = $el.closest('a')
        } else if($el.closest('span').length) {
            $el = $el.closest('span')
        } else {
            $el = $el.closest('button');
        }
        if (tagName === 'FORM') {
            $el = $('.login-btn-forms');
        }
    }
    loadModal($el);
}

function analyticsIdentityUser(accion) {
    var smartCustomerRegion = localStorage.getItem('smart_customer_region');
    const smartCustomerGARut = $('#smartCustomerRut').val();
    const smartCustomerGAEncryptedRut = $('#smartCustomerEncryptedRut').val();
    const smartCustomerGAEmail = $('#smartCustomerEmail').val();
    const smartCustomerGALoginTag = $('#smartCustomerLoginTag').val();
    const smartCustomerGALoginType = $('#smartCustomerLoginType').val();
    var smartCustomerGARegion = '';
    var smartCustomerGAComuna = '';

    if (!_.isEmpty(smartCustomerRegion)) {
        smartCustomerRegion = JSON.parse(smartCustomerRegion);
        smartCustomerGARegion = smartCustomerRegion.name;
        smartCustomerGAComuna = smartCustomerRegion.comuna.name;
    }

    if (document.getElementById('gamificationOrderConfirmation')) {
        accion = document.getElementById('gamificationOrderConfirmation').value;
    }

    var gaObject = new Object();
    gaObject['event-interactive-category'] = 'Identificación usuario';
    gaObject['event-interactive-accion'] = accion;
    gaObject['event-interactive-tag'] = smartCustomerGALoginTag;

    if (!_.isEmpty(smartCustomerGAEmail)) {
        gaObject['i1'] = smartCustomerGAEmail;
    }

    if (!_.isEmpty(smartCustomerGARut)) {
        gaObject['i2'] = smartCustomerGARut;
    }

    if (!_.isEmpty(smartCustomerGAEncryptedRut)) {
        gaObject['event-interactive-rut'] = smartCustomerGAEncryptedRut;
    }

    if (!_.isEmpty(smartCustomerGALoginType)) {
        gaObject['event-interactive-tipoLogin'] = smartCustomerGALoginType;
    }

    if (document.getElementById('gamificationLevel')) {
        gaObject['event-interactive-nivel'] = document.getElementById('gamificationLevel').value;
    }

    if (!_.isEmpty(smartCustomerGARegion)) {
        gaObject['event-interactive-region'] = smartCustomerGARegion;
    }

    if (!_.isEmpty(smartCustomerGAComuna)) {
        gaObject['event-interactive-comuna'] = smartCustomerGAComuna;
    }

    gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
    window.dataLayer.push(gaObject);
}

function showHideSameDayFilter(displayAction) {
    //show the same day filter: ;
    const elements = document.getElementsByClassName('refinement-sameday-delivery');
    const elementIsSameDayDelivery = document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay");

    if (elementIsSameDayDelivery === null || elementIsSameDayDelivery === undefined) {

        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
        }

        if (document.querySelector("div.refinement-content > div.refinement-sameday-delivery")) {
            document.querySelector("div.refinement-content > div.refinement-sameday-delivery").style.display = 'none';
        }
        return;
    }

    if (!_.isEmpty(elements)) {
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = displayAction;
        }

        if (document.querySelector("div.refinement-content > div.refinement-sameday-delivery")) {
            document.querySelector("div.refinement-content > div.refinement-sameday-delivery").style.display = displayAction;
        }
    }
}

function showHidePickupFilter(displayAction) {
    const elements = document.getElementsByClassName('refinement-pickup-delivery');
    const elementIsPickUpDelivery = document.querySelector("div.refinement-content > div.refinement.pickUp");
	
    if (elementIsPickUpDelivery === null || elementIsPickUpDelivery === undefined) {
        
        for (var i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        
        if(document.querySelector("div.refinement-content > div.refinement-pickup-delivery")){
            document.querySelector("div.refinement-content > div.refinement-pickup-delivery").style.display = 'none';
        } 
        return;
    }

    if (!_.isEmpty(elements)) {
        for (let i=0; i<elements.length; i++) {
            elements[i].style.display = displayAction;
        }
        if(document.querySelector("div.refinement-content > div.refinement-pickup-delivery")){
            document.querySelector("div.refinement-content > div.refinement-pickup-delivery").style.display = displayAction;
        }  
    }
}

// Header Comuna Popup
function comunaPopupTrapperTopHandler() {
    $('#header-comuna-source-popup-button').trigger("focus");
}

function comunaPopupTrapperBottomHandler() {
    $('#header-comuna-source-popup-close').trigger("focus");
}

function openComunaPopup() {
    $('#header-comuna-source-popup').addClass('header-comuna-source__popup--open');
    $('#header-comuna-source-popup-close').on('click', closeComunaPopup);
    
    setTimeout(function () {
        $('#header-comuna-source-popup-button').trigger("focus");

        document.addEventListener('click', closeComunaPopupIfFocusOutside);
        document.addEventListener('focusin', closeComunaPopupIfFocusOutside);
        document.addEventListener('keydown', closeComunaPopupWithEscapeKey);

        $('#header-comuna-source-popup-trapper-top').on('focus', comunaPopupTrapperTopHandler);
        $('#header-comuna-source-popup-trapper-bottom').on('focus', comunaPopupTrapperBottomHandler);
    }, 800);
}

function closeComunaPopup() {
    const $headerComunaSourcePopup = $('#header-comuna-source-popup');

    $headerComunaSourcePopup.removeClass('header-comuna-source__popup--open');
    setTimeout(function () {
        $headerComunaSourcePopup.hide();
    }, 300);

    removeComunaPopupListeners();
}

function closeComunaPopupWithEscapeKey(event) {
    if (event.key === 'Escape') closeComunaPopup();
}

function closeComunaPopupIfFocusOutside(event) {
    const withinBoundaries = event.composedPath().includes(document.querySelector('#header-comuna-source-popup'));
    if (!withinBoundaries) closeComunaPopup();
}

function removeComunaPopupListeners() {
    $('#header-comuna-source-popup-close').off('click', closeComunaPopup);
    document.removeEventListener('click', closeComunaPopupIfFocusOutside);
    document.removeEventListener('focusin', closeComunaPopupIfFocusOutside);
    document.removeEventListener('keydown', closeComunaPopupWithEscapeKey);

    $('#header-comuna-source-popup-trapper-top').off('focus', comunaPopupTrapperTopHandler);
    $('#header-comuna-source-popup-trapper-bottom').off('focus', comunaPopupTrapperBottomHandler);
}

function init() {
    let smartCustomerRegion = localStorage.getItem('smart_customer_region');
    const smartCustomerLogin = localStorage.getItem('smart_customer_login');
    let smartCustomerAddress = $('#smartCustomerAddress').val();
    const comunasSameDayStr = $('#comunasSameDay').val();
    const comunasSameDay = (!_.isEmpty(comunasSameDayStr)) ? JSON.parse(comunasSameDayStr) : [];
    let isComunaSameDay = false;
    let comunaId = null;

        if (!_.isEmpty(smartCustomerAddress) && smartCustomerAddress.toUpperCase() !== 'NULL') {
            smartCustomerRegion = smartCustomerAddress;
            localStorage.setItem('smart_customer_region', smartCustomerRegion);
            if(window.hasOwnProperty('listenerLevelService')){
                window.listenerLevelService();
            }
            const smartCustomerRegionTMP = JSON.parse(smartCustomerRegion);
            $.ajax({
                type: 'GET',
                url: util.ajaxUrl(Urls.setCustomerCommune),
                data: {communeID: smartCustomerRegionTMP.comuna.id}
            }).done(function (result) {
                if (result && result.data && result.data.hasOwnProperty('isMemberOfCustomerGroup')) {
                    if ($('#loginAdd2CartIsMemberOfCustomerGroup').length) {
                        $('#loginAdd2CartIsMemberOfCustomerGroup').val(result.data.isMemberOfCustomerGroup);
                    }

                    if (result.data.isMemberOfCustomerGroup) {
                        var gaObject = new Object();
                        gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;

                        gaObject['event-interactive-category'] = window.Resources.GTM_EXPERIMENT_SEGMENT;
                        gaObject['event-interactive-accion'] = window.Resources.GTM_LOGIN_FORCED;
                        gaObject['event-interactive-tag'] = window.Resources.GTM_LOGIN_FORCED_WITH;
                        window.dataLayer.push(gaObject);
                    }
                }
            });
        }

    if (_.isEmpty(smartCustomerRegion)) {
        openComunaPopup();
    } else {
        smartCustomerRegion = JSON.parse(smartCustomerRegion);
        comunaId = smartCustomerRegion.comuna.id;
        $('#header-comuna-source-p1').text(window.Resources.COMUNASOURCE_HEADER_ENTRGEAR_EN);
        $('#header-comuna-source-p2').text(smartCustomerRegion.comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
        $('#refinement-location__text').text(smartCustomerRegion.comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
        if ($('#delivery-info-comuna-name').length) {
            $('#delivery-info-comuna-name').text(smartCustomerRegion.comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
        }
        $('#header-comuna-source-mobile-btn span').html(`${window.Resources.COMUNASOURCE_HEADER_ENTRGEAR_EN} <b>${smartCustomerRegion.comuna.name.toLocaleLowerCase()}</b>`);
    }
    $('#header-comuna-source-mobile-btn').on('click', handleOpenModal);
    $('#header-comuna-source-btn').on('click', handleOpenModal);
    $('#header-comuna-source-popup-button').on('click', handleOpenModal);
    $('#refinement-location').on('click', handleOpenModal);

    if (_.isEmpty(document.referrer) || document.referrer.indexOf('paris.cl') === -1) {
        analyticsIdentityUser('Ingreso');
    }

    if (!_.isEmpty(smartCustomerLogin)) {
        localStorage.removeItem('smart_customer_login');
        analyticsIdentityUser('Inicio de sesión');
    }

    if (comunasSameDay && comunasSameDay.length) {
        isComunaSameDay = comunasSameDay.some(function (comunaSameDay) {
            return parseInt(comunaSameDay, 10) === parseInt(comunaId, 10);
        });
    }

    if (smartCustomerRegion) {
        const $smartCustomerLocationStr = $('#smartCustomerLocation').val();
        const $smartCustomerLocation = !_.isEmpty($smartCustomerLocationStr) ? JSON.parse($smartCustomerLocationStr) : null;
        smartCustomerRegion.sameDay = isComunaSameDay;
        const geometry = {
            latitude: $smartCustomerLocation ? $smartCustomerLocation.latitude : smartCustomerRegion.geometry.latitude,
            longitude: $smartCustomerLocation ? $smartCustomerLocation.longitude : smartCustomerRegion.geometry.longitude,
            plusCode: $smartCustomerLocation ? $smartCustomerLocation.plusCode : smartCustomerRegion.geometry.plusCode,
            isVerified: $smartCustomerLocation ? $smartCustomerLocation.isVerified : smartCustomerRegion.geometry.isVerified,
            saveLatLong: false,
        }
        smartCustomerRegion.geometry = geometry;
        const smartCustomerRegionstr = JSON.stringify(smartCustomerRegion);
        localStorage.setItem('smart_customer_region', smartCustomerRegionstr);
        if(window.hasOwnProperty('listenerLevelService')){
            window.listenerLevelService();
        }
    }

    if (isComunaSameDay) {
        showHideSameDayFilter('block');
    } else {
        showHideSameDayFilter('none');
    }
};

module.exports = {
    init,
    handleOpenModal
};
