'use strict';

var formPrepare = require('./checkout/formPrepare'),
	personalDetails = require('./checkout/personalDetails');


function initAloneRegistrationForm() {
	$(document).ready(function() {
	var target = $('.js-account-content');
	
	if (target.length == 0) {
		return;
	}
	
	var registrationForm = target.find('.js-create-account-form');
	
	if (registrationForm.length == 0) {
		return;
	}
	
    var submitRegister = $(registrationForm).find('.js-create-account');
    
    formPrepare.init({
    	continueSelector: submitRegister,
    	formSelector: registrationForm
    });
    
    //Radio
	personalDetails.selectIdentityInput($('input[id*="personaldetails_identityOption"]:checked').val());
	$(document).on('change', 'input[id*="personaldetails_identityOption"]', function(event) {
		personalDetails.selectIdentityInput($(this).val());
		formPrepare.updateRequiredFields({
			continueSelector: submitRegister,
			formSelector: registrationForm
		});
	});
});
}


var registration = {
    initAloneRegistrationForm: function () {
       	initAloneRegistrationForm();
    }
};

module.exports = registration;
