'use strict';

var dialog = require('./dialog'),
    page = require('./page'),
    util = require('./util'),
    validator = require('./validator'),
	formPrepare = require('./pages/checkout/formPrepare'),
	progress = require('./progress'),
    formatRut = require('./components/formatRut');

// TODO: reCAPTCHA - This requires further refactoring
let captchaWidgetPasswordResetForm;

function validatePasswordResetForm() {
    const passwordResetForm = $('#js-password-reset-form');
    const submitButton = $('#js-password-reset-button');
    const captchaResponseExists = typeof grecaptcha !== 'undefined' ? !!grecaptcha.getResponse(captchaWidgetPasswordResetForm).length : false;

    if (passwordResetForm.valid() && captchaResponseExists) {
        submitButton.removeAttr('disabled');
    } else {
        submitButton.attr('disabled', 'disabled');
    }

    setTimeout(function() {
        validatePasswordResetForm();
    }, 201);
}

window.recaptchaPasswordResetForm = () => {
    captchaWidgetPasswordResetForm = grecaptcha.render('captcha-checkout-password-reset', {
        'sitekey': Constants.CAPTCHA_SITE_KEY,
        'callback': validatePasswordResetForm,
        'expired-callback': validatePasswordResetForm,
    });
}

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        //o-auth binding for which icon is clicked
        $('.oAuthIcon').bind('click', function () {
            $('#OAuthProvider').val(this.id);
        });
        
        //Handle login on checkout
        var $wrapper = $('.js-checkout-primary-content');
        if ($wrapper.length > 0) {
            var $loginForm = $wrapper.find('[id$="_login"]');
            var $submitLogin = $($loginForm).find('[name$="_login_login"]');
            
            formPrepare.init({
            	continueSelector: $submitLogin,
            	formSelector: $loginForm,
            	enabledByDefault: true
            });
            
            $($loginForm).on('submit', function(e) {
				progress.show();
                if (!$loginForm.valid()) {
					progress.hide();
                    return;
                }
                e.preventDefault();
                var data = $loginForm.serialize();
                // add form action to data
                data += '&' + $submitLogin.attr('name') + '=';
                var url = util.appendParamToURL($loginForm.attr('action'),'format', 'ajax');
                $.ajax({
                    type: 'POST',
                    url: url,
                    data: data,
                    success: function (response) {
                        if (typeof response === 'object' &&
                                !response.success &&
                                response.error === Resources.CSRF_TOKEN_MISMATCH) {
                            page.redirect(Urls.csrffailed);
                        }
    
                        if (typeof response !== 'string' && response.isMigrated) {
                            $('#js-password-reset').trigger('click');
                            return;
                        }
                        if (typeof response === 'string') {
                            var $errorElement = $('<div>' + response + '</div>').find('div.error-form');
                        	if ($errorElement.length > 0) {
                        		var $formError = $loginForm.find('div.error-form');
                        		if ($formError.length > 0) {
                        			$formError.html($errorElement.html());
                        		} else {
                        			$loginForm.prepend($errorElement);
								}
								progress.hide();
                        	return;	
                        	}
                        }
                        const profileUrl = $('#dwfrm_rutCtrl').val();
                        var smartCustomerRegion = localStorage.getItem('smart_customer_region');
                        var smartCustomerGARegion = '';
                        var smartCustomerGAComuna = '';
                        if (!_.isEmpty(smartCustomerRegion)) {
                            smartCustomerRegion = JSON.parse(smartCustomerRegion);
                            smartCustomerGARegion = smartCustomerRegion.name;
                            smartCustomerGAComuna = smartCustomerRegion.comuna.name;
                        }
                        if (!_.isEmpty(smartCustomerGARegion) && !_.isEmpty(smartCustomerGAComuna)) {
                            $.ajax({
                                type: 'GET',
                                url: profileUrl,
                                success: function (response) {
                                    if(response.data.isAuthenticated){
                                        const event = {
                                            'event-interactive-category': window.Resources.GTM_STEP_1_USER_LOGIN_CATEGORY,
                                            'event-interactive-accion': window.Resources.GTM_STEP_1_USER_LOGIN_ACCION, 
                                            'event-interactive-tag': window.Resources.GTM_STEP_1_USER_LOGIN_TAG, 
                                            'event-interactive-rut': btoa(response.data.customerNo), 
                                            'event-interactive-tipoLogin': window.Resources.GTM_STEP_1_USER_LOGIN_TIPOLOGIN, 
                                            'event-interactive-region': smartCustomerGARegion, 
                                            'event-interactive-comuna': smartCustomerGAComuna, 
                                            'i1': response.data.email.toLowerCase(), 
                                            'i2': response.data.customerNo.toLowerCase(), 
                                            'event': window.Resources.GTM_STEP_1_USER_LOGIN_EVENT,
                                        };
                                        window.dataLayer.push(event);
                                    }
                                    
                                }
                            });
                        }
                        page.reload();
                    },
                    failure: function () {
						dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
						progress.hide();
						
                    }
                });
            });
        }
        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').bind('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });
        
        login.initDialogs();
        
        // this is used when a reset password form is rendered without dialog
        const passwordResetForm = $('#js-password-reset-form');
        
        if (passwordResetForm.length > 0) {
            const emailField = $('.js-resetpasswordemail');
            $(emailField).on('keyup', validatePasswordResetForm);
        }
    },
    initDialogs: function() {
        $('#js-forgotten-email').on('click', function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                options: {
                    title: Resources.FORGOTTEN_EMAIL_TITLE,
                    draggable: false,
                    autoOpen: false,
                    responsive: true,
                    modal: true,
                    fluid: true,
                    dialogClass: 'ui-dialog-full ui-dialog-forgotten forgotten-email',
                    open: function () {
                        $('html').addClass('ui-scroll-disabled');

						if(Resources.SITE_ID == "Paris"){
							login.initForgottenFormEvents(dialog.$container.find('#js-request-mail-form'), '#js-request-mail-button', '.js-requestmail-rut');
						}
						else if(Resources.SITE_ID == "ParisPe"){
							login.initForgottenFormEvents(dialog.$container.find('#js-request-mail-form'), '#js-request-mail-button', '.js-requestmail-dni');
						}
                    },
                    close: function () {
                        $('html').removeClass('ui-scroll-disabled');
                    },
                }
            });
        });
        
        $('#js-password-reset').on('click', function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                options: {
                    title: Resources.FORGOTTEN_PASSWORD_TITLE,
                    draggable: false,
                    autoOpen: false,
                    responsive: true,
                    modal: true,
                    fluid: true,
                	dialogClass: 'ui-dialog-full ui-dialog-forgotten forgotten-password',
                    open: function () {
                        setTimeout(() => {
                            $('html').addClass('ui-scroll-disabled');
                        }, 100);
                        
                    	login.initForgottenFormEvents(dialog.$container.find('#js-password-reset-form'), '#js-password-reset-button', '.js-resetpasswordemail');

                        const emailField = $('.js-resetpasswordemail');
                        $(emailField).on('keyup', validatePasswordResetForm);
                    },
                    close: function () {
                        $('html').removeClass('ui-scroll-disabled');
                    },
                }
            });
            
            window.dataLayer.push({
            	'event':'page-virtual', 
				'page':'/retrieving-key', 
				'pagename':'forgotten password'
			});
        });
    },
    initForgottenFormEvents: function(form, continueButtonID, requiredFieldID) {
    	if (typeof form == 'undefined' || form == null || form.length == 0) {
    		return;
        }
        formatRut.initFormatRutInstance('#js-request-mail-form');
    	validator.init();
    	var formIdentifier = form.attr('id');
        var $submit = form.find(continueButtonID);
        
        var rutField = form.find('.js-requestmail-rut');
        
        if (rutField.length != 0) {
            var rutplaceholder = $(rutField).attr('placeholder');
    		$(rutField).attr('placeholder', rutplaceholder);
    		$(rutField).parents('.form-row').addClass('required');
        }
        
    	formPrepare.init({
    		continueSelector: $submit,
    		formSelector:form,
    		validateOnKeyUp: true
    	});
    	
        var $loginShow = form.find('#js-login-show');

        if ($loginShow.length > 0) {
	        $($loginShow).on('click', function(e) {
	        	e.preventDefault();
	        	var loginButton = $(document).find('.js-click-login-mobile-desktop');
	        	if (loginButton.length > 0) {
	        		$(document).find('.js-click-login-mobile-desktop').trigger('click');
	        	}
	        	dialog.close();
	        });
        }
        
        $($submit).on('click', function (e) {
            if (!form.valid()) {
                return;
            }
            e.preventDefault();
            var targeturl = window.location.href;
            var data = form.serialize();
            // add form action to data
            data += '&' + $submit.attr('name') + '=';
            // make sure the server knows this is an ajax request
            if (data.indexOf('ajax') === -1) {
                data += '&format=ajax';
            }
            data += '&targeturl=' + targeturl;
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: data,
                success: function (response) {
                    if (typeof response === 'object' &&
                            !response.success &&
                            response.error === Resources.CSRF_TOKEN_MISMATCH) {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === 'string') {
                        dialog.$container.html(response);
                        login.initForgottenFormEvents(dialog.$container.find('#'+formIdentifier), continueButtonID, requiredFieldID);
                        
                        window.dataLayer.push({
                        	'event':'page-virtual',
							'page':'/ retrieving-key / confirmation',
							'pagename':'Lost Key - Confirmation'
						});
                    }
                },
                failure: function () {
                    dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                }
            });
        });
    }
}

module.exports = login;
