'use strict';

var _ = require('lodash');

var $form, $continue, $requiredInputs, validator;

var hasEmptyRequired = function (form) {
    // filter out only the visible fields
    var requiredValues = $('.required', form).find(':input').filter(':visible').map(function () {
        return $(this).val();
    });
    return _(requiredValues).contains('');
};

var hasInvalidField = function (form) {
    // filter out only the visible fields
    var fieldValues = $('.required', form).find(':input').filter(':visible');
    
    if (fieldValues.length == 0) {
    	return false;
    }
    
    var tmpValidator = form.validate();
    
    var result = false;
    
    $(fieldValues).each(function () {
        if (!tmpValidator.element(this)) {
        	result = true;
        	return false;
        }
    });
    
    return result;
};

var validateCardNumberField = function() {
    var regex = new RegExp('^[0-9]{13,19}$');
    var isCencosudCardPaymentMethodSelected = $('#is-CENCOSUD_CARD').is(':checked');
    var cardNumber = (isCencosudCardPaymentMethodSelected) ? $('input[name*="_cencosudCard_number"]').val() : $('input[name*="_creditCard_number"]').val();
    if (cardNumber != null) {
    	return regex.test(cardNumber);
    } else {
    	return true;
    }
}

var validateForm = function (opts) {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
	if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    var tmpform = $(opts.formSelector);
    var tmpContinue = $(opts.continueSelector);
    var tmpValidator = tmpform.validate();
    
    if (!tmpValidator) {
        return;
    }
    if (!hasEmptyRequired(tmpform)) {
        if (tmpValidator.form()) {
        	tmpContinue.removeAttr('disabled');
        }
    } else if (!opts.enabledByDefault) {
    	tmpContinue.attr('disabled', 'disabled');
    }
};

var validateEl = function (event, opts) {
	if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    var tmpForm = $(opts.formSelector);
    var tmpContinue = $(opts.continueSelector);
    var tmpValidator = tmpForm.validate();
    
    var currentElement = event.target;
    
    if ($(currentElement).val() === '') {
    	tmpValidator.element($(currentElement));
    	if (!opts.enabledByDefault) {
        	tmpContinue.attr('disabled', 'disabled');    		
    	}

    } else {
        // enable continue button on last required field that is valid
        // only validate single field
        if (tmpValidator.element($(currentElement)) && !hasEmptyRequired(tmpForm)) {
        	if(!hasInvalidField(tmpForm)) {
        		tmpContinue.removeAttr('disabled');
        	} else if (!opts.enabledByDefault) {
        		tmpContinue.attr('disabled', 'disabled');
        	}
        	
/*
            if (validateCardNumberField()) {
                tmpContinue.removeAttr('disabled');
            } else {
                tmpContinue.attr('disabled', 'disabled');
            }
*/
        } else if (!opts.enabledByDefault) {
        	tmpContinue.attr('disabled', 'disabled');
        }
        
        $(document).trigger( "validate.form.fields", [ "Custom", "Event" ] );
    }
};

var updateRequiredFields = function (opts) {
	if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
	
    var tmpForm = $(opts.formSelector);
	
	var tmpRequiredInputs = $('.required', tmpForm).find(':input');
	
    // start listening
	initRequiredFieldsEvents(tmpRequiredInputs, opts);
	
	validateForm(opts);
	
    return tmpRequiredInputs;
}

var initRequiredFieldsEvents = function (requiredFields, opts) {
	requiredFields.off('change');
	requiredFields.filter('input').off('keyup');
	requiredFields.on('change', function (event) {
		validateEl(event, opts);
	});
	
	var validateOnKeyUp = false;
    
    if (typeof opts.validateOnKeyUp != "undefined" && opts.validateOnKeyUp != null && opts.validateOnKeyUp === true) {
    	validateOnKeyUp = true;
    }
    
    //on key up validation for all required inputs in form when that option is set to true
    var onKeyUpIdentifier = 'input';

    if (!validateOnKeyUp) {
    	//on key up validation only those elements that have different validation options from the overall behavior form
    	onKeyUpIdentifier = '.js-validation-on-keyup';
    }
    
    validateInputsOnKeyUp(requiredFields, opts, onKeyUpIdentifier);
}

var validateInputsOnKeyUp = function (requiredFields, opts, identifier) {
	var timeout = null;

	requiredFields.filter(identifier).on('keyup', function (event) {
		var currentEvent = event;
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(function() {
			validateEl(currentEvent, opts);
		}, 200);
	});
}
var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate();
    $requiredInputs = $(".required", $form).find(":input:not('.js-region-select,.js-departamento-select,.js-provincia-select')")
    validateForm(opts);
    // start listening
    initRequiredFieldsEvents($requiredInputs, opts);
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
exports.updateRequiredFields = updateRequiredFields;
