'use strict';

var login = require('./login'),
    validator = require('./validator'),
    util = require('./util'),
    page = require('./page'),
    ajax = require('./ajax'),
    mobileMeganav = require('./mobile-meganav'),
    searchsuggest = require('./searchsuggest'),
    pagination = require('./pagination'),
    account = require('./pages/account'),
    personalDetails = require('./pages/checkout/personalDetails'),
    formPrepare = require('./pages/checkout/formPrepare'),
    formatRut = require('./components/formatRut'),
    wishlistUtils = require('./pages/wishlistUtils'),
    minicart = require('./minicart.js'),
    pageInformation = require('./components/pageInformation'),
    dialog = require('./dialog'),
    progress = require('./progress'),
    tls = require('./tls'),
    newLogin = require('./new-login');

/**
 * Collapse Login Functionality
 */
function isMobile() {
    if (window.matchMedia("(max-width: 990px)").matches) {
        return true;
    }
    return false;
}

function closeRegistrationPopUp() {
    $('#collapseLogin').fadeToggle('fast', function() {
        $('body').off('touchmove');
        $('html, body').removeClass('prevent-scroll ');
    });
}
/**
 * @function
 * @name changeAttrrMenuHamburger
 * @param {}
 * @returns {object}
 * @description if it is mobile it changes the attributes of the search engine
 */
function changeAttrrMenuHamburger() {
    const search = document.querySelector('#js-box-search-header');
    const loginUser = document.querySelector('.login-user');
    const jsClose =  document.querySelector('.js-close');
    if (search.classList.contains('visible-search')) {
        search.classList.remove('visible-search');
        search.classList.add('hidden-search');
        loginUser.classList.remove('close-icon');
        document.querySelector('.login-box').classList.remove('hbr-open-search');
        return;
    } else if (search.classList.contains('hidden-search')) {
        search.classList.remove('hidden-search');
        search.classList.add('visible-search');
        jsClose.innerHTML = textClose;
        loginUser.classList.add('close-icon');
        document.querySelector('.login-box').classList.add('hbr-open-search');
        document.querySelector('#q').focus();
        return;
    }
}
(function() {
    if (isMobile()) {
        const search = document.querySelector('#js-box-search-header');
        if (search) {
            search.classList.add('hidden-search');
        }
    }
}());
function initializeEvents() {
    //removed event from GTM
    /*
	$('#header').on('click',':not(div#collapseLogin)', function(e) {

		if (!$(this).parents('div#collapseLogin').length > 0) {
    		window.dataLayer.push({
    			'event-interactive-category':'Header',
    			'event-interactive-accion':'Click ',
    			'event-interactive-tag':'Help',
    			'event':'event-interactive'
    		});
		}
	});
    */
    if (isMobile()) {
        if (document.querySelector('.js-show-search')) {
            document.querySelector('.js-show-search').addEventListener('click', event => {
                // changeAttrrMenuHamburger();
            });
        }
   }
    $("a.js-menu-arrow").on('click', function(e) {
        var $this = $(this);
        if (isMobile() && $this.parents('ul.nivel-2').length == 0) {
            $this.attr("href", "javascript:void(0)");
            return;
        } else if (isMobile() && $(this).attr('data-category-thirdlevel') != undefined && $(this).attr('data-category-thirdlevel') === 'true') {
            $this.attr("href", "javascript:void(0)");
            return;
        }
    });


    $('.js-click-favorites-mobile-desktop').on('click', function() {
        var setTarget = Urls.setTargetField;
        var target = Urls.wishlistShow;
        var urlTarget = util.appendParamToURL(setTarget, 'targeturl', target);
        $.ajax({
            url: urlTarget,
            success: function(response) {
                if ($('input#_clickOnFavorite').length > 0) {
                    $('input#_clickOnFavorite').val(target);
                }
                if ($('#collapseLogin').length > 0) {
                    if ($('#collapseLogin').css('display') == 'none') {
                        $('#clickLogin').click();
                    }
                }
            }
        });

    });

    $('.js-category-gm').on('click', function() {
        var categories = $(this).data('category');
        var indexOfLastCategory = categories.lastIndexOf('/');
        var subCat = indexOfLastCategory != -1 ? categories.substr(indexOfLastCategory + 1, categories.length) : categories;
        window.dataLayer.push({
            'event-interactive-category': ' Navigation menu',
            'event-interactive-accion': 'Click : ' + categories,
            'event-interactive-tag': subCat,
            'event': 'event-interactive'
        });
    });
    $('#clickLoginRememberMe').click(function() {
        var count = 1;
        var url = util.appendParamToURL(Urls.accountShow, 'format', 'ajax');
        $.ajax({
            url: url,
            success: function(response) {
                $('#collapseLogin').empty().html(response);
                $('#collapseLogin').fadeToggle('fast', function() {
                    if (count == 0) {
                        $('#collapseLogin').css('display', 'none');
                    } else {
                        $('#collapseTraking').css('display', 'none');
                        $('#collapseHelp').css('display', 'none');
                        validator.init();
                        initForms();
                    }
                    $('#closeLogin').click(function() {
                        $('#collapseLogin').fadeToggle('fast', function() {});
                    });
                });
            }
        });
    });

    $('.js-click-login-mobile').on('click', () => {
        newLogin.loadModal();
    });

    $('.js-click-favorites-mobile').on('click', (e) => {
        e.preventDefault();
        let isAuthenticated = true; 
        const url = $('.js-click-favorites-mobile').attr('href');
        
        if ($('#userIsAuthenticated').length && $('#userIsAuthenticated').val() === 'false') isAuthenticated = false;
        
        if (!isAuthenticated) {
            $('.navigation-drawer-mobile').removeClass('navigation-drawer-mobile--open');
            $('html, body').removeAttr('style');
             newLogin.loadModal(null, null, url);
        } else {
            window.location.href = url;
        }
    });

    $('.js-click-login-mobile-desktop').click(function() {
        $(this).toggleClass('open');

        if ($(this).hasClass('open')) {

            // hide desktop search if it´s open
            if (window.desktopSearch) {
                window.desktopSearch.initDomVariables();
                window.desktopSearch.handleBlurSearch();
            }

            $('html, body').removeClass('prevent-scroll ');

            if (isMobile()) {
                $('#secondary.refinements').hide();
            }
            var url = util.ajaxUrl(Urls.loginShow);
            var targetUrl = $('#collapseLogin').attr('data-original').length ? $('#collapseLogin').attr('data-original') : '';
            url = util.appendParamToURL(url, 'original', targetUrl);
            var $this = $(this);
            $.ajax({
                url: url,
                success: function(response) {
                    if(response.indexOf("<head>") != -1) location.reload();
                    else{
                        $('#collapseLogin').empty().html(response);
                        validator.init();
                        initForms();
                        $('#collapseLogin').fadeToggle('fast', function() {
                            $('#collapseTraking').css('display', 'none');
                            $('#collapseHelp').css('display', 'none');
    
                            //Do not delete, this can be uncommented in the future
                            //window.dataLayer.push({
                            //	'event':'page-virtual',
                            //	'page':'/register',
                            //	'pagename':'Register'
                            //});
                        });
                        if ($this.attr('id') === 'clickLoginRWD') {
                            $this.addClass('js-mobile-version');
                            $('html, body').addClass('prevent-scroll ');
                        }
    
                        $('#closeLogin').on('click', function() {
                            closeRegistrationPopUp();
                            $('.js-click-login-mobile-desktop').removeClass('open');
                            $('#secondary.refinements').show();
                        });
    
                        login.initDialogs();
                    }
                }
            });
        } else {
            closeRegistrationPopUp();
            $('#secondary.refinements').show();
        }
    });

    $('#collapseLogin').hasClass('js-trigger-show-form') ? $('#clickLogin').trigger('click') : '';

    $('.js-btn-mini-carro').click(function() {
        minicart.showAjax();
    });

    $('#js-header-button-order-search').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        progress.show();

        $('#js-header-order-search-results').html('');
        var form = $('#js-order-search-form');

        if (form.length != 1 || !form.valid()) {
            return;
        }

        var submitButton = $(this);
        var data = form.serialize();
        // add form action to data
        data += '&' + submitButton.attr('name') + '=';
        // make sure the server knows this is an ajax request
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }

        $.ajax({
            type: 'GET',
            url: form.attr('action'),
            data: data,
            success: function(response) {
                if (typeof response === 'object' &&
                    !response.success &&
                    response.error === Resources.CSRF_TOKEN_MISMATCH) {
                    page.redirect(Urls.csrffailed);
                } else if (typeof response === 'string') {
                    $('#js-header-order-search-results').html(response);
                    pagination.initPaginationEvents($('#js-header-order-search-results'), true);
                    account.initOrdersHistoryEvents();
                }
            },
            failure: function() {
                $('#js-header-order-search-results').html('<h1>' + Resources.SERVER_ERROR + '</h1>');
            },
            complete: function() {
                progress.hide();
            }
        });
    });

    $(document).ready(function() {
        appendOverlayElement();
        let currentUrl = util.getQueryStringParams(window.location.href);
        if (currentUrl.invalidep) {
            let $invalidepmsg = $('<div class="invalidep-msg"></div>').html(Resources.REQUEST_EMAIL_INVALIDEP);

            dialog.open({
                url: Urls.accountEmailRetrieveDialogForm,
                options: {
                    dialogClass: 'forgotten-dialog forgotten-email',
                    open: function() {
                        login.initForgottenFormEvents(dialog.$container.find('#js-request-mail-form'), '#js-request-mail-button', '.js-requestmail-rut');
                        $('#dialog-container').prepend($invalidepmsg);
                    }
                }
            });
        }
        if ($("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").length > 0) {
            let miniCartQty = $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").text().trim()
            let miniCartCookieQty = tls.getCookie("paris_minicart_count");
            if (miniCartQty != miniCartCookieQty) {
                $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").text(miniCartCookieQty);
            }
            if (miniCartCookieQty == '' || miniCartCookieQty == '0') {
                $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").addClass('hidden');
            } else {
                $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").removeClass('hidden');
            }
        }
    });
}

function initForms() {
    var $wrapper = $('#collapseLogin');
    var $loginForm = $wrapper.find('[id$="_login"]');
    var $submitLogin = $($loginForm).find('[name$="_login_login"]');
    var $registerForm = $wrapper.find('[id$="_profile"]');
    var $submitRegister = $($registerForm).find('[name$="_profile_confirm"]');
    $('.js-phonecode').trigger('change');

    // Create dynamic form and continue selector used with formPrepare
    var $form, $continue;

    formPrepare.init({
        continueSelector: $submitRegister,
        formSelector: $registerForm,
        enabledByDefault: true
    });

    formPrepare.init({
        continueSelector: $submitLogin,
        formSelector: $loginForm,
        enabledByDefault: true
    });

    $($loginForm).on('submit', function(e) {
        if (!$loginForm.valid()) {
            return;
        }
        var currentURL = util.getQueryStringParams(window.location.href);
        //if Session is expired and we are trying to see Wishlist-Show
        var original = '';
        if (currentURL.original) {
            original = currentURL.original;
        } else if (currentURL.targeturl) {
            original = currentURL.targeturl;
        }
        if ($('input#_clickOnFavorite').length > 0 && $('input#_clickOnFavorite').val() != "") {
            original = $('input#_clickOnFavorite').val();
        }
        var params = {
            original: original
        }
        e.preventDefault();
        var data = $loginForm.serialize();
        //add form action to data
        data += '&' + $submitLogin.attr('name') + '=';
        var url = util.appendParamsToUrl($loginForm.attr('action'), params);
        var mobileVersionPageRefresh = false;
        progress.show();
        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            success: function(response, status, xhr) {
                if (typeof response === 'string' && response.includes('hamburger') && !document.querySelector('.hamburger')) {
                    location.reload(true);
                    return;
                }
                if (typeof response === 'object' &&
                    !response.success &&
                    response.error === Resources.CSRF_TOKEN_MISMATCH) {
                    page.redirect(Urls.csrffailed);
                }

                if (typeof response !== 'string' && response.isMigrated) {
                    $('#js-password-reset').trigger('click');
                    return;
                }

                if ($('#clickLoginRWD').hasClass('js-mobile-version')) {
                    mobileVersionPageRefresh = true;
                    return;
                }
                //this is because somehow , somewhere we are changing the value of minicart not in this file
                //var minicart = require('./minicart');
                //minicart.update();
                if ($('#collapseLogin').hasClass('js-trigger-show-form')) {
                    $('#collapseLogin').removeClass('js-trigger-show-form');
                    //page.refresh();
                } else if (typeof response === 'string') {
                    var $errorElement = $('<div>' + response + '</div>').find('div.error-form');
                    let $successElement = '';
                    let $onlynamemenu = '';
                    let $divcontentheader = '';
                    let $accountpanel = '';
                    let $cartElement = '';
                    let $favoriteElement = '';
                    if (util.isMobile()) {
                        $successElement = $('<div>' + response + '</div>').find('#menu-login-hamburguer-mobile');
                        $onlynamemenu = $('<li>' + response + '</li>').find('li.user-data');
                        $divcontentheader = $('<div>' + response + '</div>').find('#box-login-header');
                        $accountpanel = $('<div>' + response + '</div>').find('.js-account-panel');
                    } else {
                        $successElement = $('<div>' + response + '</div>').find('#box-login-header');
                        $favoriteElement = $('<div>' + response + '</div>').find('#box-favorite-headerhamburger');
                        $cartElement = $('<div>' + response + '</div>').find('#mini-cart');
                    }
                    if ($successElement.hasClass('js-login-successful')) {
                        if (util.isMobile()) {
                            //if success remove class js-click-login-mobile-desktop from mobile menu header profile link
                            $('#box-login-header').html($divcontentheader.html());
                            $('.js-account-panel').html($accountpanel.html());
                            $('#clickLoginRWD').removeClass('js-click-login-mobile-desktop');
                            //$('#menu-login-hamburguer-mobile').html($successElement.html());
                            $('li.user-data').html($onlynamemenu.html());
                            $('#collapseLogin').fadeToggle('fast', function() {});
                            $('#content-logout-btn').removeClass('hidden');
                            $('.empty-div').remove();
                            document.querySelector('.login-user-mob').addEventListener('click', () => {
                                if (!document.querySelector('#clickLogin')) {
                                    if (document.querySelector('.js-account-panel')) {
                                        document.querySelector('.js-account-panel').classList.toggle('hidden');
                                        if (!document.querySelector('.js-account-panel').classList.contains('hidden') && document.querySelector('#js-box-search-header').classList.contains('visible-search')) {
                                            document.querySelector('.js-show-search').click();
                                        }
                                    }
                                }	
                            });
                            $('.js-account-panel-close').on('click', function(event) {
                                $('.js-account-panel').addClass('hidden');
                            });
                        } else {
                            //if success remove class js-click-login-mobile-desktop from mobile menu header profile link
                            $('#clickLoginRWD').removeClass('js-click-login-mobile-desktop');
                            $('#box-login-header').html($successElement.html());
                            $('#box-favorite-headerhamburger').html($favoriteElement.html());
                            $('#mini-cart').html($cartElement.html());
                            $('#collapseLogin').fadeToggle('fast', function() {});
                        }

                        page.refresh(0);
                    } else if ($errorElement.length > 0) {
                        var $formError = $loginForm.find('div.error-form');
                        if ($formError.length > 0) {
                            $formError.html($errorElement.html());
                        } else {
                            $loginForm.prepend($errorElement);
                        }
                    } else {
                        return;
                    }
                }
                if ($('#pdpMain').length > 0) {
                    var productId = $('div#product-content').find('a.js-wishlist').attr('data-pid');
                    var $pdpForm = $('.pdpForm');
                    var pdpVersion = $pdpForm.find('input[name="pdpVersion"]').first().val();
                    var params = {
                        pid: productId,
                        source: 'productdetail',
                        from: 'pdp',
                        format: 'ajax',
                        pdpVersion: pdpVersion
                    }
                    var urlFavorite = util.appendParamsToUrl(Urls.checkFavoriteAfterLogin, params);
                    $.ajax({
                        url: urlFavorite,
                        success: function(response) {
                            $('.js-wishlist-wrapper-container').empty().html(response);
                            wishlistUtils.removeFromWishListHandler();
                        }
                    });
                } else if ($('div.js-cart-content').length > 0) {
                    var product;
                    var productId;
                    var name;
                    var products = $('.js-wishlist-wrapper-container').find('a.js-wishlist');
                    for (var i = 0; i < products.length; i++) {
                        var product = products[i];
                        if (product != undefined) {
                            productId = $(products[i]).attr('data-pid');
                            name = $(products[i]).attr('name') != undefined ? $(products[i]).attr('name') : "";
                            var params = {
                                pid: productId,
                                source: 'productdetail',
                                from: 'cart',
                                name: name,
                                format: 'ajax'
                            }
                            var urlFavorite = util.appendParamsToUrl(Urls.checkFavoriteAfterLogin, params);
                            $.ajax({
                                url: urlFavorite,
                                success: function(response) {
                                    var controlerPid = ($(response).filter('a.js-wishlist').attr('data-pid'));
                                    $('a[data-pid=' + controlerPid + ']').parent().empty().html(response);
                                    wishlistUtils.removeFromWishListHandler();
                                }
                            });
                        }
                    }
                }
                if ($('input#_clickOnWrite').length > 0) {
                    $('.js-write-review').attr("href", "javascript:void(0)");
                    if ($('input#_clickOnWrite').attr('value') == 'true') {
                        setTimeout(function() { $('.js-write-review').click(); }, 1000);
                    }
                }
                $('.js-btn-mini-carro').click(function() {
                    minicart.showAjax();
                });
            },
            complete: function(xhr, status) {
                var $errorElement = $('<div>' + xhr.responseText + '</div>').find('div.error-form');
                if ($errorElement.length > 0) {
                    var $formError = $loginForm.find('div.error-form');
                    if ($formError.length > 0) {
                        $formError.html($errorElement.html());
                    } else {
                        $loginForm.prepend($errorElement);
                    }
                } else if (mobileVersionPageRefresh) {
                    page.refresh();
                } else if (params.original) {
                    window.location.href = params.original;
                } else {
                    $('#collapseLogin').css('display', 'none');
                }
                if ($("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").length > 0) {
                    let miniCartQty = $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").text().trim()
                    let miniCartCookieQty = tls.getCookie("paris_minicart_count");
                    if (miniCartQty != miniCartCookieQty) {
                        $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").text(miniCartCookieQty);
                    }
                    if (miniCartCookieQty == '' || miniCartCookieQty == '0') {
                        $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").addClass('hidden');
                    } else {
                        $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").removeClass('hidden');
                    }
                }
                let wishlistCookieQty = tls.getCookie("paris_wishlist_count");
                wishlistCookieQty > 0 && $('.favorite-header__icon').addClass('favorite-header__icon--fill');
                progress.hide();
            },
            failure: function() {
                dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                progress.hide();
            }
        });
    });
    $($registerForm).on('submit', function(e) {
        if (!$registerForm.valid()) {
            return;
        }
        
		progress.show();
        window.dataLayer.push({
            'event-interactive-category': 'Header',
            'event-interactive-accion': 'Click',
            'event-interactive-tag': 'Register Header',
            'event': 'event-interactive'
        });

        var currentURL = util.getQueryStringParams(window.location.href);
        //if Session is expired and we are trying to see Wishlist-Show
        var original = '';
        if (currentURL.original) {
            original = currentURL.original;
        } else if (currentURL.targeturl) {
            original = currentURL.targeturl;
        }
        var params = {
            original: original
        }
        e.preventDefault();
        var data = $registerForm.serialize();
        // add form action to data
        data += '&' + $submitRegister.attr('name') + '=';
        var url = util.appendParamToURL($registerForm.attr('action'), 'format', 'ajax');
        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            success: function(response) {
                if (typeof response === 'object' &&
                    !response.success &&
                    response.error === Resources.CSRF_TOKEN_MISMATCH) {
                    page.redirect(Urls.csrffailed);
                } else if (typeof response === 'string') {
                    var $successElement = $('<div>' + response + '</div>').find('#box-login-header');
                    var $errorElements = $('<div>' + response + '</div>').find('.server-error.error');
                    if ($successElement.length > 0) {
                        $('#box-login-header').html($successElement.html());
                        $('#collapseLogin').fadeToggle('fast', function() {});
                        var accountNewlyRegisteredMsg = $successElement.find('.js-pop-up-animation');
                        if (accountNewlyRegisteredMsg.length > 0) {
                            $('.accesos-despacho-login-mobile').append(accountNewlyRegisteredMsg);
                        }
                    } else if ($errorElements.length > 0) {
                        var errorMsgs = $('.server-error');

                        for (var i = errorMsgs.length - 1; i >= 0; i--) {
                            if (errorMsgs[i] && errorMsgs[i].parentElement) {
                                errorMsgs[i].parentElement.removeChild(errorMsgs[i]);
                            }
                        }

                        for (var i = 0; i < $errorElements.length; i++) {
                            var currentErrorElement = $($errorElements[i]);
                            var currentErrorElementClone = currentErrorElement.clone();
                            var currentErrorHtml = $("<div />").append(currentErrorElementClone).html();
                            var errorParent = currentErrorElement.parent();
                            var inputField = $(errorParent).find('input');
                            var fieldId = inputField[0].id;
                            var inputError = $('[id=' + fieldId + ']')[0];
                            // only for dynamicname inputs if is set to recognize proper identifier selection
                            if ($(inputField).data('dynamicname') == true) {
                                fieldId = $(inputField).data('dynamicname-identifier');
                                inputError = $(fieldId)[0];
                            }

                            $(inputError).addClass('error');
                            if (inputError && $errorElements.length > 0) {
                                $($(inputError).parent()).append(currentErrorHtml);
                            } else if ($errorElements.length > 0) {
                                inputError = $('.js-password-confirm')[0];
                                $($(inputError).parent()).append(currentErrorHtml);
                            } else {
                                return;
                            }
                            currentErrorElement.remove();
                        }
                    } else {
                        return;
                    }
                }
            },
            complete: function(xhr, status) {
                var $errorElements = $('<div>' + xhr.responseText + '</div>').find('.server-error.error');
                if ($errorElements.length > 0) {
                    var errorMsgs = $('.server-error');

                    for (var i = errorMsgs.length - 1; i >= 0; i--) {
                        if (errorMsgs[i] && errorMsgs[i].parentElement) {
                            errorMsgs[i].parentElement.removeChild(errorMsgs[i]);
                        }
                    }

                    for (var i = 0; i < $errorElements.length; i++) {
                        var currentErrorElement = $($errorElements[i]);
                        var currentErrorElementClone = currentErrorElement.clone();
                        var currentErrorHtml = $("<div />").append(currentErrorElementClone).html();
                        var errorParent = currentErrorElement.parent();
                        var inputField = $(errorParent).find('input');
                        var fieldId = inputField[0].id;
                        var inputError = $('[id=' + fieldId + ']')[0];
                        // only for dynamicname inputs if is set to recognize proper identifier selection
                        if ($(inputField).data('dynamicname') == true) {
                            fieldId = $(inputField).data('dynamicname-identifier');
                            inputError = $(fieldId)[0];
                        }

                        $(inputError).addClass('error');
                        if (inputError && $errorElements.length > 0) {
                            $($(inputError).parent()).append(currentErrorHtml);
                        } else if ($errorElements.length > 0) {
                            inputError = $('.js-password-confirm')[0];
                            $($(inputError).parent()).append(currentErrorHtml);
                        } else {
                            return;
                        }
                        currentErrorElement.remove();
                    }

                } else if (params.original) {
                    window.location.href = params.original;
				}
				progress.hide();
            },
            failure: function() {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
				progress.hide();
            }
        });
    });

    //Radio
    personalDetails.selectIdentityInput($('input[id*="personaldetails_identityOption"]:checked').val());
    $(document).on('change', 'input[id*="personaldetails_identityOption"]', function(event) {
        personalDetails.selectIdentityInput($(this).val());
        formPrepare.updateRequiredFields({
            continueSelector: $submitRegister,
            formSelector: $registerForm
        });
    });
}


function checkSearchField(searchInput) {
    var minLength = SitePreferences.SIMPLE_SEARCH_FIELD_MIN_LENGTH;
    var targetButton = searchInput.closest('form').find('.js-simple-search')
    if (searchInput.val().length < minLength) {
        targetButton.attr('disabled', 'disabled');
        return;
    }
    targetButton.removeAttr('disabled');
}

function appendOverlayElement() {
    const overlayExists = !!$('#overlay-content').length;
    
    if (!overlayExists) {
        const $overlayElement = $('<div id="overlay-content" class="overlay-content" aria-hidden="true"></div>');
        $overlayElement.appendTo('body');
    }
}

exports.init = function init() {
    account.initOrdersHistoryEvents();
    account.initPhoneCodeEvents();
    account.initPhoneFieldEvents();
    account.initMaxLengthEvents();
    $('.js-header-help, .js-close-help').bind('click', function() {
        // avoid displaying header and ordercheck at the same time.
        $('.js-header-ordercheck-section-expanded').hide();

        $('.js-header-help-section-expanded').toggle();

        return false;
    });
    $('.js-search-input').on('change keyup', function() {
        checkSearchField($(this));
    });
    formatRut.initFormatRutInstance('#js-order-search-form');

    formPrepare.init({
        formSelector: '#js-order-search-form',
        continueSelector: '#js-header-button-order-search'
    });

    var clickedOrderCheck = false;
    $('.js-header-ordercheck, .js-close-tracking').bind('click', function() {
        // avoid displaying header and ordercheck at the same time.
        $('.js-header-help-section-expanded').hide();

        if (util.detectMedia(970)) {
            $(document.body).css('overflow', 'hidden');
        }

        if (clickedOrderCheck) {
            clickedOrderCheck = false;
            $('#js-header-order-search-results').html('');
            $(document.body).css('overflow', 'auto');
            $('#secondary.refinements').show();
        } else {
            clickedOrderCheck = true;
            if (isMobile()) {
                $('#secondary.refinements').hide();
            }
        }

        $('.js-header-ordercheck-section-expanded').toggle();

        formPrepare.validateForm({
            formSelector: '#js-order-search-form',
            continueSelector: '#js-header-button-order-search'
        });

        return false;
    });

    $('.js-btn-burger').click(function() {
        $(".js-open-menu").addClass('open-menu');
        var $mobileNavSearchContainer = $('#navigation .box-search-header:not(.initialized)');
        if ($mobileNavSearchContainer.length) {
            searchsuggest.init($mobileNavSearchContainer, Resources.SIMPLE_SEARCH);
        }
        $('.result-search').addClass('nav-search');
    });

    $('.js-close-menu').click(function() {
        $('.js-open-menu').removeClass('open-menu');
        $('.result-search').removeClass('nav-search');
    });

    var timer;
    $(".js-top-category").hover(function() {
            clearTimeout(timer);
            timer = setTimeout(function() {
                $('.menu-despliegue').addClass('opened');
                if (!isMobile()) {
                    $('li.js-parent-sub-category').hide();
                }
            }, 300);
        },
        function() {
            clearTimeout(timer);
            timer = setTimeout(function() {
                $('.menu-despliegue').removeClass('opened');
                if (!isMobile()) {
                    $('.menu-despliegue').find('ul.nivel-2 li:last-child').show();
                }
            }, 200);
        });

    initializeEvents();
    login.init();
};

jQuery(() => { initializeEvents() });
