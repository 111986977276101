'use strict';

function FormatRutFieldEvent(rutContainerIdentifier) {
	this.rutContainerIdentifier = rutContainerIdentifier;
}

FormatRutFieldEvent.prototype.formatRutInit = function () {
	var rutContainerIdentifier = this.rutContainerIdentifier;

	var currentTarget = $(rutContainerIdentifier).find('input[type="text"]');
	
	currentTarget.attr('data-valid-rut', false);
	
	$(document).on('rut-validation-success', {currentInstance: this}, this.rutValidationSuccess);
	
	$(document).on('rut-validation-error', {currentInstance: this}, this.rutValidationError);

	$(document).on('focusout', '.rut', {currentInstance: this}, this.focusOutRutField);
	
	return this;
};

FormatRutFieldEvent.prototype.rutValidationSuccess = function (event, currentTargetElement) {
	var currentInstance = event.data.currentInstance;
	var rutContainerIdentifier = currentInstance.rutContainerIdentifier;
	var currentTarget = $(currentTargetElement);
	if (currentTarget.parents(rutContainerIdentifier).length > 0) {
		$(rutContainerIdentifier).find('input[type="text"]').attr('data-valid-rut', true);
	}
};

FormatRutFieldEvent.prototype.rutValidationError = function (event, currentTargetElement) {
	var currentInstance = event.data.currentInstance;
	var rutContainerIdentifier = currentInstance.rutContainerIdentifier;
	var currentTarget = $(currentTargetElement);
	if (currentTarget.parents(rutContainerIdentifier).length > 0) {
		$(rutContainerIdentifier).find('input[type="text"]').attr('data-valid-rut', false);
	}
};

FormatRutFieldEvent.prototype.focusOutRutField = function (event) {
	var currentInstance = event.data.currentInstance;
	var rutContainerIdentifier = currentInstance.rutContainerIdentifier;
	var currentTarget = $(event.target);
	if (currentTarget.parents(rutContainerIdentifier).length > 0) {
		var successValidation = $(rutContainerIdentifier).find('input[type="text"]').attr('data-valid-rut');
		if (successValidation == "true") {
			var rutInput = $(rutContainerIdentifier).find('input[type="text"]');
			var rutValue = $(rutInput).val();
			var formattedValue = formatRut(rutValue);
			if (rutValue == formattedValue) {
				return true;
			}
			$(rutInput).val(formattedValue);
		}
	}
};

function formatRut(rutValue) {
	if (typeof rutValue == 'undefined' || rutValue == null || rutValue == '') {
		return rutValue;
	}
	
	var trimedRut = cleanRut(rutValue);
	
	var length = trimedRut.length;
	var firstPartLength = 1;
	if (length == 9) {
		firstPartLength = 2;
	}
	
	var formattedRut = trimedRut.substr(length - 1, length);
	formattedRut = trimedRut.substr(0, firstPartLength) + '.' + trimedRut.substr(length - 7, 3) + '.' + trimedRut.substr(length - 4, 3) + '-' + formattedRut.toUpperCase();
	
	return formattedRut;
}

function cleanRut(rutValue) {
	if (typeof rutValue == 'undefined' || rutValue == null) {
		return rutValue;
	}
	
	var trimedRut = $.trim(rutValue);
	trimedRut = trimedRut.replace(new RegExp('[.]', 'g'), '');
	trimedRut = trimedRut.replace('-', '');
	
	return trimedRut;
}

function initFormatRutInstance(rutContainerIdentifier) {
	var newInstance = new FormatRutFieldEvent(rutContainerIdentifier);
	newInstance.formatRutInit();
};

exports.initFormatRutInstance = initFormatRutInstance;
