'use strict';

const dialog = require('./dialog');
const util = require('./util');
const page = require('./page');
window.formNotification = require('./components/commons/form-notification');
const smartCustomer = require('./smartCustomer');

// TODO: reCAPTCHA - This requires further refactoring
let captchaWidgetByEmailId;
let captchaWidgetByIdentificationId;

const newLogin = {
    $recoveryFormNotification: null,

    $formByEmail: null,

    $formByIdentification: null,

    formEmailValidation: null,

    formIdenticationValidation: null,

    savedMail: null,

    defaultSrcAction: null,

    redirectUrl: null,

    loadModal($target, modalSubtitle = null, redirect) {
        // Google reCAPTCHA
        window.recaptchaMainPasswordResetForm = () => {
            const callbackEmail = (response) => {
                this.formEmailValidation.updateErrorState();
            };
            const callbackIdentification = (response) => {
                this.formIdenticationValidation.updateErrorState();
            };

            captchaWidgetByEmailId = grecaptcha.render(
                'captcha-main-password-reset-by-email',
                {
                    sitekey: Constants.CAPTCHA_SITE_KEY,
                    callback: callbackEmail,
                    'expired-callback': callbackEmail,
                }
            );
            captchaWidgetByIdentificationId = grecaptcha.render(
                'captcha-main-password-reset-by-identification',
                {
                    sitekey: Constants.CAPTCHA_SITE_KEY,
                    callback: callbackIdentification,
                    'expired-callback': callbackIdentification,
                }
            );
        };

        const self = this;
        const url =
            $target instanceof jQuery
                ? $target.data('src')
                : window.Urls.getLoginForm;
        const isLoginThrowFromHeader =
            $target instanceof jQuery
                ? $target.data('login-from-header')
                : false;
        self.redirectUrl = redirect ? redirect : this.redirectUrl;

        if (isLoginThrowFromHeader) {
            var pageName = '';
            var page = dataLayer.find((item) =>
                item.hasOwnProperty('pagename')
            );

            if (page) {
                pageName = page.pagename;
            }

            var gaObject = new Object();
            gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
            gaObject['event-interactive-category'] =
                window.Resources.GTM_MY_ACCOUNT;
            gaObject['event-interactive-accion'] = pageName;
            gaObject['event-interactive-tag'] =
                window.Resources.GTM_TAG_LOGIN_CLICK;
            window.dataLayer.push(gaObject);
        }

        dialog.open({
            url,
            options: {
                title: window.Resources.ACCOUNT_LOGIN_MODAL_TITLE,
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                width: 395,
                dialogClass: 'ui-dialog-full ui-dialog-login-customer',
                close() {
                    const $modal = $(this);
                    self.removeSubtitleToDialogTitle($modal);
                },
            },
            callback($modal) {
                self.initLoginForm(
                    $modal,
                    modalSubtitle,
                    isLoginThrowFromHeader
                );
            },
        });
    },

    initLoginForm($modal, modalSubtitle = '', isLoginThrowFromHeader = false) {
        const self = this;
        const $form = $('#dwfrm_login');

        if (!modalSubtitle) {
            self.removeSubtitleToDialogTitle($modal);
        } else {
            self.addSubtitleToDialogTitle($modal, modalSubtitle);
        }

        $form.find('a[data-action="register"]').on('click', function (e) {
            if ($modal && $modal.length) {
                $modal.dialog('close');
            }
        });

        $form
            .find('a[data-action="recovery-password"]')
            .on('click', function (e) {
                self.removeSubtitleToDialogTitle($modal);

                if ($modal && $modal.length) {
                    self.loadRecoveryModal($(this));
                } else {
                    self.loadRecoveryModal($(this), false);
                }
            });

        const formValidation = $form.validateForm();
        let $notification = null;
        let isSending = false;

        formValidation.onErrorUpdated = function () {
            if (this.hasErrors()) {
                this.$submitButton.prop('disabled', 'disabled');
            } else {
                if (!isSending) {
                    this.$submitButton.removeAttr('disabled');
                }
            }
        };

        $form.on('submit', (e) => {
            e.preventDefault();
            isSending = true;

            if ($notification) {
                $notification.remove();
            }

            /**
             *
             * Add loading state to button
             */

            formValidation.$submitButton.addClass('btn--loading').empty();
            formValidation.$submitButton.append($('<div></div>'));
            formValidation.$submitButton.append($('<div></div>'));
            formValidation.$submitButton.append($('<div></div>'));

            /* End add loading state */

            formValidation
                .validate()
                .then((valid) => {
                    if (valid) {
                        return self.submit($form);
                    }
                })
                .then((response) => {
                    if (
                        typeof response === 'object' &&
                        !response.success &&
                        response.error === Resources.CSRF_TOKEN_MISMATCH
                    ) {
                        page.redirect(Urls.csrffailed);
                    }

                    if (typeof response !== 'string' && response.isMigrated) {
                        self.loadRecoveryModal(
                            $form.find('a[data-action="recovery-password"]')
                        );
                        return;
                    }

                    var $currentCustomerRut = '';

                    if (typeof response === 'string') {
                        var $divResponse = $('<div>' + response + '</div>');
                        var $errorElement = $divResponse.find('div.error-form');
                        var $userInvalid = $divResponse.find(
                            '#dwfrm_login_user_invalid'
                        );
                        var $rateLimitIndicator = $divResponse.find(
                            '#dwfrm_login_user_rate_limit_indicator'
                        );
                        var $statusCode = $divResponse.find(
                            '#dwfrm_login_user_code_status'
                        );
                        $currentCustomerRut = $divResponse.find(
                            '#currentCustomerRut'
                        );

                        if ($errorElement.length > 0) {
                            formValidation.$submitButton
                                .removeClass('btn--loading')
                                .empty()
                                .text('Iniciar sesión')
                                .blur();
                            formValidation.$submitButton;
                            $notification = window.formNotification(
                                'error',
                                'El correo o la contraseña no coinciden',
                                'Revisa que estén escritos correctamente y vuelve a intentar.'
                            );
                            $form.prepend(
                                $notification.css('marginBottom', 20)
                            );
                            return;
                        }

                        if (
                            $userInvalid.length > 0 &&
                            $userInvalid[0].value === 'true'
                        ) {
                            formValidation.$submitButton
                                .removeClass('btn--loading')
                                .empty()
                                .text('Iniciar sesión')
                                .blur();
                            formValidation.$submitButton;
                            $notification = window.formNotification(
                                'error',
                                '',
                                'Has introducido un correo o contraseña incorrecta.'
                            );
                            $form.prepend(
                                $notification.css('marginBottom', 20)
                            );

                            var gaObject = new Object();
                            gaObject.event =
                                window.Resources.GTM_EVENT_INTERACTIVE;
                            gaObject['event-interactive-category'] =
                                window.Resources.GTM_LOGIN;
                            gaObject['event-interactive-accion'] =
                                window.Resources.GTM_RESPONSE;
                            gaObject['event-interactive-tag'] =
                                window.Resources.GTM_WRONG_EMAIL_OR_PASSWORD;
                            window.dataLayer.push(gaObject);
                            return;
                        }
                    }

                    if ($modal && $modal.length) {
                        $modal.dialog('close');
                    }

                    localStorage.setItem('smart_customer_login', 'success');
                    let smartCustomerRegion = localStorage.getItem(
                        'smart_customer_region'
                    );
                    smartCustomerRegion = JSON.parse(smartCustomerRegion);
                    var gaObject = new Object();
                    var ciudad = '';
                    var rut = '';
                    var email = '';

                    if (
                        !_.isEmpty(smartCustomerRegion) &&
                        smartCustomerRegion.hasOwnProperty('comuna') &&
                        smartCustomerRegion.comuna.hasOwnProperty('name')
                    ) {
                        ciudad = smartCustomerRegion.comuna.name;
                    }

                    if ($currentCustomerRut) {
                        rut = $currentCustomerRut.val();
                    }

                    if ($('#dwfrm_login_username').length) {
                        email = $('#dwfrm_login_username').val();
                    }

                    if (isLoginThrowFromHeader) {
                        gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
                        gaObject['event-interactive-category'] =
                            window.Resources.GTM_EXPERIMENT_SEGMENT;
                        gaObject['event-interactive-accion'] =
                            window.Resources.GTM_LOGIN_FORCED;
                        gaObject['event-interactive-tag'] =
                            window.Resources.GTM_LOGIN_VOLUNTARY;
                        window.dataLayer.push(gaObject);
                    }

                    gaObject = new Object();
                    gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
                    gaObject['event-interactive-category'] =
                        window.Resources.GTM_LOGIN_OK;
                    gaObject['event-interactive-accion'] =
                        window.Resources.GTM_LOGIN;
                    gaObject['event-interactive-tag'] =
                        window.Resources.GTM_USER_REGISTERED;
                    gaObject['user_id'] = btoa(rut);
                    gaObject['Ciudad'] = ciudad;
                    gaObject['i1'] = email.toLowerCase();
                    gaObject['i2'] = rut.toLowerCase();
                    window.dataLayer.push(gaObject);

                    if (self.redirectUrl) {
                        page.redirect(self.redirectUrl);
                    } else {
                        page.reload();
                    }
                })
                .catch((error) => {
                    if (error.status === 429) {
                        formValidation.$submitButton
                            .removeClass('btn--loading')
                            .empty()
                            .text('Iniciar sesión')
                            .blur();
                        formValidation.$submitButton;
                        $notification = window.formNotification(
                            'rate-limit',
                            'Bloqueo de seguridad',
                            'La cuenta se bloqueó por motivos de seguridad. Por favor, intenta de nuevo en 1 minuto.'
                        );
                        $form.prepend($notification.css('marginBottom', 20));

                        var gaObject = new Object();
                        gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
                        gaObject['event-interactive-category'] =
                            window.Resources.GTM_LOGIN;
                        gaObject['event-interactive-accion'] =
                            window.Resources.GTM_RESPONSE;
                        gaObject['event-interactive-tag'] =
                            window.Resources.GTM_WRONG_EMAIL_OR_PASSWORD;
                        window.dataLayer.push(gaObject);
                        return;
                    }

                    formValidation.$submitButton
                        .removeClass('btn--loading')
                        .empty()
                        .text('Iniciar sesión');
                    $notification = window.formNotification(
                        'error',
                        'Error',
                        Resources.SERVER_ERROR
                    );
                    $form.prepend($notification.css('marginBottom', 20));
                })
                .finally(() => {
                    isSending = false;
                });
        });
    },

    submit($form) {
        const $submitLogin = $($form).find('[name$="_login_login"]');
        let data = $form.serialize();
        data += '&' + $submitLogin.attr('name') + '=';
        const url = util.appendParamToURL(
            $form.attr('action'),
            'format',
            'ajax'
        );

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: url,
                data: data,
            })
                .done((data, textStatus, jqXHR) => {
                    resolve(data);
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    reject(jqXHR);
                });
        });
    },

    getSuccesMarkup() {
        const username = this.savedMail.replace(/@.*$/, '');
        const usernameObfuscated = util.obfuscate(username);
        const mailObfuscated = this.savedMail.replace(
            username,
            usernameObfuscated
        );

        const $markup = $($('#success-sended-email').html());

        const text = $markup
            .find('.sended-email-confirmation-modal__description')
            .text()
            .replace('{{mail}}', mailObfuscated);
        $markup
            .find('.sended-email-confirmation-modal__description')
            .html(text);

        const $icon = $markup.find('.sended-email-confirmation-modal__icon');
        const $title = $markup.find('.sended-email-confirmation-modal__title');
        const $description = $markup.find(
            '.sended-email-confirmation-modal__description'
        );
        const $wrap = $markup.find('.sended-email-confirmation-modal__wrap');

        $icon.css({
            backgroundColor: '#E6F4F1',
            borderRadius: '50%',
            width: '80px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto 30px',
        });

        $icon.find('svg').css({ display: 'block' });

        $title.css({
            fontFamily: 'Montserrat, arial, helvetica',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#009372',
            marginBottom: '20px',
        });

        $description.css({
            fontFamily: 'Montserrat, arial, helvetica',
            fontSize: '14px',
            lineHeight: '19px',
            textAlign: 'center',
            color: '#1A1A1A',
        });

        $wrap
            .css({
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'space-between',
                height: '100%',
            })
            .on('submit', (e) => {
                e.preventDefault();

                const $submitButton = $wrap.find('button[type="submit"]');
                $submitButton.empty().addClass('btn--loading');
                $submitButton.append($('<div>'));
                $submitButton.append($('<div>'));
                $submitButton.append($('<div>'));

                setTimeout(() => {
                    $submitButton
                        .empty()
                        .removeClass('btn--loading')
                        .text('Reenviar enlace');
                    this.loadRecoveryModal();
                }, 1000);
            });

        return $markup.css({
            height: '342px',
        });
    },

    renderSendedEmailModal() {
        if (!this.savedMail) {
            return;
        }

        const self = this;
        dialog.open({
            html: self.getSuccesMarkup(),
            options: {
                title: '',
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                width: 395,
                dialogClass:
                    'ui-dialog-full ui-dialog-no-header ui-dialog-login-customer',
            },
            callback($modal) {
                self.removeBackButtonToDialogTitle($modal);
            },
        });
    },

    initRecoveryFormByEmail() {
        if (!this.$formByEmail) {
            return;
        }

        if (this.savedMail) {
            this.$formByEmail
                .find('[name="dwfrm_requestpassword_email"]')
                .val(this.savedMail);
        }

        this.formEmailValidation = this.$formByEmail.validateForm();
        let isSending = false;

        this.formEmailValidation.onErrorUpdated = function () {
            const captchaResponseExists =
                typeof grecaptcha !== 'undefined'
                    ? !!grecaptcha.getResponse(captchaWidgetByEmailId).length
                    : false;

            if (this.hasErrors() || !captchaResponseExists) {
                this.$submitButton.prop('disabled', 'disabled');
            } else {
                if (!isSending) {
                    this.$submitButton.removeAttr('disabled');
                }
            }
        };

        this.$formByEmail.on('submit', (e) => {
            e.preventDefault();

            isSending = true;

            if (this.$recoveryFormNotification) {
                this.$recoveryFormNotification.remove();
            }

            this.formEmailValidation.$submitButton
                .empty()
                .addClass('btn--loading');
            this.formEmailValidation.$submitButton.append($('<div>'));
            this.formEmailValidation.$submitButton.append($('<div>'));
            this.formEmailValidation.$submitButton.append($('<div>'));

            this.formEmailValidation
                .validate()
                .then((valid) => {
                    if (valid) {
                        return this.submitRecoveryModal(this.$formByEmail);
                    }
                })
                .then((response) => {
                    if (
                        typeof response === 'object' &&
                        !response.success &&
                        response.error === Resources.CSRF_TOKEN_MISMATCH
                    ) {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === 'string') {
                        var $errorElement = $(
                            '<div>' + response + '</div>'
                        ).find('div.js-error-form');

                        if ($errorElement.length > 0) {
                            this.$recoveryFormNotification = window
                                .formNotification(
                                    'error',
                                    'El correo no está registrado',
                                    'Revisa que el correo electrónico ingresado esté bien escrito o recupera tu cuenta con tu RUT.'
                                )
                                .css('marginBottom', 20);
                            $('.recovery-password-modal-form').prepend(
                                this.$recoveryFormNotification
                            );

                            var gaObject = new Object();
                            gaObject.event =
                                window.Resources.GTM_EVENT_INTERACTIVE;
                            gaObject['event-interactive-category'] =
                                window.Resources.GTM_PASSWORD_RECOVER;
                            gaObject['event-interactive-accion'] =
                                window.Resources.GTM_STEP_2_ERROR;
                            gaObject['event-interactive-tag'] =
                                window.Resources.GTM_WRONG_EMAIL_NOT_REGISTER;
                            window.dataLayer.push(gaObject);
                        } else {
                            this.savedMail =
                                this.formEmailValidation.$fields.dwfrm_requestpassword_email.value;
                            this.renderSendedEmailModal();
                        }
                    }
                })
                .catch(() => {
                    this.$recoveryFormNotification = window
                        .formNotification(
                            'error',
                            'Error',
                            Resources.SERVER_ERROR
                        )
                        .css('marginBottom', 20);
                    $('.recovery-password-modal-form').prepend(
                        this.$recoveryFormNotification
                    );
                })
                .finally(() => {
                    isSending = false;
                    this.formEmailValidation.$submitButton
                        .removeClass('btn--loading')
                        .empty()
                        .text('Enviar enlace');
                });
        });
    },

    initRecoveryFormByIdentification() {
        if (!this.$formByIdentification) {
            return;
        }

        this.formIdenticationValidation =
            this.$formByIdentification.validateForm();
        let isSending = false;

        this.formIdenticationValidation.onErrorUpdated = function () {
            const captchaResponseExists =
                typeof grecaptcha !== 'undefined'
                    ? !!grecaptcha.getResponse(captchaWidgetByIdentificationId)
                          .length
                    : false;

            if (this.hasErrors() || !captchaResponseExists) {
                this.$submitButton.prop('disabled', 'disabled');
            } else {
                if (!isSending) {
                    this.$submitButton.removeAttr('disabled');
                }
            }
        };

        this.$formByIdentification.on('submit', (e) => {
            e.preventDefault();

            isSending = true;

            if (this.$recoveryFormNotification) {
                this.$recoveryFormNotification.remove();
            }

            this.formIdenticationValidation.$submitButton
                .empty()
                .addClass('btn--loading');
            this.formIdenticationValidation.$submitButton.append($('<div>'));
            this.formIdenticationValidation.$submitButton.append($('<div>'));
            this.formIdenticationValidation.$submitButton.append($('<div>'));

            this.formIdenticationValidation
                .validate()
                .then((valid) => {
                    if (valid) {
                        return this.submitRecoveryFormByIdentification(
                            this.$formByIdentification
                        );
                    }
                })
                .then((response) => {
                    if (
                        typeof response === 'object' &&
                        !response.success &&
                        response.error === Resources.CSRF_TOKEN_MISMATCH
                    ) {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === 'string') {
                        const $errorElement = $(
                            '<div>' + response + '</div>'
                        ).find('div.js-error-form');

                        if ($errorElement.length > 0) {
                            this.formIdenticationValidation.$submitButton.prop(
                                'disabled',
                                'disabled'
                            );
                            this.$recoveryFormNotification = window
                                .formNotification(
                                    'warning',
                                    '',
                                    'El RUT ingresado no se encuentra registrado en Paris.cl.'
                                )
                                .css('marginBottom', 20);
                            $('.recovery-password-modal-form').prepend(
                                this.$recoveryFormNotification
                            );

                            var gaObject = new Object();
                            gaObject.event =
                                window.Resources.GTM_EVENT_INTERACTIVE;
                            gaObject['event-interactive-category'] =
                                window.Resources.GTM_PASSWORD_RECOVER;
                            gaObject['event-interactive-accion'] =
                                window.Resources.GTM_STEP_2_ERROR;
                            gaObject['event-interactive-tag'] =
                                window.Resources.GTM_WRONG_RUT;
                            window.dataLayer.push(gaObject);
                        } else {
                            const $mailResult = $(
                                '<div>' + response + '</div>'
                            ).find('p.mail_value');

                            if ($mailResult.length > 0) {
                                this.$recoveryFormNotification = window
                                    .formNotification(
                                        'success',
                                        '',
                                        'El correo electrónico asociado a tu cuenta en Paris es <b>' +
                                            $mailResult[0].textContent +
                                            '</b>'
                                    )
                                    .css('marginBottom', 20);
                                this.formIdenticationValidation.$submitButton
                                    .parent()
                                    .prepend(this.$recoveryFormNotification);

                                this.formIdenticationValidation.$submitButton.hide();

                                const $newButton = $('<button>')
                                    .addClass('btn btn--secondary')
                                    .text('Recuperar contraseña con correo')
                                    .on('click', () => {
                                        this.toggleRecoveryForm('email');
                                        this.formIdenticationValidation.$submitButton.show();
                                        this.formIdenticationValidation.$submitButton.prop(
                                            'disabled',
                                            'disabled'
                                        );
                                        this.formIdenticationValidation.resetForm();
                                        $newButton.remove();
                                    });

                                this.formIdenticationValidation.$submitButton
                                    .parent()
                                    .append($newButton);
                            }
                        }
                    }
                })
                .catch(() => {
                    this.$recoveryFormNotification = window
                        .formNotification(
                            'error',
                            'Error',
                            Resources.SERVER_ERROR
                        )
                        .css('marginBottom', 20);
                    $('.recovery-password-modal-form').prepend(
                        this.$recoveryFormNotification
                    );
                })
                .finally(() => {
                    isSending = false;
                    this.formIdenticationValidation.$submitButton
                        .removeClass('btn--loading')
                        .empty()
                        .text('Confirmar');
                });
        });
    },

    addSubtitleToDialogTitle($dialog, subtitle) {
        const dialogTitlebar = $dialog.dialog('instance').uiDialogTitlebar;
        const $subtitle = $('<span></span>')
            .text(subtitle)
            .addClass('ui-dialog-subtitle')
            .css({
                fontFamily: '"Montserrat", sans-serif, arial, helvetica',
                fontSize: '14px',
                lineHeight: '19px',
                color: '#1A1A1A',
                padding: '16px 16px 0px',
            });

        dialogTitlebar.after($subtitle);
    },

    removeSubtitleToDialogTitle($dialog) {
        const dialogContent = $dialog.dialog('instance').uiDialog;
        dialogContent.find('.ui-dialog-subtitle').remove();
    },

    addBackButtonToDialogTitle($dialog) {
        const dialogTitlebar = $dialog.dialog('instance').uiDialogTitlebar;
        const $backBottom = $('<button></button>')
            .prop('type', 'button')
            .addClass('ui-button-back')
            .html(
                '<svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.617109 9.70958C0.608703 9.66679 0.597494 9.62667 0.597494 9.58299C0.597494 9.5402 0.608703 9.50008 0.617109 9.45729C0.625516 9.41539 0.632054 9.37438 0.647934 9.33515C0.666615 9.29325 0.694638 9.25759 0.720792 9.21926C0.740408 9.19073 0.750682 9.15952 0.774968 9.13367C0.78244 9.12565 0.792716 9.12208 0.801123 9.11406C0.809529 9.10693 0.813265 9.09712 0.821671 9.0891L11.0918 0.174047C11.3776 -0.0746828 11.8213 -0.0541782 12.081 0.218622C12.3425 0.491423 12.3211 0.914888 12.0352 1.16273L2.33487 9.58299L12.0352 18.0041C12.3211 18.252 12.3425 18.6754 12.081 18.9482C11.9437 19.0927 11.7541 19.1667 11.5635 19.1667C11.3945 19.1667 11.2254 19.1096 11.0918 18.9928L0.821671 10.0778C0.812331 10.0698 0.809528 10.0599 0.800188 10.051C0.792715 10.0439 0.78244 10.0412 0.774968 10.0332C0.750682 10.0073 0.740408 9.97614 0.720792 9.94761C0.694638 9.90928 0.666615 9.87362 0.647934 9.83172C0.632054 9.79249 0.625516 9.75148 0.617109 9.70958Z" fill="#1A1A1A"/></svg>'
            )
            .css({
                marginRight: 15,
                border: 'none',
            })
            .on('click', () => {
                $backBottom.remove();
                this.loadModal(
                    $('.recovery-password-modal-form a[data-action="login"]')
                );
            });

        dialogTitlebar.find('.ui-dialog-title').before($backBottom);
    },

    removeBackButtonToDialogTitle($dialog) {
        const dialogTitlebar = $dialog.dialog('instance').uiDialogTitlebar;
        dialogTitlebar.find('.ui-button-back').remove();
    },

    initToggleRecoryForm() {
        const self = this;

        if (!self.$formByEmail || !self.$formByIdentification) {
            return;
        }

        var gaObject = new Object();
        gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
        gaObject['event-interactive-category'] =
            window.Resources.GTM_MY_ACCOUNT;
        gaObject['event-interactive-accion'] =
            window.Resources.GTM_CLICK_BUTTON;
        gaObject['event-interactive-tag'] =
            window.Resources.GTM_PASSWORD_FORGOT;
        window.dataLayer.push(gaObject);

        self.$formByIdentification.hide();

        $('.recovery-password-modal-form input[name="recovery-by"]').on(
            'change',
            function () {
                const recoveryOption = $(this).val();

                self.toggleRecoveryForm(recoveryOption);
            }
        );
    },

    toggleRecoveryForm(recoveryOption) {
        const self = this;

        if (self.$recoveryFormNotification) {
            self.$recoveryFormNotification.remove();
        }

        $('.recovery-password-modal-form input[name="recovery-by"]').each(
            (i, radio) => {
                const $radio = $(radio);

                if ($radio.val() === recoveryOption) {
                    // $radio.trigger('click');
                    $radio.prop('checked', true);
                }
            }
        );

        if (recoveryOption === 'email') {
            self.$formByEmail.show();
            self.$formByIdentification.hide();
        }

        if (recoveryOption === 'identification') {
            self.$formByIdentification.show();
            self.$formByEmail.hide();
        }
    },

    loadRecoveryModal($target, renderBAckButton = true) {
        const self = this;

        if ($target || $target instanceof jQuery) {
            this.defaultSrcAction =
                $target.length && $target.data('src')
                    ? $target.data('src')
                    : this.defaultSrcAction;
        }

        dialog.open({
            url: this.defaultSrcAction,
            options: {
                title: window.Resources.ACCOUNT_RECOVERY_MODAL_TITLE,
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                width: 395,
                dialogClass:
                    'ui-dialog-full ui-dialog-recovery-password-customer',
                close() {
                    $('html').removeClass('ui-scroll-disabled');
                    self.removeBackButtonToDialogTitle($(this));
                },
            },
            callback($modal) {
                if (renderBAckButton) {
                    self.addBackButtonToDialogTitle($modal);
                }

                $('.recovery-password-modal-form')
                    .find('a[data-action="login"]')
                    .on('click', function (e) {
                        self.removeBackButtonToDialogTitle($modal);
                        self.loadModal($(this));
                    });

                self.$formByEmail = $('#recovery-password-by-email-modal-form');
                self.$formByIdentification = $(
                    '#recovery-password-by-identification-modal-form'
                );

                self.initRecoveryFormByEmail();
                self.initRecoveryFormByIdentification();
                self.initToggleRecoryForm();
            },
        });
    },

    submitRecoveryModal($form) {
        let data = $form.serialize();
        data += '&dwfrm_requestpassword_send=';
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        const url = util.appendParamToURL(
            $form.attr('action'),
            'format',
            'ajax'
        );

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: url,
                data: data,
                success: function (response) {
                    var gaObject = new Object();
                    gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
                    gaObject['event-interactive-category'] =
                        window.Resources.GTM_PASSWORD_RECOVER;
                    gaObject['event-interactive-accion'] =
                        window.Resources.GTM_STEP_1_MAIL;
                    gaObject['event-interactive-tag'] =
                        window.Resources.GTM_CLICK;
                    window.dataLayer.push(gaObject);

                    resolve(response);
                },
                failure: function () {
                    reject();
                },
            });
        });
    },

    submitRecoveryFormByIdentification($form) {
        let data = $form.serialize();
        data += '&dwfrm_requestmail_retrieve=';
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        const url = util.appendParamToURL(
            $form.attr('action'),
            'format',
            'ajax'
        );

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: url,
                data: data,
                success: function (response) {
                    var gaObject = new Object();
                    gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
                    gaObject['event-interactive-category'] =
                        window.Resources.GTM_PASSWORD_RECOVER;
                    gaObject['event-interactive-accion'] =
                        window.Resources.GTM_STEP_1_RUT;
                    gaObject['event-interactive-tag'] =
                        window.Resources.GTM_CLICK;
                    window.dataLayer.push(gaObject);

                    resolve(response);
                },
                failure: function () {
                    reject();
                },
            });
        });
    },

    checkToOpen() {
        let params = {};

        try {
            function getJsonFromUrl() {
                const url = location.search;
                const query = url.substr(1);
                const result = {};
                query.split('&').forEach(function (part) {
                    const item = part.split('=');
                    result[item[0]] = decodeURIComponent(item[1]);
                });
                return result;
            }

            params = getJsonFromUrl();
        } catch (e) {
            return;
        }

        if (!params.hasOwnProperty('showform')) {
            return;
        }

        if (params.showform !== 'true') {
            return;
        }

        if ($('.header-customer').hasClass('header-customer--authenticated')) {
            return;
        }

        this.redirectUrl =
            params.targeturl || params.original || window.Urls.home;

        const $form = $('#dwfrm_login');
        if ($form.length) {
            this.initLoginForm();
        } else {
            this.loadModal();
        }
    },
};

module.exports = newLogin;
