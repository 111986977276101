'use strict';
const dataLayer = require('../../dataLayer.js');
const _ = require('lodash');

const memoizedFetchDelivery = _.memoize(fetchDelivery, (args) => JSON.stringify(args));

function fetchDelivery({ url, data }) {
    return new Promise(function (resolve, reject) {
        $.ajax({
            contentType: 'application/json',
            type: 'POST',
            data: JSON.stringify(data),
            dataType: 'json',
            url: url,
            success: function (data) {
                resolve(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                reject(textStatus);
            }
        });
    });
}

function hideDeliveryOptions(data) {
    if (data.status) {
        if (data.data.homedelivery) {
            $('.show-hide__deliveryoptions').hide();
            $('.deliveryoptions__item-lists').hide();
            $('#daterange_delivery_list').hide();
            $('#scheduled_delivery_list').hide();
            $('#sameday_delivery_list').hide();
            $('#express_delivery_list').hide();
        }
    }
}

function getLevels(data) {
    const prod1 = data.data[0];
    var levels = [];

    for (var i = 0; i < prod1.length; i++) {
        if (prod1[i].hasOwnProperty('facility')) {
            var sonIguales = true;

            for (var j = 1; j < data.data.length; j++) {
                if (!sonIguales) {
                    break;
                }

                var prod2 = data.data[j];
                for (var k = 0; k < prod2.length; k++) {
                    if (prod1[i].type === prod2[k].type) {
                        sonIguales = prod2[k].hasOwnProperty('facility');

                        if (prod2[k].hasOwnProperty('facility') && prod2[k].facility !== prod1[i].facility) {
                            sonIguales = false;
                        }
                        break;
                    }
                }
            }

            if (sonIguales) {
                levels.push(prod1[i]);
            }
        }
    }

    return levels;
}

function showNSInfoInPDP({ data, scheduled, datarange, express, sameday, pickup, address }) {
    if (data.status) {
        if (data.bundle) {
            var levels = getLevels(data);

            if (levels.length < 1) {
                if ($("#box-cta").length) {
                    $("#box-cta").hide();
                    if (document.getElementById('out-of-stock-pdp')) {
                        document.getElementById('out-of-stock-pdp').removeAttribute("style");
                    }
                }
            }
        }

        data.data.forEach((element) => {
            switch (element.type) {
                case 'SCHEDULED':
                    if (element.status.code == 2000) {
                        $('#scheduled_delivery_list').show();
                        $('#scheduled_delivery_info').show();
                        $('#scheduled_delivery_price').show();
                        if (element.shippingPrice.status) {
                            $('#scheduled_delivery_info').html(`Recíbelo desde el <strong>${element.deliveryDate}</strong>`);
                            let price = element.shippingPrice.shippingPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                            $('#scheduled_delivery_price').html('$' + price);
                            $('#scheduled_delivery_price').attr('aria-label', price + ' ' + window.Resources.MONEY);
                        } else {
                            $('#scheduled_delivery_info').html(`Recíbelo desde el <strong>${element.deliveryDate}</strong>`);
                        }
                        scheduled = true;
                    }
                    break;
                case 'DATERANGE':
                    if (element.status.code == 2000) {
                        $('#daterange_delivery_list').show();
                        $('#daterange_delivery_info').show();
                        $('#daterange_delivery_price').show();
                        if (!scheduled) {
                            if (element.shippingPrice.status) {
                                $('#daterange_delivery_info').html(`Recibe entre ${element.deliveryDate} y ${element.deliveryDateEnd}`);
                                let price = element.shippingPrice.shippingPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                                $('#daterange_delivery_price').html('$' + price);
                                $('#daterange_delivery_price').attr('aria-label', price + ' ' + window.Resources.MONEY);
                            } else {
                                $('#daterange_delivery_info').html(`Recibe desde el ${element.deliveryDate}`);
                            }
                            datarange = true;
                        }
                    }
                    break;
                case '24HOURS':
                    if (element.status.code == 2000) {
                        $('#express_delivery_list').show();
                        $('#express_delivery_info').show();
                        $('#express_delivery_price').show();
                        if (element.shippingPrice.status) {
                            $('#express_delivery_info').html(`Cómpralo <strong>antes de las 20:00</strong>`);
                            let price = element.shippingPrice.shippingPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                            $('#express_delivery_price').html('$' + price);
                            $('#express_delivery_price').attr('aria-label', price + ' ' + window.Resources.MONEY);
                        } else {
                            $('#express_delivery_info').html(`Cómpralo <strong>antes de las 20:00</strong>`);
                        }
                        express = true;
                    }
                    break;
                case 'SAMEDAY':
                    if (element.status.code == 2000) {
                        $('#sameday_delivery_list').show();
                        $('#sameday_delivery_info').show();
                        $('#sameday_delivery_price').show();
                        if (element.shippingPrice.status) {
                            $('#sameday_delivery_info').html(`Cómpralo <strong>antes de las 13:00</strong>`);
                            let price = element.shippingPrice.shippingPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                            $('#sameday_delivery_price').html('$' + price);
                            $('#sameday_delivery_price').attr('aria-label', price + ' ' + window.Resources.MONEY);
                        } else {
                            $('#sameday_delivery_info').html(`Cómpralo <strong>antes de las 13:00</strong>`);
                        }
                        sameday = true;
                    }
                    break;
                case 'PICKUP':
                    if (element.status.code == 2000) {
                        $('#pickup_delivery_info').show();
                        $('#pickup_delivery_price').show();
                        $('#pickup_delivery_available_text').show();
                        if (element.shippingPrice.status) {
                            $('#pickup_delivery_info').html(`Podrás retirarlo desde el <strong>${element.deliveryDate}</strong>`);
                            $('#pickup_delivery_price').html(`Disponible`);
                        } else {
                            $('#pickup_delivery_info').html(`Podrás retirarlo desde el <strong>${element.deliveryDate}</strong>`);
                        }
                        pickup = true;
                    }
                    break;
            }
        });

        if (!pickup) {
            $('#pickup_delivery_available_text').hide();
            $('#pickup_delivery_info').hide();
            $('#pickup_delivery_available_pill').show();
        }
        if (!express) {
            $('#express_delivery_list').hide();
        }
        if (!sameday) {
            $('#sameday_delivery_list').hide();
        }
        if (!scheduled) {
            $('#scheduled_delivery_list').hide();
        }
        if (!datarange) {
            $('#daterange_delivery_list').hide();
        }

        $('.show-hide__deliveryoptions').show();
        $('.deliveryoptions__item-lists').show();

        if (!express && !sameday && !scheduled && !datarange) {
            $('.show-hide__deliveryoptions').hide();
            $('.deliveryoptions__item-lists').hide();
            $('#home_delivery_available_pill').show();
        }

    } else {
        memoizedFetchDelivery({
            url: Urls.getAvailibleShippingMethodsByPreference,
            data: {
                sku: $("#pid").val(),
                address: address
            }
        })
            .then(function (data) {
                hideDeliveryOptions(data);
            })
            .catch(function (error) {
                console.error('getAvailibleShippingMethodsByPreference', error);
            });
    }
}

function showBundlePDP(data, bundleID, bundleDIV) {
    if (data.status) {
        if (data.bundle) {
            var levels = getLevels(data);

            if (levels.length < 1) {
                const buttonID = '#' + bundleID + '-add-to-cart';

                if ($(buttonID).length) {
                    $(buttonID).hide();
                }

                if ($(bundleDIV).find('#out-of-stock-pdp').length) {
                    $($(bundleDIV).find('#out-of-stock-pdp')[0]).removeAttr("style");
                }
            }
        }
    }
}

const deliveryInfo = {
    bundlePDP: () => {
        const result = $('*[data-isbundle="true"]');
        const isEnableShippingInfoInPDP = SitePreferences.IS_ENABLE_SHIPPING_INFO_PDP;
        if(isEnableShippingInfoInPDP && result.length) {
            let address = false;
            if ("smart_customer_region" in localStorage) {
                address = JSON.parse(localStorage.getItem('smart_customer_region'));
                if($('#delivery-info-comuna-name')){
                    $('#delivery-info-comuna-name').text(address.comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
                }
            }

            for (var j = 0; j < result.length; j++) {
                if ($(result[j]).data('bundle-has-stock')) {
                    const bundleID = $(result[j]).data('itemid');
                    const bundleDIV = $(result[j]);

                    memoizedFetchDelivery({
                        url: Urls.getNSInfoInPDP,
                        data: {
                            sku: bundleID,
                            address: address
                        }
                    })
                        .then(function (data) {
                            showBundlePDP(data, bundleID, bundleDIV);
                        })
                        .catch(function (error) {
                            console.error('getNSInfoInPDP (deliveryInfo.bundlePDP)', error);
                        });
                }
            }
        }
    },
    init: () => {
        const isEnableShippingInfoInPDP = SitePreferences.IS_ENABLE_SHIPPING_INFO_PDP;
        const isProductMasterStr = $('#isMasterProduct').val();
        const isProductMaster = (isProductMasterStr) ?  JSON.parse(isProductMasterStr) : false;

        if (!isProductMaster && isEnableShippingInfoInPDP) {
            clearDeliveryOptionsListeners();
            initDeliveryOptionsVisibility();
            
            const nivelesServicioInput = {
                address: false,
                scheduled: false,
                pickup: false,
                express: false,
                sameday: false,
                datarange: false
            };
            
            if ("smart_customer_region" in localStorage) {
                nivelesServicioInput.address = JSON.parse(localStorage.getItem('smart_customer_region'));
                if($('#delivery-info-comuna-name')){
                    $('#delivery-info-comuna-name').text(nivelesServicioInput.address.comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
                }
            }
            
            if ($('.deliveryoptions').length) {
                memoizedFetchDelivery({
                    url: Urls.getNSInfoInPDP,
                    data: {
                        sku: $('#productiD').val(),
                        address: nivelesServicioInput.address
                    }
                })
                    .then(function (data) {
                        nivelesServicioInput.data = data;
                        showNSInfoInPDP(nivelesServicioInput);
                    })
                    .catch(function (error) {
                        console.error('getNSInfoInPDP (deliveryInfo.init)', error);
                    });
            }
            $('#link-delivery-info-comuna').on('click', () => {
                $('#header-comuna-source-btn').click();
                if ("smart_customer_region" in localStorage) {
                    $("#empty-comuna-delivery-info").hide();
                } else {
                    $("#empty-comuna-delivery-info").show();
                }
                
            });
    
            if ("smart_customer_region" in localStorage) {
                $("#empty-comuna-delivery-info").hide();
            } else {
                $("#empty-comuna-delivery-info").show();
            }
            setAriaLabelToDeliveryoptionTooltip();
        }
    }, 
}

function initDeliveryOptionsVisibility() {
    $('.show-hide__deliveryoptions').on('click', function () {
        $('.deliveryoptions__item-lists').toggleClass('hide');
        $('.show-hide__deliveryoptions').toggleClass('show-hide__deliveryoptions__hide');
        setAriaLabelToDeliveryoptionTooltip();
    });
    
    $('.show-hide__deliveryoptions').on('keypress', function (e) {
        if(e.keyCode === 13 || e.keyCode === 32){
            e.preventDefault();
            $(e.target).trigger('click')
        }
    });
}

function setAriaLabelToDeliveryoptionTooltip() {
    let ariaLabel = $('.show-hide__deliveryoptions').get(0)?.classList.contains('show-hide__deliveryoptions__hide') ? 
    window.Resources.SHOW_DELIVERY_OPTIONS : window.Resources.HIDE_DELIVERY_OPTIONS;
    $('.show-hide__deliveryoptions').attr('aria-label', ariaLabel);
}

function clearDeliveryOptionsListeners() {
    $('.show-hide__deliveryoptions').off();
}

clearDeliveryOptionsListeners();
initDeliveryOptionsVisibility();
module.exports = deliveryInfo;
