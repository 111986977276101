'use strict';

var util = require('./util'),
    _ = require('lodash');


var selectbox = {
    init: function (elem) {
        if (elem.length > 0) {
            elem.each(function() {
                var selectBox = $(this).next($("js-selectbox")),
                    selectBoxSelected = selectBox.find($("js-option-selected")),
                    selectBoxItem = selectBox.find($("js-option"));

                selectbox.update($(this), selectBox);
            })
        }
    },

    update: function (elem, selector) {
        var options = elem.find("option");
        var currOptions = selector.find(".js-option");
        var disabled = currOptions.attr("data-disabled") === "true";
        var variants = currOptions.not(disabled);
        var selectedVal = selector.find(".js-selected-value");
        var selectedOpt = selector.find(".js-option[selected='selected']");
        var emptySelect = selector.find(".js-select-empty");
        
        if (selectedOpt.length > 0) {
            selectedVal.each(function(){
                selectedOpt = $(this).parent().find(selectedOpt);
                $(this).empty();
                $(this).text(selectedOpt.text());
            });
        } else {
            selectedVal.each(function(){
                emptySelect = $(this).parent().find(emptySelect);
                $(this).empty();
                $(this).text(emptySelect.text());
            });
        }

        selectedVal.on("click", function(){
            $(this).parent().find(".js-options").toggle();
        });

        currOptions.each(function() {
            var $this = $(this);
            if ($this.attr("data-disabled") === "true") {
                $this.addClass("unselectable");
            }
        });

        var variants = currOptions.not(".unselectable");
        variants.on("click", function() {
            currOptions.parent().hide();
            $(this).parents('.box-product').addClass('flagged');
            var selection = $(this).attr("data-index");

            //selects option in native selectbox
            var selectBoxOption = $(this).parent().parent().prev(elem).find("option[data-index=" + selection + "]");
            variants.removeAttr("selected");
            $(this).attr("selected", "selected");
            var optionElements = $(this).parent().parent().prev(elem).find("option");
            optionElements.prop('selected', false);
            selectBoxOption.prop("selected", "selected");
            selectedVal.off("click");
            elem.trigger("change");
        })

    },

    destroy: function () {

    },
    exists: function () {
        return this.$select && (this.$select.length > 0);
    }
};

module.exports = selectbox;
