'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    $(document).tooltip({
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        }
    });

    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });

    $('.icon-info').on('keypress', (e)=>{
        if(e.keyCode === 13 || e.keyCode === 32){
            e.preventDefault();
            $(e.target).addClass('openTooltip');
            $('.tooltiptext').focus();
            $('.tooltiptext').on('blur', ()=>{
                $(e.target).removeClass('openTooltip');
            });
            $('.tooltiptextDiv').on('keyup', (e)=>{
                if(e.keyCode === 27){
                    $(e.target).removeClass('openTooltip');
                    $('.icon-info').focus();
                }
            });
        }
    });
};
