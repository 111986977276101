'use strict'
var util = require('./util');
var image = require('./pages/product/image');

const ProductsMarking = require('./utils/ProductsMarking');

function initializeSliderListingTransformation(mq) {
	if(mq.matches) {
		$('.product-carousel-listing:not(.slick-initialized)').slick(productSlotSliderConf(3));
	} else if($('.product-carousel-listing.slick-initialized').length > 0) {
		$('.product-carousel-listing').slick('unslick');
	}
}

function productSlotSliderConfPdp() {
	var sliderConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		lazyLoad: 'ondemand',
		centerMode: false,
		arrows: false,
		mobileFirst: true,
		centerPadding: '60px',
		dots: true,
		responsive: [{
			breakpoint: 330,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				centerMode: false,
				centerPadding: '100px',
				arrows: false,
				infinite: true,
			}
		}, {
			breakpoint: 413,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				centerMode: false,
				centerPadding: '100px',
				arrows: false,
				infinite: true,
			}
		}, {
			breakpoint: 567,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				centerMode: false,
				centerPadding: '100px',
				arrows: false,
				infinite: true,
			}
		}, {
			breakpoint: 666,
			settings: {
				centerMode: false,
				centerPadding: '100px',
				slidesToShow: 2,
				slidesToScroll: 2,
				arrows: false,
				infinite: true,
			}
		}, {
			breakpoint: 767,
			settings: {
				centerMode: false,
				slidesToShow: 4,
				slidesToScroll: 4,
				arrows: false,
				infinite: true,
			}
		}, {
			breakpoint: 992,
			settings: {
				centerMode: false,
				slidesToShow: 5,
				slidesToScroll: 5,
				arrows: false,
				infinite: true,
			}
		}, {
			breakpoint: 1365,
			settings: {
				centerMode: false,
				slidesToShow: 5,
				slidesToScroll: 5,
				arrows: true,
				dots: true,
				infinite: true,
			}
		}]
	}
	return sliderConfig;
}

function productSlotSliderConf(itemsPerLine, page) {
	var sliderConfig = {
		slidesToScroll: 1,
		slidesToShow: 1,
		lazyLoad: 'ondemand',
		centerMode: true,
		arrows: false,
		mobileFirst: true,
		centerPadding: '60px',
		responsive: [{
			breakpoint: 330,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
				centerPadding: '100px',
				arrows: false
			}
		},  {
			breakpoint: 413,
			settings: {
				slidesToShow: 1,
				centerPadding: '100px',
				arrows: false
			}
		}, {
			breakpoint: 567,
			settings: {
				slidesToShow: 2,
				centerPadding: '100px',
				arrows: false
			}
		}, {
			breakpoint: 666,
			settings: {
				centerPadding: '100px',
				slidesToShow: 2,
				arrows: false
			}
		}, {
			breakpoint: 767,
			settings: {
				centerPadding: '50px',
				slidesToShow: 3,
				arrows: false
			}
		}, {
			breakpoint: 1023,
			settings: {
				centerPadding: '100px',
				slidesToShow: 3,
				arrows: true
			}
		}, {
			breakpoint: 1365,
			settings: {
				centerMode: false,
				slidesToShow: itemsPerLine,
				arrows: true
			}
		}]
	}

	if (page !== 'undefined' && page === 'home') {
		sliderConfig.responsive[4] = {
			breakpoint: 992,
			settings: {
				centerMode: false,
				slidesToShow: itemsPerLine,
				arrows: false
			}
		}
	}

	return sliderConfig
}

function colorSliderConf(initialSlide) {
	return {
		slidesToShow: 5,
		slidesToScroll: 1,
		mobileFirst: false,
		focusOnSelect: false,
		draggable: false,
		initialSlide: initialSlide,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 361,
				settings: {
					slidesToShow: 4
				}
			}
		]
	}
}

function categoryWorldLandingConf() {
	return {
		dots: true,
		slidesToShow: 6,
		slidesToScroll: 6,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					arrows: true,
					dots: true,
				},
			},
		],
	};
}

function productSlotSliderConfPdpAb() {
    var sliderConfig = {
		slidesToScroll: 1,
		slidesToShow: 1,
		infinite: true,
		lazyLoad: 'ondemand',
		centerMode: true,
		arrows: true,
		mobileFirst: true,
		centerPadding: '60px',
		dots: true,
	}
	return sliderConfig;
}

var sliders = {
	initializeColorCarousels: function (carouselElement, initialSlide) {
		var defaultCarouselElement = $('.carousel-colors.swatch-toggle:not(.slick-initialized)');
		var $colorsCarousel = carouselElement ? carouselElement : defaultCarouselElement;
		initialSlide = parseInt(initialSlide) ? initialSlide : 0;
		$colorsCarousel.slick(colorSliderConf(initialSlide));
	},

	unslickColorCarousels: function (carouselElement) {
		if (carouselElement && carouselElement.hasClass('slick-initialized')) {
			carouselElement.slick('unslick');
		} else {
			var defaultCarouselElement = $('.carousel-colors.swatch-toggle.slick-initialized');
			defaultCarouselElement.slick('unslick');
		}
	},

	initializeSliders: function() {
		$('.category-word-landing-slider:not(.slick-initialized)').slick(categoryWorldLandingConf()).on('beforeChange', function () {
			image.lazyLoad();
		});

		$('.js-product-slider ul:not(.slick-initialized, .carousel-colors)').slick(productSlotSliderConf(3, 'home')).on('beforeChange', function () {
			image.lazyLoad();
		});

		var horizontalCarousel = $('.horizontal-carousel');
		horizontalCarousel.each(function (index, element) {
			var horizontalItemsPerLine = $(element).data('itemsPerLine') || 4;
			if ($(element).children('li').length > 0 && $(element).is(':not(.slick-initialized)')) {
				const el = $(element);
				const listMarkingEnabled = el.data('listMarking') || 0;
				const listMarkingDescription = el.data('listMarkingDescription');
				const listMarkingSlot = el.data('listMarkingSlot');
				const isHomeCarousel = el.data('listMarkingIsInHome');
				const homeOrder = el.data('listMarkingHomeOrder');

				const Marking = listMarkingEnabled ? new ProductsMarking() : null;

				if (listMarkingEnabled) {
					let listName = '';
					if(isHomeCarousel){
						const homeVitrina = window.Resources.GTM_HOME_CAROUSEL_LIST_PARAMETER;
						listName = `${homeVitrina} ${homeOrder}: ${listMarkingDescription}`
					}else{ 
						listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
					}
					Marking.defineListName(listName);
					Marking.defineIsInHome(isHomeCarousel);
				}

				el.on('init', (e, slick) => {
					if (!!Marking) {
						Marking.handleChangeSlide(slick);
					}

					const { handleAddToCartFromPLP } = require('./pages/product/addToCart');
					
					el.find('.product-tile').each(function(i, el) {
						$(el).find('.add-to-cart-from-plp').on('click', handleAddToCartFromPLP);	
					})
				});

				el.slick(productSlotSliderConf(horizontalItemsPerLine));

				el.on('afterChange', (e, slick, currentSlide) => {
					if (!!Marking) {
						Marking.handleChangeSlide(slick, currentSlide);
					}

					setTimeout(() => {
						image.lazyLoad();
					}, 100);
				});
			}
		})

		$('.hero-slider:not(.slick-initialized)').slick({
			responsive: [{
				breakpoint: 1024,
				settings: {
					arrows: false,
					dots: true
				}
			}]
		});

		$('.slider:not(.slick-initialized)').slick({
			responsive: [{
				breakpoint: 1024,
				settings: {
					arrows: true,
					dots: false
				}
			}]
		});

		var productSlider = $('.js-product-slider');
		productSlider.each(function (index, element) {
			if ($(element).children('li').length > 0 && $(element).is(':not(.slick-initialized)')) {
				const el = $(element);
				const listMarkingEnabled = el.data('listMarking') || 0;
				const listMarkingDescription = el.data('listMarkingDescription');
				const listMarkingSlot = el.data('listMarkingSlot');
				const isHomeCarousel = el.data('listMarkingIsInHome');
				const homeOrder = el.data('listMarkingHomeOrder');

				const Marking = listMarkingEnabled ? new ProductsMarking() : null;

				if (listMarkingEnabled) {
					let listName = '';
					if(isHomeCarousel){
						const homeVitrina = window.Resources.GTM_HOME_CAROUSEL_LIST_PARAMETER;
						listName = `${homeVitrina} ${homeOrder}: ${listMarkingDescription}`
					}else{ 
						listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
					}
					Marking.defineListName(listName);
					Marking.defineIsInHome(isHomeCarousel);
				}

				el.on('init', (e, slick) => {
					if (!!Marking) {
						Marking.handleChangeSlide(slick);
					}
				});

				el.slick(productSlotSliderConfPdp());

				el.on('afterChange', (e, slick, currentSlide) => {
					if (!!Marking) {
						Marking.handleChangeSlide(slick, currentSlide);
					}

					setTimeout(() => {
						image.lazyLoad();
					}, 100);
				});
			}
		})

		var resizeTimer;
		var that = this;
		$(window).on('resize', function(e) {
			clearTimeout(resizeTimer);
			that.unslickColorCarousels();
			resizeTimer = setTimeout(function() {
				// Resizing has stopped
				that.initializeColorCarousels();
			}, 300);
		});

		util.matchMedia(767, initializeSliderListingTransformation);
	},

	mutationHandler: function (mutationList) {
		for (var i = 0; i < mutationList.length; i++) {
			if ((mutationList[i].type == 'childList' || mutationList[i].type == 'subtree') && $(mutationList[i].target).is('[id^="cq_recomm"]')) {
				var cqRecomm = $(mutationList[i].target);
				let el = cqRecomm.find('.pdp-ein-horizontal-carousel:not(.slick-initialized)');
				if (el.length > 0) {
					const config = productSlotSliderConfPdp();
					const listMarkingEnabled = el.data('listMarking') || 0;
					const listMarkingDescription = el.data('listMarkingDescription');
					const listMarkingSlot = el.data('listMarkingSlot');

					const Marking = listMarkingEnabled ? new ProductsMarking() : null;

					if (listMarkingEnabled) {
						const listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
						Marking.defineListName(listName);
					}

					el.on('init', (e, slick) => {
						if (!!Marking) {
							Marking.handleChangeSlide(slick);
						}
					});

					el.slick(config);

					el.on('afterChange', (e, slick, currentSlide) => {
						if (!!Marking) {
							Marking.handleChangeSlide(slick, currentSlide);
						}

						image.lazyLoad();
					});
				}

				el = cqRecomm.find('.ein-horizontal-carousel:not(.slick-initialized)');
				if (el.length > 0) {
					const einHorizontalItemsPerLine = el.data('itemsPerLine') || 4;
					const config = productSlotSliderConf(einHorizontalItemsPerLine);
					const listMarkingEnabled = el.data('listMarking') || 0;
					const listMarkingDescription = el.data('listMarkingDescription');
					const listMarkingSlot = el.data('listMarkingSlot');

					const Marking = listMarkingEnabled ? new ProductsMarking() : null;

					if (listMarkingEnabled) {
						const listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
						Marking.defineListName(listName);
					}

					el.on('init', (e, slick) => {
						if (!!Marking) {
							Marking.handleChangeSlide(slick);
						}
					});

					el.slick(config);

					el.on('afterChange', (e, slick, currentSlide) => {
						if (!!Marking) {
							Marking.handleChangeSlide(slick, currentSlide);
						}

						image.lazyLoad();
					});
				}

                el = cqRecomm.find('.pdp-ein-horizontal-carousel-ab:not(.slick-initialized)');
                if (el.length > 0){
                    const config = productSlotSliderConfPdpAb();
                    const listMarkingEnabled = el.data('listMarking') || 0;
					const listMarkingDescription = el.data('listMarkingDescription');
					const listMarkingSlot = el.data('listMarkingSlot');

					const Marking = listMarkingEnabled ? new ProductsMarking() : null;

					if (listMarkingEnabled) {
						const listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
						Marking.defineListName(listName);
					}

					el.on('init', (e, slick) => {
						if (!!Marking) {
							Marking.handleChangeSlide(slick);
						}
					});

					el.slick(config);

					el.on('afterChange', (e, slick, currentSlide) => {
						if (!!Marking) {
							Marking.handleChangeSlide(slick, currentSlide);
						}

						image.lazyLoad();
					});
                }

				if (window.sliderAdjustable) {
					window.sliderAdjustable.init();
				}

				image.lazyLoad();
			}
		}
	},

	initializeEinSliders: function() {
		// Create an observer instance linked to the callback function
		var observer = new MutationObserver(sliders.mutationHandler);

		// Start observing the target node for configured mutations
		$('#main').each(function () {
			observer.observe(this, {childList: true, subtree: true});
		});

		var resizeTimer;
		var that = this;
		$(window).on('resize', function(e) {
			clearTimeout(resizeTimer);
			that.unslickColorCarousels();
			resizeTimer = setTimeout(function() {
				// Resizing has stopped
				that.initializeColorCarousels();
			}, 300);
		});
	},

	initializeBrandSlider: function () {
		$('.clp-carousel').slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			lazyLoad: 'ondemand',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
						dots: true,
						arrows: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						dots: true,
						arrows: false
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						dots: true,
						arrows: false
					}
				}
			]
		});
	},

	initialSwatchesMobSwipe: function () {
		try{			
			$('.swtchs').slick({
				slidesToShow: 3,
				infinite: false,
				arrows: false,
				responsive: [
				{
					breakpoint: 9999,
					settings: 'unslick'
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 2,
						infinite: false
					}
				},
				{
					breakpoint: 480,
					settings: {
						  arrows: false,
						slidesToShow: 2,
						infinite: false
					}
				  }
				]
			});
		}
		catch(e){
			console.log("Error initialSwatchesMobSwipe");
		}
	},

	initialSliderProdEvent: function(){
		$('.product-carrusel-cyber').slick({
			slidesToShow: 6,
			autoplay: false,
			dots: false,
			arrows: false,
			centerMode: false,
			draggable: false,
			responsive: [
				{
					breakpoint: 1366,
					settings: {
						autoplay: false,
						dots: false,
						arrows: false,
						slidesToShow: 6,
						draggable: false
					}
				},
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 5,
						dots: true,
						arrows: true,
						slidesToScroll: 1,
						draggable: true
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						dots: true,
						arrows: true,
						slidesToScroll: 1,
						draggable: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						dots: true,
						arrows: true,
						slidesToScroll: 1,
						draggable: true
					}
				},
				{
					breakpoint: 450,
					settings: {
						slidesToShow: 1,
						dots: true,
						arrows: true,
						slidesToScroll: 1,
						centerMode: true,
						draggable: true
					}
				}
			]
		});
	},

	initialQtySliders: function(){
        $(".product-carrusel-cyber").on("touchmove", swipeOnCarousel);

        function swipeOnCarousel(){
            var x, i;
            x = document.querySelectorAll('.qty-slides');
            for (i = 0; i < x.length; i++){
                x[i].classList.add('hide');
            }
            removeFunction();
        }

        function removeFunction(){
            $(".product-carrusel-cyber").off("touchmove", swipeOnCarousel);
        }
  }
};

module.exports = sliders;
