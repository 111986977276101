'use strict';

var util = require('./util'),
    dialog = require('./dialog'),
    ajax = require('./ajax'),
    bonusProductsView = require('./bonus-products-view'),
    tls = require('./tls'),
    addToCart = require('./pages/product/addToCart'),
    pageInformation = require('./components/pageInformation'),
    _ = require('lodash');

var timer = {
    id: null,
    clear: function() {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function(duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};
/**
 * @description this function anchors the element towards 
 * the technical specifications of the product in the PDP
 */
function anchorElement() {
	$('.js-technicalDetails').on('click',  '#js-viewMoreTechnicalDetails',  function(e)  {
		e.preventDefault();
        var $desciptionTite = $('#pdp-spec-button-tab').offset();
        document.getElementById('pdp-spec-button-tab').click();
        $('html, body').animate({
            scrollTop: $desciptionTite.top
        });
	});
};
// function anchorElementPdp() {
// 	$('.pdp-main_details').on('click',  '#pdp-main_details',  function(e)  {
// 		e.preventDefault();
// 		var $desciptionTite = $('.pdp-product-info_spec').offset();
// 		$('html, body').animate({
// 			scrollTop: $desciptionTite.top,
// 			scrollLeft: $desciptionTite.left
// 		});
// 	});
// };
function removeDisableButtonClass(){
    if(document.getElementById("productID") !== null) {
        const getProductId = document.getElementById("productID").value;
        const $btn = $(`#${getProductId}-add-to-cart`);
    
        if ($btn.length) {
            $btn.removeClass('disabled-button');
        }
    }
};

/**
 * @description Closures that allows free interaction with the quantity
 * buttons of a product, helping to reduce the number of API requests.
 */
function miniCartQtyButtonsActions() {
    let startQty = 0;
    let loading = false;
    let currentRequest = { abort(){} };

    function getProductInput() {
        const parent = $(this).closest('div');
        const fieldName = $(this).data('field');
        const qtyInput = parent.find('input[name=' + fieldName + ']');
        const currentVal = parseInt(qtyInput.val(), 10) || 0;
        const sku = parent.find('input#sku').val() || 0;

        return { parent, qtyInput, currentVal, sku };
    }

    function loadingMiniCartStart($currentQtyButtons) {
        const $qtyButtons = $('#mini-cart .js-btn-plusMC, #mini-cart .js-btn-minusMC');
        const $totals = $('#mini-cart .box-totales-mc');
        const $totalsCalculations = $('#mini-cart .box-total-mc p > *:last-child:contains("$")');
        const $callToActionAnchors = $('#mini-cart .box-controles-mc');

        $qtyButtons.prop('disabled', true);
        if ($currentQtyButtons) $currentQtyButtons.prop('disabled', false);

        if (!loading) {
            $totalsCalculations.css('visibility', 'hidden');
            $totals.append('<div class="loading-totals" aria-label="Calculando totales"></div>');

            $callToActionAnchors.css('cursor', 'not-allowed');
            $callToActionAnchors.find('a').addClass('disabled');
        }

        loading = true;
    }

    function loadingMiniCartEnd() {
        loading = false;
    }

    function waitingForMiniCartUpdate(qty) {
        currentRequest.abort();

        const { parent, qtyInput, currentVal } = getProductInput.call(this);
        const $currentQtyButtons = parent.find('.js-btn-plusMC, .js-btn-minusMC');
        const updatedVal = currentVal + qty;

        if (!loading) startQty = currentVal;

        loadingMiniCartStart($currentQtyButtons);
        modifyMiniCartQtyHeaderBy(qty);
        qtyInput.val(updatedVal);

        // Quantity restrictions of the product
        const allValues = parent.find('input#allValues').val().split(',') || 0;
        const maxQtyToAdd = parseInt(allValues[0], 10) || 0;
        const availableQty = parseInt(allValues[1], 10) || 0;

        const minusButton = parent.find('.js-btn-minusMC');
        const plusButton = parent.find('.js-btn-plusMC');

        if (updatedVal <= 1) {
            minusButton.prop('disabled', true);
        } else {
            minusButton.prop('disabled', false);
        }
        if (updatedVal >= maxQtyToAdd || updatedVal >= availableQty) {
            plusButton.prop('disabled', true);
        } else {
            plusButton.prop('disabled', false);
        }
    }

    function updateMiniCartQty() {
        loadingMiniCartStart();

        const { currentVal, sku } = getProductInput.call(this);
        const deltaQty = currentVal - startQty;

        if (deltaQty === 0) {
            currentRequest = $.ajax({
                url: util.ajaxUrl(Urls.minicartShow),
                success: manageResponseQtyMC,
                complete: loadingMiniCartEnd
            });
        } else {
            currentRequest = $.ajax({
                url: util.ajaxUrl(Urls.addProduct),
                data: { 'pid': sku, 'Quantity': deltaQty, 'cartAction': 'update' },
                success: manageResponseQtyMC,
                complete: loadingMiniCartEnd
            });

            const isIncrement = deltaQty >= 1;
            const dataLayerEvent = isIncrement ? 'addToMiniCart' : 'removeFromMiniCart';
            const dataLayerModifier = isIncrement ? 'add' : 'remove';
            const minilineItemObj = $('#' + String(sku)).data('minilineitem');
            const productsArr = [minilineItemObj];
            productsArr[0].quantity = deltaQty;
            window.dataLayer.push({
                'event': dataLayerEvent,
                'ecommerce': {
                    [dataLayerModifier]: {
                        'products': productsArr
                    }
                }
            });
        }
    }

    function addQtyMiniCart() {
        waitingForMiniCartUpdate.call(this, 1);
    }

    function removeQtyMiniCart() {
        waitingForMiniCartUpdate.call(this, -1);
    }

    function updateMiniCart() {
        updateMiniCartQty.call(this);
    }

    return { addQtyMiniCart, removeQtyMiniCart, updateMiniCart };
}

const { addQtyMiniCart, removeQtyMiniCart, updateMiniCart } = miniCartQtyButtonsActions();
const debouncedUpdateMiniCart = _.debounce((event) => {
    updateMiniCart.call(event.target);
}, 600);

var minicart = {
    init: function( param ) {
        if (param ) {
            //update product qty from pdp
            $('#js-input-group-pdp').on('click',  '.js-btn-plusPDP',  function(e)  {
                e.preventDefault();
                incrementQtyPDP(this);
            });
            //update product qty from pdp
            $('#js-input-group-pdp').on('click',  '.js-btn-minusPDP',  function(e)  {
                e.preventDefault();
                decrementQtyPDP(this);
            });
        }

        $(document).on('click', '#mini-cart-link-checkout', function(e) {
            e.preventDefault();
            e.stopPropagation();

            const isNoviosCart = SitePreferences.IS_NOVIOS_CART;
            const smartCustomerRegion = JSON.parse(localStorage.getItem('smart_customer_region'));
            if (smartCustomerRegion || isNoviosCart) {
                window.location.href = $('#mini-cart-link-checkout').data('href');
            } else {
                minicart.close();
                $('html').removeClass('disabled-scroll');
                $('.headerSlidingDropdown').css('opacity', '1');
                $('#header-comuna-source-btn').click();
            }
        });

        $(document).on('click', '#mini-cart-link-cart', function(e) {
            e.preventDefault();
            e.stopPropagation();

            window.location.href = $('#mini-cart-link-cart').data('href');
        });

        // Update product quantity from mini cart
        $('.js-input-group-mc').on('click', '.js-btn-plusMC', addQtyMiniCart);
        $('.js-input-group-mc').on('click', '.js-btn-plusMC', debouncedUpdateMiniCart);

        // Update product quantity from mini cart
        $('.js-input-group-mc').on('click', '.js-btn-minusMC', removeQtyMiniCart);
        $('.js-input-group-mc').on('click', '.js-btn-minusMC', debouncedUpdateMiniCart);

        $('.js-remove-bonus-product').on('click', function () {
            window.dataLayer.push({
                'event-interactive-category': 'Sapling', 
                'event-interactive-accion': 'Eliminar del carro', 
                'event-interactive-tag': $(this).attr('data-id'),
                'event': 'event-interactive'
            });
        });

        $('.js-remove-product-line-item-bonus').on('click', function() {
            var promoId = $(this).attr('data-id-bonus-promo');
            var promoElements = $(".js-remove-product-line-item-bonus[data-id-bonus-promo='" + promoId +"']");
            var samplingElements = $(".js-minicart-bonuslineitem[data-is-sampling-product='1']");
            if(promoElements.length > 1 || samplingElements.length == 0){
                var pliUUID = $(this).data('product-line-item-id');
                var minilineItemObj = $(this).data('minilineitem');
                var isBonusLineItem = $(this).data('is-bonus-product');
                if(!isBonusLineItem){
                    delete minilineItemObj.image_data;
                }
                var productsArr = [minilineItemObj];
                $.ajax({
                    type: 'GET',
                    url: util.ajaxUrl(Urls.minicartRemoveItem),
                    data: { pliUUID: pliUUID }
                }).done(function(response) {
                    minicart.showAjax();
                    if(isBonusLineItem){
                        console.log("DataLayer Eliminar")
                    } else {
                        window.dataLayer.push({
                            'event': 'removeFromMiniCart',
                            'ecommerce': {
                                'remove': {
                                    'products': productsArr
                                }
                            }
                        });
                    }
                    //refresh page if on the main basket
                    if ($('.js-cart-container').length) {
                        if (location.search != "") {
                            location = location.origin + location.pathname;
                        } else {
                            location.reload();
                        }
                    }
                    $('html').removeClass('disabled-scroll');
                    $('.loader-button').remove();
                    $('span.add-to-cart__label').removeClass('hide');
                });
            } else {
                minicart.close();
                var uuidModal = $(this).attr('data-uuid-modal-minicart');
                var buttonId = "delete-product-modal-minicart-" + uuidModal;
                if (window.matchMedia('(max-width: 768px)').matches) {
                    $('.' + buttonId).removeAttr('style');
                    $('html').addClass('ui-scroll-disabled');
                    $('.' + buttonId).addClass('modal__delete-product opened');
                    $('html').append('<div class="overlay__delete-product opened"></div>');
                    // Get the div promotional pods
                    const getModalPromotionalPods = document.querySelector('.modal__delete-product');
                    // Create <div> HTML element for title
                    const createDivTitleTag = document.createElement('div');
                    // Add class for the <div> HTML element for title
                    createDivTitleTag.classList.add('modal__delete-product-title');
                    // Create H3 Tag element for title
                    const createH3TagTitle = document.createElement('H3');
                    // Create title text for modal PODS
                    const createTitleModalPodsText = document.createTextNode('Se eliminarán tus muestras');
                    // Put the H3 tag, into the div element
                    createDivTitleTag.appendChild(createH3TagTitle);
                    // Put the text 'Muestra de regalo' into H3 tag
                    createH3TagTitle.appendChild(createTitleModalPodsText);
                    // Put the div with the class 'modal__delete-product-title', the h3 with title, into the modal
                    getModalPromotionalPods.insertBefore(createDivTitleTag, getModalPromotionalPods.childNodes[0]);
                    // Get the div element with the class 'modal__delete-product-title'
                    const getModalTitle = document.querySelector('.modal__delete-product-title');
                    // Create the close button HTML element
                    const createButtonCloseModal = document.createElement('button');
                    // Put the button, into the div 'modal__delete-product-title'
                    getModalTitle.appendChild(createButtonCloseModal);
                    // Add classes to the button created
                    createButtonCloseModal.classList.add('modal__delete-product-title-close');
                    // Get te button created
                    const getButtonCloseModal = document.querySelector('.modal__delete-product-title-close');
                    // Create the attribute type
                    const buttonTypeAttribute = document.createAttribute('type');
                    // Create the attribute aria-label
                    const buttonAriaLabelAtttribute = document.createAttribute('aria-label');
                    // Set the attribute value button
                    buttonTypeAttribute.value = 'button'
                    // Set the aria-label attribute to the button
                    buttonAriaLabelAtttribute.value = 'Cerrar'
                    // Add the attributes type button and aria-label, to the button element
                    getButtonCloseModal.setAttributeNode(buttonTypeAttribute);
                    getButtonCloseModal.setAttributeNode(buttonAriaLabelAtttribute);
                    // Create X image to the modal
                    createButtonCloseModal.innerHTML = '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m9.846 9.004 7.603-7.602a.6.6 0 1 0-.851-.85L8.995 8.152 1.402.561a.602.602 0 0 0-.85.85l7.593 7.593L.56 16.589a.602.602 0 0 0 .85.85l7.585-7.584 7.594 7.593a.599.599 0 0 0 .85 0 .6.6 0 0 0 0-.85L9.847 9.004Z" fill="#00355B"/></svg>';
                    // Close the modal pod
                    $('.modal__delete-product-title-close, .overlay__delete-product').on('click', function(){
                        $('html').removeClass('ui-scroll-disabled');
                        $('#' + buttonId + '-modal').removeClass('modal__delete-product opened');
                        $('#' + buttonId + '-modal').attr('style', 'display:none');
                        $('.overlay__delete-product').remove();
                        getModalTitle.remove();
                    });      
    
                } else {
                    $('.' + buttonId).dialog({
                        classes: {
                            "ui-dialog": "ui-dialog__sampling"
                        },
                        modal: true,
                        draggable: false,
                        title: "Se eliminarán tus muestras",
                        width: '385',
                        open: function() {
                            $('html').addClass('ui-scroll-disabled');
                            $('.ui-widget-overlay').each((i, el) => {
                                $(el).on('click', () => handleCloseModal(true));
                            });
                            $('.js-delete-product-and-bonus-minicart').on('click', function() {
                                var uuid = $(this).attr('data-uuid');
                                var minilineItemObj = $(this).data('minilineitem');
                                var productsArr = [minilineItemObj];
                                $.ajax({
                                    type: 'GET',
                                    url: util.ajaxUrl(Urls.minicartRemoveItem),
                                    data: { pliUUID: uuid }
                                }).done(function(response) {
                                    minicart.showAjax();
                                    if(isBonusLineItem){
                                        console.log("DataLayer Eliminar")
                                    } else {
                                        window.dataLayer.push({
                                            'event': 'removeFromMiniCart',
                                            'ecommerce': {
                                                'remove': {
                                                    'products': productsArr
                                                }
                                            }
                                        });
                                    }
                                        if (location.search != "") {
                                            location = location.origin + location.pathname;
                                        } else {
                                            location.reload();
                                        }
                                    
                                    $('html').removeClass('disabled-scroll');
                                    $('.loader-button').remove();
                                    $('span.add-to-cart__label').removeClass('hide');
                                    $(this).dialog('close');
                                });
                            });
                            
                            $('.js-close-modal-bonus-product-minicart').each((i, el) => {
                                $(el).on('click', () => handleCloseModal(true));
                            });
                        },
                        close: function() {
                            $(this).dialog('close');
                            $(this).dialog("destroy");
                            $('html').removeClass('ui-scroll-disabled');
                        }
                    });
                    // $('.ui-button').attr('aria-label', 'Cerrar');
                    function handleCloseModal() {
                        $('.' + buttonId).dialog('close');
                        $('html').removeClass('ui-scroll-disabled');
                    }
                }
            }
        });

        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');

        $('.mini-cart-product').eq(0).find('.mini-cart-toggle').addClass('fa-caret-down');
        $('.mini-cart-product').not(':first').addClass('collapsed')
            .find('.mini-cart-toggle').addClass('fa-caret-right');

        $('.mini-cart-toggle').on('click', function() {
            $(this).toggleClass('fa-caret-down fa-caret-right');
            $(this).closest('.mini-cart-product').toggleClass('collapsed');
        });

        $(document).off('click', '.js-checkout-disabled');
        $(document).on('click', '.js-checkout-disabled', function(e) {
            e.preventDefault();
            e.stopPropagation();
        });
        let miniCartQty = $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").text().trim()
        let miniCartCookieQty = tls.getCookie("paris_minicart_count");
        if (miniCartQty != miniCartCookieQty) {
            $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").text(miniCartCookieQty);
        }
        if (miniCartCookieQty == '' || miniCartCookieQty == '0') {
            $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").addClass('hidden');
        } else {
            $("#mini-cart > div.box-carro-header > a > span.icon-cart-empty > p").removeClass('hidden');
        }

        // remove products
        $('.js-remove-product-line-item').on('click', function() {

            var pliUUID = $(this).data('product-line-item-id');
            var minilineItemObj = $(this).data('minilineitem');
            var isBonusLineItem = $(this).data('is-bonus-product');
            if(!isBonusLineItem){
                delete minilineItemObj.image_data;
            }
            var productsArr = [minilineItemObj];
            $.ajax({
                type: 'GET',
                url: util.ajaxUrl(Urls.minicartRemoveItem),
                data: { pliUUID: pliUUID }
            }).done(function(response) {
                minicart.showAjax();
                if(isBonusLineItem){
                    console.log("DataLayer Eliminar")
                } else {
                    window.dataLayer.push({
                        'event': 'removeFromMiniCart',
                        'ecommerce': {
                            'remove': {
                                'products': productsArr
                            }
                        }
                    });
                }
                
                util.cleanProductListBySKU(minilineItemObj?.id);

                //refresh page if on the main basket
                if ($('.js-cart-container').length) {
                    if (location.search != "") {
                        location = location.origin + location.pathname;
                    } else {
                        location.reload();
                    }
                }
                $('html').removeClass('disabled-scroll');
                $('.loader-button').remove();
                $('span.add-to-cart__label').removeClass('hide');
            });

            return false;
		});
        anchorElement();
        //anchorElementPdp();
        pushEventCartJourney();
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     * @param {Boolean} Avoid loading bonus dialog when an item is removed from minicart.
     */
    show: function(html, loadBonusDlg, listItem) {
        if ($(html).filter('.js-add-to-cart-errors').length > 0) {
            var $errorMessage = $('.message-add-cart');
            $errorMessage.html(html).addClass('show-message');
            if ($errorMessage.parents('.wishlist-wrapper').length > 0) {
                var messageOffset = $errorMessage.offset().top;
                var messageHeight = $errorMessage.height();
                var windowHeight = $(window).height();
                var offset = messageOffset - ((windowHeight / 2) - (messageHeight / 2));
                $('html, body').animate({
                    scrollTop: offset
                }, 1000);
            }
        } else {
            $('.message-add-cart').html('').removeClass('show-message');
            this.$el.html(html);
            this.init();

            //show the card if its not empty
            if ($(html).find('.mini-cart-empty').length < 1) {
                this.slide();
            } else {
                $('html').removeClass('disabled-scroll');
                $('.headerSlidingDropdown').css('opacity', '1');
            }

            if (loadBonusDlg !== false) {
                setTimeout(function() {
                    bonusProductsView.loadBonusOption(listItem)
                }, 10);
            }
        }
        
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     * @param {Boolean} Avoid loading bonus dialog when an item is removed from minicart.
     */
    showAjax: function(isAddToCart, listItem) {
        if (window.matchMedia('(min-width: 719px)').matches && window.location.pathname !== Urls.cartShow) {
            var url = util.ajaxUrl(Urls.minicartShow);
            var $this = $(this);
            if (window.currentCartAjaxRequest) {
                return;
            }
            window.currentCartAjaxRequest = $.ajax({
                url: url,
                success: function(response) {
                    if ($('.box-mini-carro').hasClass('opened') === true) {
                        if(response.indexOf('box-totales-controles') !== -1) {
                            response = response.replace('box-totales-controles', 'box-totales-controles opened');
                        }
                    }

                    $('#box-minicart').empty().html(response);

                    if(response.indexOf('content-mini-carro') === -1) {
                        $('.box-mini-carro').removeClass('opened');
                        $('.overlay-mini-carro').removeClass('opened');
                        $('.close-mini-carro').removeClass('opened');
                        $('.box-totales-controles').removeClass('opened');
                    }

                    if ($('#box-minicart').find('.content-mini-carro').length > 0) {
                        let accionLabel = isAddToCart ? 'automática' : 'Manual';
                        const existsPushEventInteractive = util.checkIfExistsPushEventInteractive('Header','Click', 'MiniCart');
                        if (!existsPushEventInteractive) {
                            window.dataLayer.push({
                                'event-interactive-category': 'Header',
                                'event-interactive-action': 'Click',
                                'event-interactive-tag': 'MiniCart',
                                'еvent': 'event-interactive'
                            }, {
                                'event-interactive-category': 'mini cart',
                                'event-interactive-accion': accionLabel,
                                'event-interactive-tag': pageInformation.returnPageName(),
                                'event': 'event-interactive'
                            });
                        }
                        
                        if (window.matchMedia('(min-width: 719px)').matches) {
                            minicart.slide();
                            minicart.init(false);
                            $('.js-close-mini-carro').unbind('click');
                            $('.js-close-mini-carro').on('click', function() {
                                minicart.close();
                                $('html').removeClass('disabled-scroll');
                                $('span.add-to-cart__label').removeClass('hide');
                                removeDisableButtonClass();
                                $('.headerSlidingDropdown').css('opacity', '1');
                            });
                            $('.overlay-mini-carro').unbind('click');
                            $('.overlay-mini-carro').on('click', function() {
                                minicart.close();
                                $('html').removeClass('disabled-scroll');
                                $('span.add-to-cart__label').removeClass('hide');
                                removeDisableButtonClass();
                                $('.headerSlidingDropdown').css('opacity', '1');
                            });
                        }
                    }
                    minicart.updateCartBadge();
                },
                complete: function() {
                    window.currentCartAjaxRequest = null;
                }
            });
            
        }
        //mobile
        else {
            if (isAddToCart) {
                setTimeout(function() { bonusProductsView.loadBonusOption(listItem) }, 10);
                minicart.updateCartBadge();
            } else {
                if (parseInt(tls.getCookie("paris_minicart_count")) > 0) {
                    window.location.href = Urls.cartShow;
                }
            }
        }
    },
    showMobileNotification: function(listItem) {
        var productContent = $('#mini-add-card').parent().html();
        var hasListItem = listItem !== undefined && listItem.length > 0;
        var productImage = hasListItem ? listItem.find('.main-image img') : $('.main-image img');
        var productName = hasListItem ? listItem.find('.js-product-name') : $('.js-product-name');
        var quantityElement = hasListItem ? listItem.find('#Quantity:visible') : $('#Quantity:visible');
        productContent = productContent
            .replace('%IMAGE%', productImage.attr('src'))
            .replace('%PRODUCTNAME%', productName.text())
            .replace('%QUANTITY%', quantityElement.val());

        var $productAddedDialog = $('#product-added-dialog');
        dialog.open({
            target: $productAddedDialog,
            html: productContent,
            options: {
                width: 400,
                modal: true,
                draggable: false,
                dialogClass: 'product-added',
                open: function() {
                    $(document.body).css('overflow', 'hidden');
                    $(this).parent().find('.ui-dialog-titlebar-close').text('')
                    $('.pt_product-details').css('overflow', 'hidden');
                    //$('.headerSlidingDropdown').css('opacity', '0.3');
                    // prevent scrolling fix for iOS product-added
                    $('.ui-widget-overlay')
                        .add($(this).closest('.ui-dialog'))
                        .on('touchmove', function(e) {
                            return false;
                        });
                    var thisDialog = $(this);
                    setTimeout(function() {
                        thisDialog.dialog('close');
                    }, Number(SitePreferences.PRODUCT_ADDED_MOBILE_OVERLAY_TIMEOUT));
                },
                close: function() {

                    $(document.body).css('overflow', 'auto');
                    $('.pt_product-details').css('overflow', 'auto');
                    $('.headerSlidingDropdown').css('opacity', '1');
                    $('.js-remove-product-line-item-bonus').each((i, el) => {
                        $(el).off('click');
                    });
                }
            }
        });
    },

    loadErrorMessageDialog: function(html) {
        dialog.open({
            html: html,
            options: {
                width: 400
            },
            callback: function() {}
        });
    },

    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function() {
        timer.start(50, function() {
            if ($('.box-mini-carro').hasClass('opened') == false) {
                $('.box-mini-carro').addClass('opened');
                $('.overlay-mini-carro').addClass('opened');
                $('.close-mini-carro').addClass('opened');
                $('.box-totales-controles').addClass('opened');
            }
        });
        $('html').addClass('disabled-scroll');
        $('.headerSlidingDropdown').css('opacity', '1');
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function(delay) {
        $('.box-mini-carro').toggleClass('opened');
        $('.overlay-mini-carro').toggleClass('opened');
        $('.close-mini-carro').toggleClass('opened');
        $('.box-totales-controles').toggleClass('opened');
        $('.loader-button').remove();
    },
    /**
     * @function
     * @description Update the minicart when update , remove line items
     *
     */
    update: function() {
        var $miniCart = $('#mini-cart');
        ajax.load({
            url: Urls.minicartShow,
            target: $miniCart,
            callback: function(data, textStatus) {
                //update something if needed
                minicart.init(true);
            }
        });
    },
    updateCartBadge: function() {
        if (parseInt(tls.getCookie("paris_minicart_count")) > 0) {
            $('.minicart-quantity').removeClass('hidden');
            $('.minicart-quantity').empty().html(tls.getCookie("paris_minicart_count"));
        } else {
            $('.minicart-quantity').addClass('hidden');
        }
    },
};


function incrementQtyPDP(_this) {
    var fieldName = $(_this).data('field');
    var parent = $(_this).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
    var select = $('#js-input-group-pdp');
    var allValues = select.find('input[id="allValues"]').val().split(',') || 0;    
    var  maxQtyToAdd  = parseInt(allValues[0], 10) || 0;
    var  availableCount  = parseInt(allValues[1], 10) || 0;

    if (!isNaN(currentVal) && currentVal >= 1) {
        if (currentVal  <  maxQtyToAdd) {
            if ( currentVal < availableCount) {
                $('#btn-plusPDP').prop('disabled',  false);
                $('#add-to-cart-quantity').val(currentVal + 1);
                parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
            } else {
                $('#btn-plusPDP').prop('disabled',  true);
                return;
            }
        } else {
            $('#btn-plusPDP').prop('disabled', true);
        }

    } else {
        parent.find('input[name=' + fieldName + ']').val(1);
    }
}

function decrementQtyPDP(_this) {
    var fieldName = $(_this).data('field');
    var parent = $(_this).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

    if (!isNaN(currentVal) && currentVal > 1) {
        $('#btn-plusPDP').prop('disabled',  false);
        $('#add-to-cart-quantity').val(currentVal - 1);
        parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
    } else {
        parent.find('input[name=' + fieldName + ']').val(1);
    }
}

function modifyMiniCartQtyHeaderBy(qty) {
    var $qtyElement = $('#mini-cart .header-mini-carro h3 span');
    var currentQty = +$qtyElement.text().match(/\d+/)[0];
    $qtyElement.text($qtyElement.text().replace(/\d+/, currentQty + qty));
}

function manageResponseQtyMC(response) {
    if($(response).filter('.content-mini-carro').length === 1 && $(response).filter('.box-totales-controles').length === 1) {
        // const cartContent = productsArr.map(elm => {return `${elm.id}_${elm.quantity}`}).join('/')
        // window.dataLayer.push({
        //     'еvent': 'cartJourney',
        //     'cartContent': cartContent
        // });
        var parser = new DOMParser();
        response = response.replace('box-totales-controles', 'box-totales-controles opened');
        response = response.replace('close-mini-carro', 'close-mini-carro opened');
        var contentMC = parser.parseFromString(response, "text/html").querySelector(".content-mini-carro");
        var boxTotales = parser.parseFromString(response, "text/html").querySelector(".box-totales-controles");
        var curr_y = 0;
        if($('.content-mini-carro').length > 1){
            for(var i=0;i<$('.content-mini-carro').length;i++){
                if($('.content-mini-carro')[i].scrollTop > curr_y){
                    curr_y = $('.content-mini-carro')[i].scrollTop;
                }
            }
        }else{
            curr_y = $('.content-mini-carro').scrollTop();
        }
        $('.content-mini-carro').empty().html(contentMC.children);
        $('.box-totales-controles').empty().html(boxTotales.children);
        if ($('#box-minicart').find('.content-mini-carro').length > 0) {
            window.dataLayer.push({
                'event-interactive-category': 'Header',
                'event-interactive-action': 'Click',
                'event-interactive-tag': 'MiniCart',
                'еvent': 'event-interactive'
            }, {
                'event-interactive-category': 'mini cart',
                'event-interactive-accion': 'Manual',
                'event-interactive-tag': pageInformation.returnPageName(),
                'event': 'event-interactive'
            });

            if (window.matchMedia('(min-width: 719px)').matches) {
                minicart.slide();
                minicart.init(false);
                $('.js-close-mini-carro').unbind('click');
                $('.js-close-mini-carro').on('click', function() {
                    minicart.close();
                    $('html').removeClass('disabled-scroll');
                    $('.headerSlidingDropdown').css('opacity', '1');
                });
                $('.overlay-mini-carro').unbind('click');
                $('.overlay-mini-carro').on('click', function() {
                    minicart.close();
                    $('html').removeClass('disabled-scroll');
                    $('.headerSlidingDropdown').css('opacity', '1');
                });
            }
        }       
        $('.content-mini-carro').scrollTop(curr_y);
        minicart.updateCartBadge();
    }
}

function pushEventCartJourney(){
    var cartJouneyValue = tls.getCookie("paris_minicart_journey");
    if(cartJouneyValue.length === 0 ){
        return;
    }
    window.dataLayer.push({
        'event': 'cartJourney',
        'cartContent': cartJouneyValue
    });
}

module.exports = minicart;
