'use strict';

const pageInformation = require('./components/pageInformation');

// Hamburger Menu event
const eventInteractiveMenu = (category, action, tag) => {
	var eventInfo = {
		'event-interactive-category': category,
		'event-interactive-accion': action,
		'event-interactive-tag': tag,
		'event-interactive-value': '',
		'event': 'event-interactive'
	}
	return eventInfo;
};
// Hamburger Menu event

exports.eventInteractiveMenu = eventInteractiveMenu;
