'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phoneByCountry: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
    phone: /^[\d]{8}$/,
    numbers: /^[\d]*$/,
    postal: {
        cl: /^[0-9]{7}$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    rut: /^(([0-9]{1,2}\.?[0-9]{3}\.?[0-9]{3}))\-?([0-9Kk])$/,
	dni:  /^[^\s]{2,15}$/,
	dniPeru: /^[\d]{8}$/,
    firstname: /^[\w\d\s]{0,15}$/,
    letters: /[a-zA-Z]/,
	name: /^[a-zA-Z\s'’`ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç-]{0,15}$/,
	lettersspanish: /[a-zA-ZÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç]/,
    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@.()$!%*\-?&#^_+\.]{8,15}$/,
	loginpassword: /^.{8,15}$/,
	factura: /^[\w\d\s'’`´ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç\.-]{3,35}$/,
	facturaperu: /^[\w\d\s'’`´ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç\.\-$!%*?&]{3,35}$/,
    digit: /^(\s*|\d+)$/,
    cardNumber: /[0-9\s]{15,19}$/,
	giftcardcode: /^[\d]{16}$/,
    giftcardpassword: /^[\d]{4}$/,
    catcerradacard: /^[\d]{16}$/,
	streetnamecontent: /^[\w\d\s'’`´ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç.-]{3,50}$/,
	streetnumber: /^[a-zA-Z\d-]{1,5}$/,
    cvn: /^(\s*|\d{3,4})$/,
    apartmentcontent: /^[a-zA-Z\d\sÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç]{0,5}$/,
    apartmentcontentPeru: /^[a-zA-Z\d\sÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç]{0,80}$/,
    addressid: /^[a-zA-Z\d\sÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç-]{2,20}$/
};
var rutNoPermitidos = ['987654325','123456785','131313136'];
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            if (element && element.id == 'searchTextField') {
                return;
            }
            $(element).siblings('.server-error').remove();
            $(element).siblings('.error').remove();
            $(element).removeClass('field-error');
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
	if(Resources.SITE_ID == "Paris"){
		if (typeof value == "undefined" || null == value) {
			return false;
		}
		
		var trimValue = $.trim(value);
		
		var form = $(el).parents('form');

		if (form.length != 0) {
			var phoneCodeElement = $(form).find('.js-phonecode');
			//validate phone value depends on phone code selection : 8 or 10 max length
			if (!empty(phoneCodeElement) && phoneCodeElement.length != 0) {
				var phoneCodeValue = $(phoneCodeElement).val();
				var trimPhoneCodeValue = $.trim(phoneCodeValue);
				if (!regex.digit.test(trimValue)) {
					return false;
				}
				
				if ((trimPhoneCodeValue.length == 3 && trimValue.length == 10) || (trimPhoneCodeValue.length == 4 && trimValue.length == 8)) {
					return true;
				}
				
				return false;
			}
		}
		
		return regex.phone.test(trimValue);
	}
	else if(Resources.SITE_ID == "ParisPe"){
		return validatePhonePeru(value, el);
	}
};

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhonePeru = function (value, el) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	
	var trimValue = $.trim(value);
	
	var form = $(el).parents('form');

	if (form.length != 0) {
		var phoneCodeElement = $(form).find('.js-phonecode');
		//validate phone value depends on phone code selection : 8 or 10 max length
		if (phoneCodeElement.length != 0) {
			var phoneCodeValue = $(phoneCodeElement).val();
			var trimPhoneCodeValue = $.trim(phoneCodeValue);
			if (!regex.digit.test(trimValue)) {
				return false;
			}
			
			if (trimPhoneCodeValue.length == 3 && (trimValue.length == 8 || trimValue.length == 9)) {
				return true;
			}
			
			return false;
		}
	}
	
	return regex.phone.test(trimValue);
};

var validateNumbers = function (value) {
    return regex.numbers.test(value);
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};
/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates that a Rut value is valid
 * @param {String} value The populated rut input field which will be validated
 */
var validateRut = function (value, currentElement) {
	if (typeof value == "undefined" || null == value) {
		$(document).trigger('rut-validation-error', [currentElement]);
		return false;
	}
	var trimedRut = $.trim(value);

	var isValid = regex.rut.test(trimedRut);


    if (!isValid) {
		$(document).trigger('rut-validation-error', [currentElement]);
		return false;
	}
	
	trimedRut = trimedRut.replace(new RegExp('[.]', 'g'), '');
	trimedRut = trimedRut.replace('-', '');

    if (trimedRut.length < 8) {
		$(document).trigger('rut-validation-error', [currentElement]);
		return false;
	}
	
	if (trimedRut.length == 8) {
		trimedRut = "0" + trimedRut;
	}

    var sum = Number(trimedRut[0])*3 + Number(trimedRut[1])*2 + Number(trimedRut[2])*7 + Number(trimedRut[3])*6 + Number(trimedRut[4])*5 + Number(trimedRut[5])*4 + Number(trimedRut[6])*3 + Number(trimedRut[7])*2;
	var digestSymbol = null;


	if (trimedRut[8].toUpperCase() == 'K') {
		digestSymbol = trimedRut[8].toUpperCase();
	} else {
		digestSymbol = Number(trimedRut[8]);
	}

    if(trimedRut[8].toUpperCase() != 'K') {
    	for (var i = 0; i < rutNoPermitidos.length; i++) {
    		var valor = rutNoPermitidos[i];
            if (valor == trimedRut){
                $(document).trigger('rut-validation-error', [currentElement]);
                return false;
            }
        }
        var cant=0;
        for (var i =0 ; i < trimedRut.length; i++) {
            var valor1 = trimedRut.charAt(i);
            var valor2 = trimedRut.charAt(i + 1);
            if (valor1  == valor2){
                cant = cant + 1;
            }
        }
        if (cant == 8 || cant == 9 ) {
            $(document).trigger('rut-validation-error', [currentElement]);
            return false;
        }
    }

	var digestResult = sum % 11;
	digestResult = 11 - digestResult;
	
	var result = false;
	
	if (digestResult == 11 && digestSymbol == 0) {
		result = true;
	} else if (digestResult == 10 && digestSymbol == 'K') {
		result = true;
	} else if (digestResult == digestSymbol) {
		result = true;
	}
	
	if (result) {
		$(document).trigger('rut-validation-success', [currentElement]);
	} else {
		$(document).trigger('rut-validation-error', [currentElement]);
	}
	
	return result;
};

var validateRuc = function (value){
	var ruc = $.trim(value);
	ruc = ruc.replace(/[-.,[\]()\s]+/g,"");
	if ((ruc = Number(ruc)) && ruc % 1 === 0 && valruc(ruc)) {
			return true;
	}
	else return false;
}

function valruc(valor){
	valor = $.trim(valor.toString())
	if ( valor.length == 8 ){
		var suma = 0
		for (var i=0; i<valor.length-1;i++){
			var digito = valor.charAt(i) - '0';
			if ( i==0 ) suma += (digito*2)
			else suma += (digito*(valor.length-i))
		}
		var resto = suma % 11;
		if ( resto == 1) resto = 11;
		if ( resto + ( valor.charAt( valor.length-1 ) - '0' ) == 11 ){
			return true
		}
	} else if ( valor.length == 11 ){
		var suma = 0
		var x = 6
		for (var i=0; i<valor.length-1;i++){
			if ( i == 4 ) x = 8
			var digito = valor.charAt(i) - '0';
			x--
			if ( i==0 ) suma += (digito*x)
			else suma += (digito*x)
		}
		var resto = suma % 11;
		resto = 11 - resto
	
		if ( resto >= 10) resto = resto - 10;
		if ( resto == valor.charAt( valor.length-1 ) - '0' ){
			return true
		}      
	}
	return false;
}

/**
 * @function
 * @description Validates that a passport value is valid
 * @param {String} value The populated passport input field which will be validated
 */
var validatePassport = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.dni.test($.trim(value));
};

/**
 * @function
 * @description Validates that a peruvian dni value is valid
 * @param {String} value The populated dni input field which will be validated
 */
var validateDniPeru = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.dniPeru.test($.trim(value));
};

/**
 * @function
 * @description Validates that a Profile name value is valid length
 * @param {String} value The populated Profile name input field which will be validated
 */
var validateProfileNameLength = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	
	var trimValue = $.trim(value);

	return (trimValue.length < 2 || trimValue.length > 15) ? false : true;
}

/**
 * @function
 * @description Validates that a Profile name value is valid
 * @param {String} value The populated Profile name input field which will be validated
 */
var validateProfileNameContent = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	
	return regex.name.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Email value is valid
 * @param {String} value The populated Email input field which will be validated
 */
var validateEmailContent = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.email.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Email value is valid length
 * @param {String} value The populated Email input field which will be validated
 */
var validateEmailLength = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	
	var trimValue = $.trim(value);

	return (trimValue.length < 10 || trimValue.length > 50) ? false : true;
}

/**
 * @function
 * @description Validates that a Password value is valid
 * @param {String} value The populated Password input field which will be validated
 */
var validatePassword = function (value, element) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	if ($(element).data('skip-pwd-validation')) {
		return true;
	}
	
	return regex.password.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Password value is valid
 * @param {String} value The populated Password input field which will be validated
 */
var validateLoginPassword = function (value, element) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	if ($(element).data('skip-pwd-validation')) {
		return true;
	}
	
	return regex.loginpassword.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Confirm Password value matches password field
 * @param {String} value The populated ConfirmPassword input field which will be validated
 */
var validateConfirmPassword = function (value) {
    if (typeof value == "undefined" || null == value) {
        return false;
    }

    var result = false;
    
    if (typeof this.currentForm != 'undefined') {
    	var currentForm = $(this.currentForm);
    	
    	var passwordField = currentForm.find('.js-password-field');
    	
    	if (passwordField.length == 1) {
    		var passwordValue = passwordField.val();
    		
    		result = (value === passwordValue) ? true : false;
    	}
    }
    
    return result;
}

/**
 * @function
 * @description Validates that a Factura Field value is valid
 * @param {String} value The populated Factura Field input field which will be validated
 */
var validateFacturaField = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.factura.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Factura Peru Field value is valid
 * @param {String} value The populated Factura Field input field which will be validated
 */
var validateFacturaPeruField = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.facturaperu.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Factura Number Field value is valid
 * @param {String} value The populated Factura Number Field input field which will be validated
 */
var validateFacturaNumberField = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.digit.test($.trim(value));
}

/**
 * @function
 * @description Validates that a email value is equal to confirm email value
 * @param {String} value The populated confirm email input field which will be validated
 */
var validateEmailConfirm = function (value) {
	if (typeof value == "undefined" || null == value) {
        return false;
    }

    var result = false;
    
    if (typeof this.currentForm != 'undefined') {
    	var currentForm = $(this.currentForm);
    	
    	var emailField = currentForm.find('.js-email-field');
    	
    	if (emailField.length == 1) {
    		var emailValue = emailField.val();
    		
    		result = (value === emailValue) ? true : false;
    	}
    }
    
    return result;
}

var validateSpecialProgramCodeField = function (value, element) {
	var minlength = $(element).data('validation-length').replace('min', '');
	var pattern = '\\d{' + minlength + ',}';
	var regex = new RegExp(pattern, 'g');
	return regex.test(value);
}

/**
 * @function
 * @description Validates that a field value is valid
 * @param {String} value The populated field input field which will be validated
 */
var validateApartment = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.apartmentcontent.test($.trim(value));
}

/**
 * @function
 * @description Validates that a field value is valid
 * @param {String} value The populated field input field which will be validated
 */
var validateApartmentPeru = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.apartmentcontentPeru.test($.trim(value));
}

/**
 * @function
 * @description Validates that a Street name Field value is valid
 * @param {String} value The populated Street name Field input field which will be validated
 */
var validateStreetNameField = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	
	var trimValue = $.trim(value);
	
	return regex.streetnamecontent.test(trimValue);
}

var validateCardNumberField = function(value) {
    if (typeof value === 'undefined' || null === value) {
        return false;
    }
    
    return regex.cardNumber.test(value);
}

/**
 * @function
 * @description Validates that a GiftCard Code Field value is valid
 * @param {String} value The populated GiftCard Code Field input field which will be validated
 */
var validateGiftCardCode = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.giftcardcode.test($.trim(value));
}

/**
 * @description Validates that a card number value is valid
 * @param {String} value The populated card number input field which will be validated
 */
var validateCardNumberContent = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.cardnumber.test($.trim(value));
};

/**
 * @description Validates addressID field
 * @param {String} the address name input
 */
var validateAddressId = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	return regex.addressid.test($.trim(value));
};

/**
 * @function
 * @description Validates that a card number value is valid length
 * @param {String} value The populated card number input field which will be validated
 */
var validateCardNumberLength = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	
	var trimValue = $.trim(value);

	return (trimValue.length < 13 || trimValue.length > 19) ? false : true;
}

/**
 * @function
 * @description Validates that a GiftCard Password Field value is valid
 * @param {String} value The populated GiftCard Password Field input field which will be validated
 */
var validateGiftCardPassword = function (value) {
    if (typeof value == "undefined" || null == value) {
        return false;
    }
    
    return regex.giftcardpassword.test($.trim(value));
}

var validateCatCerradaCard = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}
	var trimedNumber = $.trim(value);
	trimedNumber = trimedNumber.replace(/-/g, '');
	
	// Checking the range 
	var pattern = RegExp (SitePreferences.CAT_CERRADA_BIN_RANGE);
    if ( !pattern.test(trimedNumber) ) {
    	return false;
    }
	
	return regex.catcerradacard.test(trimedNumber);
}	
/**
 * @description Validates that a card number value is valid
 * @param {String} value The populated card number input field which will be validated
 */
var validateCvnContent = function (value) {
    if (typeof value == "undefined" || null == value) {
        return false;
    }

    return regex.cvn.test($.trim(value));
}

/**
 * @function
 * @description Validates that the expiration date provided is correct and not in the past.
 */
function validateCardExpirationDate() {
    var creditCard = {};
    var expirationMonth;
    var expirationYear;
    var isCreditCardPaymentMethodSelected = $('#is-CREDIT_CARD').is(':checked');
    var isCencosudCardPaymentMethodSelected = $('#is-CENCOSUD_CARD').is(':checked');
    var isCencosudCardTCompPaymentMethodSelected = $('#is-CENCOSUD_CARD_TCOMP').is(':checked');
    var isCreditCardTCompPaymentMethodSelected = $('#is-CREDIT_CARD_TCOMP').is(':checked');
    var isCencosudCardXCashPaymentMethodSelected = $('#is-CENCOSUD_CARD_XCASH').is(':checked');
	
	var isPaymentWithoutExpirationDate = $('#is-XCash').is(':checked') ||
										 $('#is-WEBPAY').is(':checked') ||
										 $('#is-ALIGNET_CREDIT_DEBIT').is(':checked') ||
										 $('#is-ALIGNET_CENCOSUD_CARD').is(':checked') ||
										 $('#is-MERCADOPAGO').is(':checked') ||
										 $('#is-MERCADOPAGO_CENCOSUD').is(':checked');
	
    if (!isPaymentWithoutExpirationDate) {
	    if (isCreditCardPaymentMethodSelected) {
	        creditCard = $('[data-method="CREDIT_CARD"]');
	        expirationMonth = $('select[name*="creditCard_expiration_month"]').val();
	        expirationYear = $('select[name*="creditCard_expiration_year"]').val();
	    } else if (isCencosudCardPaymentMethodSelected) {
	        creditCard = $('[data-method="CENCOSUD_CARD"]');
	        expirationMonth = $('select[name*="cencosudCard_expiration_month"]').val();
	        expirationYear = $('select[name*="cencosudCard_expiration_year"]').val();
	    }
	    else if (isCreditCardTCompPaymentMethodSelected) {
	        creditCard = $('[data-method="CREDIT_CARD_TCOMP"]');
	        expirationMonth = $('select[name*="creditCardTComp_expiration_month"]').val();
	        expirationYear = $('select[name*="creditCardTComp_expiration_year"]').val();
	    } else if (isCencosudCardTCompPaymentMethodSelected) {
	        creditCard = $('[data-method="CENCOSUD_CARD_TCOMP"]');
	        expirationMonth = $('select[name*="cencosudCardTComp_expiration_month"]').val();
	        expirationYear = $('select[name*="cencosudCardTComp_expiration_year"]').val();
	    } else if (isCencosudCardXCashPaymentMethodSelected) {
	        creditCard = $('[data-method="CENCOSUD_CARD_XCASH"]');
	        expirationMonth = $('select[name*="cencosudCardXCash_expiration_month"]').val();
	        expirationYear = $('select[name*="cencosudCardXCash_expiration_year"]').val();
	    }
	    
	    var currentDate = new Date();
	    var expirationDate = new Date();
	    expirationDate.setMonth(expirationMonth-1);
	    expirationDate.setYear(expirationYear);
	    var expiryDateErrorElement = '<span class="error" id="expiryDateError">' + Resources.INVALID_EXPIRY_DATE + '</span>';
	    
	    if (currentDate.getFullYear() < expirationDate.getFullYear() 
			|| (currentDate.getFullYear() == expirationDate.getFullYear() && currentDate.getMonth() <= expirationDate.getMonth())) {
	        creditCard.find('select[name*="expiration_month"]').addClass('valid');
	        creditCard.find('select[name*="expiration_month"]').removeClass('error');
	        creditCard.find('select[name*="expiration_year"]').addClass('valid');
	        creditCard.find('select[name*="expiration_year"]').removeClass('error');
	        $('#expiryDateError').remove();
	    } else {
	        creditCard.find('select[name*="expiration_month"]').removeClass('valid');
	        creditCard.find('select[name*="expiration_month"]').addClass('error');
	        if (creditCard.find('select[name*="expiration_month"]').parent().find('span.error').length === 0) {
	            creditCard.find('select[name*="expiration_month"]').parent().append(expiryDateErrorElement);
	        }
	        creditCard.find('select[name*="expiration_year"]').removeClass('valid');
	        creditCard.find('select[name*="expiration_year"]').addClass('error');
	    }
    }
}

/**
 * @function
 * @description Validates that a Street number Field value is valid
 * @param {String} value The populated Street number Field input field which will be validated
 */
var validateStreetNumberField = function (value) {
	if (typeof value == "undefined" || null == value) {
		return false;
	}

	var trimValue = $.trim(value);
	
	var isValid = regex.streetnumber.test(trimValue);
	
	if (!isValid) {
		return isValid;
	}
	
	return (trimValue.length < 1 || trimValue.length > 6) ? false : true;
}

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE_MOBILE);
$.validator.addMethod('phonelong', validatePhone, Resources.INVALID_PHONE_LONG);
$.validator.addMethod('numberscontent', validateNumbers, Resources.VALIDATE_DIGITS);

$.validator.addMethod('firstnamecontent', validateProfileNameContent, Resources.INVALID_FIRSTNAME);
$.validator.addMethod('firstnamelength', validateProfileNameLength, Resources.INVALID_FIRSTNAME_LENGTH);
$.validator.addMethod('lastnamecontent', validateProfileNameContent, Resources.INVALID_LASTNAME);
$.validator.addMethod('lastnamelength', validateProfileNameLength, Resources.INVALID_LASTNAME_LENGTH);
$.validator.addMethod('confirm-email', validateEmailConfirm, Resources.INVALID_EMAILCONFIRM);
$.validator.addMethod('email', validateEmailContent, Resources.INVALID_EMAIL);
$.validator.addMethod('emaillength', validateEmailLength, Resources.INVALID_EMAIL);
$.validator.addMethod('rut', validateRut, Resources.INVALID_RUT);
$.validator.addMethod('ruc', validateRuc, Resources.INVALID_RUC);
$.validator.addMethod('passport', validatePassport, Resources.INVALID_PASSPORT);
$.validator.addMethod('dniperu', validateDniPeru, Resources.INVALID_PASSPORT);
$.validator.addMethod('password-field', validatePassword, Resources.INVALID_PASSWORD);
$.validator.addMethod('login-password-field', validateLoginPassword, Resources.INVALID_LOGIN_PASSWORD);
$.validator.addMethod('confirm-password', validateConfirmPassword, Resources.INVALID_PASSWORD_NOMATCH);
$.validator.addMethod('factura', validateFacturaField, Resources.INVALID_FACTURA_FIELD);
$.validator.addMethod('facturaperu', validateFacturaPeruField, Resources.INVALID_FACTURA_FIELD);
$.validator.addMethod('digit', validateFacturaNumberField, Resources.VALIDATE_NUMBER);
$.validator.addMethod('streetnamecontent', validateStreetNameField, Resources.ADDRESS_STREET_NAME_LENGTH);
$.validator.addMethod('apartmentcontent', validateApartment, Resources.ADDRESS_STREET_NAME_INVALID);
$.validator.addMethod('apartmentcontentPeru', validateApartmentPeru, Resources.ADDRESS_STREET_NAME_INVALID);
$.validator.addMethod('specialprogramcodefield', validateSpecialProgramCodeField, Resources.INVALID_SPECIAL_PROGRAM_CODE);
$.validator.addMethod('giftcard-code', validateGiftCardCode, Resources.INVALID_GIFTCARD_CODE);
$.validator.addMethod('giftcard-password', validateGiftCardPassword, Resources.INVALID_GIFTCARD_PASSWORD);
$.validator.addMethod('streetnumber', validateStreetNumberField, Resources.INVALID_STREET_NUMBER);
$.validator.addMethod('cat-cerrada-number', validateCatCerradaCard, Resources.INVALID_CATCERRADA_CARD);
$.validator.addMethod('cardnumbercontent', validateCardNumberContent, Resources.VALIDATE_CREDITCARD);
$.validator.addMethod('cardnumberlength', validateCardNumberLength, Resources.VALIDATE_CREDITCARD);
$.validator.addMethod('cvncontent', validateCvnContent, Resources.INVALID_CVN_REQUIRED);
$.validator.addMethod('addressid', validateAddressId, Resources.INVALID_ADDRESS_NAME);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

$.validator.addMethod('card-number', validateCardNumberField, Resources.VALIDATE_CREDITCARD);

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    },
    validateCardExpirationDate: validateCardExpirationDate
};

$.validator.messages.required = function ($1, ele) {
	var requiredText = $(ele).parents('.form-row').data('required-text');
	return requiredText || Resources.VALIDATE_REQUIRED;
}

module.exports = validator;
