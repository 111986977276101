
var autocomplete;

var autocompleteListener;
var newRegionMappingUtil = require('../newRegionMappingUtil');
//google address API place.address_components types
var componentForm = {
        street_number: 'short_name',  // street number
        route: 'long_name',   // street name
        postal_town: 'long_name',   // city 
        locality: 'long_name',   // city
        administrative_area_level_1: 'short_name',  // state
        administrative_area_level_2: 'short_name',  // county
        administrative_area_level_3: 'long_name',  // comuna reserve field
        country: 'short_name',  // country
        postal_code: 'short_name'  // post code

}

var googlePlaces = {
        
        //$selector - google search field
        initAutocomplete: function($selector, options) {
            
            if (typeof(options) === 'undefined') {
                options = {};
            } 
            
            if (typeof(options.types) === 'undefined') {
                options.types = ['geocode'];
            }
            
            if (typeof(window.google) !== 'undefined') {
				autocomplete = new google.maps.places.Autocomplete($selector, options);
				var countryScope = Resources.SITE_ID === "Paris" ? "cl" : (Resources.SITE_ID === "ParisPe" ? "pe" : 'cl');
                autocomplete.setComponentRestrictions(
						{'country': [countryScope]});
				autocomplete.setFields(['address_components', 'geometry', 'plus_code']);
			} 
        },
        
        init: function($selector) {
            
            if (typeof(window.google) !== 'undefined') {
                if (typeof($selector) === 'undefined') {
                    $selector = $('#searchTextField');
				}
                
                if ($selector.length > 0) {
                    //$selector.on('focus', this.geolocate);
                   
                    var _self = this;
                    
                    if (typeof(autocomplete) !== 'undefined') {
                        google.maps.event.removeListener(autocompleteListener);
                        google.maps.event.clearInstanceListeners(autocomplete);
                    }
                    $selector.each(function() {
						if($selector.val() == ""){
							_self.initAutocomplete(this);
                            autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', _self._autocompleteAddress);
						}
						else{
							$selector.on('focus',function(){
								$selector.val('');
								_self.initAutocomplete(this);
                                autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', _self._autocompleteAddress);
                            });
						}
						
					});
                }
            }
            
        },
        
        _autocompleteAddress: function() {
            
            if (typeof(autocomplete) !== 'undefined') {
                
				// Get the place details from the autocomplete object.
                var place = autocomplete.getPlace();
                
                for (var component in componentForm) {
                    
                    var $formField = $('[data-autocomplete='+component+']');
                    
                    if ($formField.attr('type') !== 'hidden') {
                        $formField.val('');
                        $formField.disabled = false;
                    }
                    $('[data-autocomplete=latitude]').val('');
                    $('[data-autocomplete=longitude]').val(''); 
                }

                if (!place || !place.address_components){
                    $(document).trigger( "validate.google.addresses", [ "Custom", "Event" ] );
                    return;
                }
                // Get each component of the address from the place details
                // and fill the corresponding field on the form.
                
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0]
                    if (componentForm[addressType]) {
                        var value = place.address_components[i][componentForm[addressType]];
                        if (addressType === 'administrative_area_level_1') {
                        //fill Region select
                            var val = $('[data-autocomplete=administrative_area_level_1] option:contains('+value+')').val();
                            if (typeof val !== 'undefined') {
                                $('[data-autocomplete=administrative_area_level_1]').val(val);
                                $('[data-autocomplete=administrative_area_level_1]').trigger('change');
                            }
                        }
                    }
                }
                
                var isComunaPopulated = false;
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var value = place.address_components[i][componentForm[addressType]];
                        if (addressType === 'country') {
                            continue;
                        } else if ((addressType === 'locality' || addressType === 'administrative_area_level_3') && !isComunaPopulated) {
                        	//populate region according to new region mapping
                            var dataObject = newRegionMappingUtil.checkComunas(value.toUpperCase());
                            if (dataObject.isNewRegion) {
                                if ($('[data-autocomplete=administrative_area_level_1] option:contains('+dataObject.newRegion+')').length) {
                                	$('[data-autocomplete=administrative_area_level_1] option').each(function() {
                                		if ($(this).text() == dataObject.newRegion) {
                                			$(this).prop('selected' , true);
                                		}
                                	});
                                }
                            }
                            $('[data-autocomplete=administrative_area_level_1]').trigger('change');
                            $('[data-autocomplete=locality]').val(value.toUpperCase());
                            if ($('[data-autocomplete=locality]').val() && $('[data-autocomplete=locality]').val().length > 0) {
                                isComunaPopulated = true;
                            }
                        } else if (addressType === 'administrative_area_level_1') {
                               continue;
                        } else {
                            $('[data-autocomplete='+addressType+']').val(value);
                        }
                    }
                }
                
                //fill latitude and longitude
                if (place.geometry && place.geometry.location) {
                    var lat = place.geometry.location.lat;
                    var lng = place.geometry.location.lng;
                    $('[data-autocomplete=latitude]').val(lat);
                    $('[data-autocomplete=longitude]').val(lng); 
				}
				else{
					$('[data-autocomplete=latitude]').val('');
                    $('[data-autocomplete=longitude]').val(''); 
				}
                //fill plusCode
                if (place.hasOwnProperty('plus_code')) {
                    var plusCode ='';
                    plusCode = place.plus_code.global_code;
                    $('[data-autocomplete=plusCode]').val(plusCode); 
                } else {
                    $('[data-autocomplete=plusCode]').val(''); 
                }
                /* trigger event to validate google search fields
                   e.g Region , Comuna , Street name , Street Number , Latitude , Longitude
                */
                $(document).trigger( "validate.google.addresses", [ "Custom", "Event" ] );
                
            }
        },
        
        // Bias the autocomplete object to the user's geographical location,
        // as supplied by the browser's 'navigator.geolocation' object.
        geolocate: function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                    };
                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                    if (typeof(autocomplete) !== 'undefined') {
                        autocomplete.setBounds(circle.getBounds());
                    }
                    
                });
            }
		}
};

module.exports = googlePlaces;


