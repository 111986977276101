'use strict';

/**
 * @description Return page name according to existing elements
 * @returns {String} Page name
 */
exports.returnPageName = function () {
	var currentPage = 'unknown page';
	if ($('.pt_storefront').length > 0) {
		currentPage = 'home';
	} else if ($('.pt_product-search-result').length > 0) {
		currentPage = 'PLP';
	} else if ($('.js-ajax-category-landing ').length > 0) {
		currentPage = 'Top category';
	} else if ($('.pt_cart ').length > 0) {
		currentPage = 'cart';
	} else if ($('.pdp-main').length > 0 || $('.js-lifting-pdp').length > 0) {
		currentPage = 'PDP';
	} else if ($('.pt_error').length > 0) {
		currentPage = '404';
	} else if ($('.page_checkout').length > 0) {
		currentPage = 'checkout';
	}

	return currentPage;
}
