'use strict';

var util = require('./util');

var currentQuery = null,
    lastQuery = null,
    runningQuery = null,
    listTotal = -1,
    listCurrent = -1,
    delay = 30,
    $resultsContainer;
/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
    return true;
}

var searchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function (container, defaultValue) {
        var $searchContainer = $(container);
        var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        var $searchField = $searchForm.find('input[name="q"]');

        $searchContainer.addClass('initialized');

        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        // n click listener for appear einstein suggestions
        $searchField.click(function () {
            if(SitePreferences.ENABLE_SUGGESTION_EINSTEIN==true ) {
                setTimeout(this.suggestEinstein.bind(this), delay);
            }
        }.bind(this));

        // on focus listener (clear default value)
        $searchField.focus(function () {
            if (!$resultsContainer) {
                // create results container if needed
                $resultsContainer = $('<div/>').attr('id', 'result-search').attr('class', 'result-search').appendTo($searchContainer);
                if (window.matchMedia("(max-width: 970px)").matches) {
                    $resultsContainer.addClass('nav-search');
                }

                // show suggestion if second click or more
                if (!$resultsContainer.is(':visible')) {
                    $resultsContainer.fadeIn();
                } 
            } 
            if ($searchField.val() === defaultValue) {
                $searchField.val('');
            }
        });
        
        // hide suggestion if click outside suggestion div
        $('body').click((e) => {
            if ($resultsContainer) {
                let search = $(e.target).closest('#result-search');    
                if (!search.length) {
                    if ($resultsContainer.is(':visible')) {
                        $resultsContainer.hide();
                    }
                }        
            }
        });

        $(document).on('click', function (e) {
            if (!$resultsContainer || $resultsContainer.find(e.target).length != 0) {
                return;
            }
            setTimeout(this.clearResults, 200);
        }.bind(this));
        // on key up listener
        $searchField.keyup(function (e) {

            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }
            // check for an ENTER or ESC
            if (keyCode === 13 || keyCode === 27) {
                this.clearResults();
                return;
            }

            currentQuery = $searchField.val().trim();

            // no query currently running, init an update
            if (!runningQuery) {
                runningQuery = currentQuery;
                if(runningQuery.length<3 && SitePreferences.ENABLE_SUGGESTION_EINSTEIN==true ) {
                    setTimeout(this.suggestEinstein.bind(this), delay);
                }else{
                    setTimeout(this.suggest.bind(this), delay);
                }
            }
        }.bind(this));
    },
    /**
     * @function
     * @description trigger suggest action
     */
    suggest: function () {
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }
        var minLength = SitePreferences.SIMPLE_SEARCH_FIELD_MIN_LENGTH;
        // appear with minlenth
        if (runningQuery.length < minLength) {
            this.clearResults();
            runningQuery = null;
            return;
        }
        

        // build the request url
        var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', runningQuery);

        // execute server call
        $.get(reqUrl, function (data) {
            var suggestionHTML = data,
                ansLength = suggestionHTML.trim().length;

            // if there are results populate the results div
            if (ansLength === 0) {
                this.clearResults();
            } else {
                // update the results div
                $resultsContainer.html(suggestionHTML).fadeIn(200);
                var $results = $resultsContainer.find('.box-item-cart');
                if ($results.length > 0) {
                    var suggestionsBoxHeight = 0,
                    $searchSuggestions;

                    var maxProductsShown = (window.matchMedia("(max-width: 767px)").matches) ? 2 : 4;

                    $results.eq(maxProductsShown).find('img').one('load', function () {
                        $results.slice(0, maxProductsShown).each(function() {
                            suggestionsBoxHeight += $(this).outerHeight(true);
                        });

                        $searchSuggestions = $resultsContainer.find('.search-overflow');
                        var outerHeight = $searchSuggestions.outerHeight();

                        //show less than 2 products of height if we don't have enough space
                        if (outerHeight < suggestionsBoxHeight) {
                            suggestionsBoxHeight = outerHeight;
                        } 
                        //make up for fraction values
                        suggestionsBoxHeight -= 1;
                        $resultsContainer.find('.search-overflow').css('height', suggestionsBoxHeight);
                    });

                }
            }

            // record the query that has been executed
            lastQuery = runningQuery;
            // reset currently running query
            runningQuery = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (currentQuery !== lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
            this.hideLeftPanel();
        }.bind(this));
    },
    /**
     * @function
     * @description trigger suggest action
     */
    suggestEinstein: function () {
        let runningQuerylength=0;
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }
        var minLength = SitePreferences.SIMPLE_SEARCH_FIELD_MIN_LENGTH;
        //fix for appear einstein suggestion without search string
        if (runningQuery != undefined){
            runningQuerylength = runningQuery.length;
        }
         if (runningQuerylength >= minLength) {
            this.clearResults();
            runningQuery = null;
            return;
        }        

        // build the request url
        var reqUrl = util.appendParamToURL(Urls.searchsuggesteinstein, 'q', runningQuery);

        // execute server call
        $.get(reqUrl, function (data) {
            var suggestionHTML = data,
                ansLength = suggestionHTML.trim().length;

            // if there are results populate the results div
            if (ansLength === 0) {
                this.clearResults();
            } else {
                // update the results div
                $resultsContainer.html(suggestionHTML).fadeIn(200);
                var $results = $resultsContainer.find('.box-item-cart');
                if ($results.length > 0) {
                    var suggestionsBoxHeight = 0,
                    $searchSuggestions;

                    var maxProductsShown = (window.matchMedia("(max-width: 767px)").matches) ? 2 : 4;

                    $results.eq(maxProductsShown).find('img').one('load', function () {
                        $results.slice(0, maxProductsShown).each(function() {
                            suggestionsBoxHeight += $(this).outerHeight(true);
                        });

                        $searchSuggestions = $resultsContainer.find('.search-overflow');
                        var outerHeight = $searchSuggestions.outerHeight();

                        //show less than 2 products of height if we don't have enough space
                        if (outerHeight < suggestionsBoxHeight) {
                            suggestionsBoxHeight = outerHeight;
                        } 
                        //make up for fraction values
                        suggestionsBoxHeight -= 1;
                        $resultsContainer.find('.search-overflow').css('height', suggestionsBoxHeight);
                    });

                }
            }

            // record the query that has been executed
            lastQuery = runningQuery;
            // reset currently running query
            runningQuery = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (currentQuery !== lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
            this.hideLeftPanel();
        }.bind(this));
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }
        $resultsContainer.fadeOut(200, function () {$resultsContainer.empty();});
    },
    /**
     * @function
     * @description
     */
    hideLeftPanel: function () {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').css('display', 'none');
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    }
};

module.exports = searchsuggest;
