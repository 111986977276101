'use strict';

const formNotification = function (type, title, text) {
    function getIcon() {
        switch (type) {
            case 'success':
                return '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6784 8.52561L16.7402 7.38026C16.7029 7.32963 16.6443 7.30432 16.591 7.30432C16.5323 7.30432 16.479 7.32963 16.4417 7.38026L9.93868 15.1573L7.57201 12.3477C7.52937 12.2971 7.47606 12.2718 7.42276 12.2718C7.36946 12.2718 7.31615 12.2971 7.27351 12.3477L6.32471 13.4741C6.23942 13.5753 6.23942 13.7335 6.32471 13.8348L9.3097 17.3784C9.5016 17.6062 9.73613 17.7391 9.93335 17.7391C10.2159 17.7391 10.4611 17.4923 10.5517 17.3911H10.557L17.6837 8.88631C17.7583 8.77873 17.7583 8.62053 17.6784 8.52561Z" fill="white"/></svg>';
            case 'rate-limit':
                return '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"> <style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#BD6000;}</style><path class="st0" d="M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10S17.5,22,12,22z M12,7c0.6,0,1,0.4,1,1v5c0,0.6-0.4,1-1,1s-1-0.4-1-1V8C11,7.4,11.4,7,12,7z M11,16c0-0.6,0.4-1,1-1s1,0.4,1,1c0,0.6-0.4,1-1,1S11,16.6,11,16z"/></svg>';
            default:
                return '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.0629 5.57214C11.3446 5.06705 11.6859 4.77762 11.9996 4.77762C12.3132 4.77762 12.6545 5.06705 12.9375 5.57214L18.0045 14.6313C18.2881 15.1383 18.3544 15.5953 18.1862 15.8841C18.0168 16.1747 17.5852 16.3423 17.0015 16.3423H6.99762C6.4145 16.3423 5.98299 16.1747 5.81358 15.8834C5.64478 15.5946 5.71168 15.1383 5.99527 14.6313L11.0629 5.57214ZM6.99772 17.28H17.0016C17.9322 17.28 18.6344 16.9543 18.9787 16.361C19.3249 15.7666 19.2629 14.987 18.8044 14.1675L13.7374 5.1077C13.2801 4.29067 12.6626 3.83997 11.9997 3.83997C11.3361 3.83997 10.7193 4.29067 10.2626 5.1077L5.19557 14.1675C4.73644 14.9864 4.67444 15.7659 5.02186 16.3617C5.36621 16.9543 6.06841 17.28 6.99772 17.28ZM11.9998 13.44C11.7348 13.44 11.5198 13.2414 11.5198 12.9968V9.08324C11.5198 8.83857 11.7348 8.64 11.9998 8.64C12.2647 8.64 12.4798 8.83857 12.4798 9.08324V12.9968C12.4798 13.2414 12.2647 13.44 11.9998 13.44ZM12 15.36C11.7355 15.36 11.5198 15.1446 11.5198 14.8803C11.5198 14.6154 11.7355 14.4 12 14.4C12.2646 14.4 12.4798 14.6154 12.4798 14.8803C12.4798 15.1446 12.2646 15.36 12 15.36Z" fill="white"/></svg>';
        }
    }

    function getNotification() {
        const className = 'form-notification';
        const $icon = $(`<div class="${className}__icon"></div>`)
            .css({
                width: 24,
                height: 24,
                backgroundColor:
                    type === 'success'
                        ? '#009372'
                        : type === 'warning'
                        ? '#EB7024'
                        : type === 'rate-limit'
                        ? '#FFFF'
                        : '#EE595A',
                borderRadius: '50%',
                marginRight: 10,
                flexShrink: 0,
            })
            .append($(getIcon()).css('display', 'block'));

        const $title = $(`<div class="${className}__title">${title}</div>`).css(
            {
                fontFamily: '$paris-primary-font, arial, helvetica',
                fontWeight: 'bold',
                fontSize: type === 'rate-limit' ? 14 : 16,
                lineHeight: '22px',
                color: '#1A1A1A',
            }
        );

        const $header = $(`<div class="${className}__header"></div>`).css({
            display: 'flex',
            alignItems: 'center',
            marginBottom: title.length ? 5 : 0,
        });

        const $body = $(`<div class="${className}__body">${text}</div>`).css({
            fontFamily: '$paris-primary-font, arial, helvetica',
            fontSize: 14,
            lineHeight: '19px',
            backgroundColor: type === 'rate-limit' ? '#FFFCE0' : '',
            color: '#1A1A1A',
        });

        const $wrapper = $(`<div class="${className}__wrap"></div>`).css({
            padding: 10,
            backgroundColor: type === 'rate-limit' ? '#FFFCE0' : '',
        });

        $header.append($icon);
        if (title.length) {
            $header.append($title);
        } else {
            $header.append($body);
        }

        $wrapper.append($header);
        if (title.length) {
            $wrapper.append($body);
        }

        const $notification = $(
            `<div class="form-notification form-notification--${type}"><div>`
        ).css({
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor:
                type === 'success'
                    ? '#009372'
                    : type === 'warning'
                    ? '#EB7024'
                    : type === 'rate-limit'
                    ? '#BD6000'
                    : '#EE595A',
            borderRadius: 8,
            overflow: 'hidden',
        });

        $notification.append($wrapper);

        return $notification;
    }

    const $notification = getNotification();

    return $notification;
};

module.exports = formNotification;
