'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    ajax = require('../ajax'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    progress = require('../progress'),
    googlePlaces = require('../components/googlePlaces'),
    registration = require('./registration'),
    formPrepare = require('./checkout/formPrepare'),
    regions = require('../regions'),
    newLogin = require('../new-login');


/**
 * Delete address from customer addresses book by addressID (address name)
 * 
 * @returns
 */

function showDeleteAddrDialog($element) {
	
    dialog.openConfirmation(
		$('.addresses'),
		String.format(Resources.DELETE_ADDRESS),
		String.format(Resources.DELETE_ADDRESS_CONFIRM_MESSAGE), 
		function () {
			deleteAddress($element);
		});
}

function deleteAddress($element) {
	var addressID = util.getQueryStringParams($element.attr('href')).AddressID.toString();
	var url = util.appendParamsToUrl(Urls.deleteAddress,{format : 'ajax', AddressID : addressID, isMyAccount : 'true'});
	var $target = $('.js-account-content');
	progress.show($target);
    $.ajax({
        url: url,
        method: 'POST'
    }).done(function (data) {
    	if (typeof(data) !== 'string') {
            if (data.success) {
               page.refresh();
            } else if (data.error) {
               page.redirect(Urls.csrffailed);
            } else {
               window.alert(data.message);
               return false;
            }
        } else {
        	$target.html(data);
        	initAddressEvents();
        }
    	progress.hide();
    });
}

function makeAddressDefault($element) {
	var $target = $('.js-account-content');
    progress.show($target);
    var url = util.appendParamToURL($element.attr('href'), 'isMyAccount', 'true');
    ajax.load({
		url: url,
		callback: function (data) {
			$target.html(data);
			progress.hide();
			googlePlaces.init();
			initAddressEvents();
		}
	});
}


/**
 * Check the form when addressname is modified
 * 
 */

function validateFormFields () {
	var $form = $('#edit-address-form');
	var error = validateHiddenFormFields();
	var $addressID = $('.js-address-id-check input');
	var $department = $form.find('input[name*="_address3"]');
	if (error && $addressID.length > 0 && $department.length > 0) {
		error = true;
	}
	if (!$department.valid()) {
		error = true;
	}
	if (!$addressID.valid()) {
		error = true;
	}
	if (error) {
		//$('.js-apply-button').attr('disabled', 'disabled');
		return error;
	}
	$('.js-apply-button').removeAttr('disabled');
	
}
/**
 * Validate form hidden fields
 * @returns
 */
function validateHiddenFormFields () {
    var error = false;
    $( '.js-google-validation' ).each(function( index ) {
        var element = $(this);
        var elementForm = element.closest('form');
        var skipElement = elementForm.hasClass('js-form-edit-address') && (element.hasClass('js-latitude') || element.hasClass('js-longitude')) ? true : false;
        if (!skipElement && !element.find('input ,select').val()) {
            error = true;
            return;
        }
    });
	return error;
}

function validateGoogleAddrFields () {
    var error = validateHiddenFormFields();

    if (error) {
        $('.js-google-error').removeClass('hidden');
        $('#searchTextField').addClass('error');
        
    } else {
    	$('.js-google-error').addClass('hidden');
        $('#searchTextField').removeClass('error');
        $('#searchTextField').addClass('valid');
        $('.js-apply-button').removeAttr('disabled');
       
    }
    validateFormFields();
}

function checkForms () {
	var $form = $('#edit-address-form');
	if ( $('button[name$="dwfrm_profile_address_create"]').length ) {
	    formPrepare.init({
	    	continueSelector: '[name$="_profile_address_create"]',
	    	formSelector: $form,
	    	enabledByDefault: true
	    });
	} else if ( $('button[name="dwfrm_profile_address_edit"]').length ) {
		formPrepare.init({
	    	continueSelector: '[name$="_profile_address_edit"]',
	    	formSelector: $form,
	    	enabledByDefault: true
	    });
	}
}

function clearAddressForm(clearSearchField) {
	var cleanGoogleField = clearSearchField != undefined ? clearSearchField : true;
    var $form = $('[name$="_profile_address"]');
   
    $form.find('input[name$="_address3"]').val('');
    $form.find('input[name$="_address1"]').val('');
    $form.find('input[name$="_address2"]').val('');
    $form.find('select[name$="_states_state"]').prop("selectedIndex", 0);
    $form.find('select[name$="_city"]').prop("selectedIndex", 0);
    $form.find('input[name$="_latitude"]').val('');
    $form.find('input[name$="_longitude"]').val('');
    if (cleanGoogleField) {
        $form.find('input[name$="_addressID"]').val('');
    }
    
    validateFormFields();
    
    
}
/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    regions.initializeRegionsComunaFiltering();
    var $form = $('#edit-address-form');
    var $target = $('.js-account-content');
    $form.find('input[name="format"]').remove();
    tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);
    checkForms();
    
    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
					        
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data , textStatus, jqXHR) {
        	
        	window.dataLayer.push({
				'event':'page-virtual',
				'page':'/ my-account / address / add / Confirmation',
				'pagename':'My Account - My Addresses - Confirmacion'
        	});
        	
            if (typeof(data) !== 'string') {
                if (data.success) {
                   page.refresh();
                } else if (data.error) {
                   page.redirect(Urls.csrffailed);
                } else {
                   window.alert(data.message);
                   return false;
                }
            } else {
            	$target.html(data);
            	initAddressEvents();
                tooltip.init();
            }
        });
    });
    $form.on('click', '.js-cancel-button', function (e) {
        e.preventDefault();
        var $target = $('.js-account-content');
        var url = util.appendParamToURL(Urls.addressList , 'format', 'ajax');
        progress.show($target);
        ajax.load({
    		url: url,
    		callback: function (data) {
    			$target.html(data);
    			initAddressEvents();
    			tooltip.init();
    			progress.hide();
    		}
    	});
    });
    
    validator.init();
    $(document).on('validate.google.addresses', validateGoogleAddrFields);
    $(document).on('validate.form.fields', validateFormFields);
    var $apartment = $form.find('input[name*="_address3"]');
    $apartment.on('change',function() {
    	validateFormFields();
    }).on('keydown', function (event) {
		if (event.keyCode == 9) {
			validateFormFields();
		}
    });
    
    $('#searchTextField').on('change', function(e) {
        setTimeout(function() {
         //   $('.js-apply-button').attr('disabled', 'disabled');
            $('.js-google-error').removeClass('hidden');
            $('#searchTextField').addClass('error');
            clearAddressForm(false);
        }, 1);
    });
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}

function requestLogin(clickedElement) {
    if (clickedElement instanceof jQuery) {
        if (clickedElement.prop('tagName') !== 'A') {
            clickedElement = clickedElement.closest('a').length ? clickedElement.closest('a') : clickedElement.closest('button');
        }
        const requestLogin = clickedElement.data('request-login');
        if (requestLogin) {
            newLogin.loadModal(null, Resources.ACCOUNT_LOGIN_MODAL_SUBTITLE || '');
            return true;
        }
    }

    return false;
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('.addresses');
    if (addresses.length === 0) { return; }
    var $target = $('.js-account-content');
    
    addresses.on('click', '.js-address-edit, .js-address-create', function (e) {
        e.preventDefault();

        if (requestLogin($(e.target))) {
            return;
        }

        progress.show($target);
        ajax.load({
    		url: this.href,
    		callback: function (data) {
    			$target.html(data);
    			progress.hide();
    			googlePlaces.init();
				initializeAddressForm();
				if(!SitePreferences.GMAPS_AUTOCOMPLETE_ENABLE){
					$('.js-form-hidden-field').toggleClass('hidden');
				}
    		}
    	});
    	
        window.dataLayer.push({
        	'event': 'page-virtual',
        	'page': '/ my-account / address / add', 
        	'pagename' :'My Account - My Addresses - Add',
        });
    });
    

    $('.js-address-delete').on('click', function (e) {
        e.preventDefault();

        if (requestLogin($(e.target))) {
            return;
        } else {
            showDeleteAddrDialog($(this));
        }
    });
    
    $('.address-make-default').on('click', function (e) {
        e.preventDefault();

        if (requestLogin($(e.target))) {
            return;
        } else {
            makeAddressDefault($(this));
        }
    });
    

}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues

        const requestLogin = $(this).data('request-login');
        if (requestLogin) {
            newLogin.loadModal(null, Resources.ACCOUNT_LOGIN_MODAL_SUBTITLE || '');
            return;
        }

        var that = this;
        dialog.openConfirmation(
			paymentList, 
			String.format(Resources.CREDIT_CARD_DELETE_TITLE),
			String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD), 
			function () {
				removePaymentInstrument(that);
			});
    });
}

function removePaymentInstrument(paymentForm) {
	var button = $(paymentForm).find('.delete');
    $('<input/>').attr({
        type: 'hidden',
        name: button.attr('name'),
        value: button.attr('value') || 'delete card'
    }).appendTo($(paymentForm));
    var data = $(paymentForm).serialize();
    $.ajax({
        type: 'POST',
        url: $(paymentForm).attr('action'),
        data: data
    })
    .done(function () {
        page.redirect(Urls.paymentsList);
    });
}

function initializePaymentForm() {
    $('#CreditCardForm').on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });
}

function initChangePassword() {
	//this is form form reset password from request new password functionality
	formPrepare.init({
		continueSelector: '.js-requestchangepasswordbutton',
		formSelector:'#request-change-password-form'
	});
	

	//this is form in my account section for changing password
	initErrorFromServer('#change-password-form', '.js-changepasswordbutton');
}

function initErrorFromServer(formIdentifier, continueButtonIdentifier) {
	var changePasswordForm = $(formIdentifier);
	var submitChangePassword = $(changePasswordForm).find(continueButtonIdentifier);
	
	var errorElements = $(changePasswordForm).find('.server-error.error');
	
	if (errorElements.length > 0) {
	//	submitChangePassword.attr('disabled', 'disabled');
		var firstInitForm = false;
		changePasswordForm.on('focusin', function(e) {
			if (firstInitForm) {
				return;
			}
			firstInitForm = true;
			formPrepare.init({
				continueSelector: continueButtonIdentifier,
				formSelector: formIdentifier,
				enabledByDefault: true
			});
		});
		
		for (var i = 0; i < errorElements.length; i++) {
			var currentErrorElement = $(errorElements[i]);
			var currentErrorElementClone = currentErrorElement.clone();
			var currentErrorHtml = $("<div />").append(currentErrorElementClone).html();
			var errorParent = currentErrorElement.parent();
    		var inputField = $(errorParent).find('input');
    		var fieldId = inputField[0].id;
    		var inputError = $('[id='+fieldId+']')[0];
    		// only for dynamicname inputs if is set to recognize proper identifier selection
    		if ($(inputField).data('dynamicname') == true) {
    			fieldId = $(inputField).data('dynamicname-identifier');
    			inputError = $(fieldId)[0];
    		}
    		
    		$(inputError).addClass('error');
    		if (inputError && errorElements.length > 0) {
    			$($(inputError).parent()).append(currentErrorHtml);
    		} else if (errorElements.length > 0 && $('.js-password-confirm').length == 1){
    			inputError = $('.js-password-confirm')[0];
    			$($(inputError).parent()).append(currentErrorHtml);
    		} else {
    			return;
    		}
    		
    		currentErrorElement.remove();
	    }
	} else {
		formPrepare.init({
			continueSelector: continueButtonIdentifier,
			formSelector:formIdentifier,
			enabledByDefault: true
		});
	}
}

function initChangeEmail() {
	initErrorFromServer('#change-email-form', '[name$="_emailchangeform_changeemail"]');
}

function initChangePersonalDetails() {
    $('#personal-details-form').on('submit', function(e) {
        const requestLogin = $(this).data('request-login');
        if (requestLogin) {
            newLogin.loadModal(null, Resources.ACCOUNT_LOGIN_MODAL_SUBTITLE || '');
            e.preventDefault();
        }
    });
	initErrorFromServer('#personal-details-form', '[name$="_personaldetails_apply"]');
}
function initOrdersHistoryEvents() {
	$(document).off('show.bs.collapse', '.js-order-accordion');
	//hide tabs not active search
    $(document).on('show.bs.collapse', '.js-order-accordion', function () {
        var $resultsContainer = $(this).parents('.table-data');
        progress.show();
        $('.js-order-collapse-in').each(function(){
            $(this).trigger('hide.bs.collapse');
        });
        var collapseBody = $(this);
        var form = $('#js-order-history-form');
        if (form.length == 1) {
	        var submitButton = $(this).siblings('.js-order-details-button');
	        var formActionUrl = form.attr('action');
	        var searchFromHeader = $('.js-search-from-header').val();
            if (searchFromHeader && searchFromHeader != "false" && searchFromHeader != false) {
            	formActionUrl = Urls.seachFromHeader;
            }
	        if (formActionUrl.indexOf('ajax') === -1) {
	        	formActionUrl = util.ajaxUrl(formActionUrl);
            }
	        var data = form.serialize();
	        // add form action to data
            data += '&' + submitButton.attr('name') + '=';
            formActionUrl += data;

            $.ajax({
                type: 'GET',
                url: formActionUrl,
                success: function (response) {
                    if (typeof response === 'object' &&
                            !response.success &&
                            response.error === Resources.CSRF_TOKEN_MISMATCH) {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === 'string') {
                    	collapseBody.html(response);
                    }
                },
                failure: function () {
                	collapseBody.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                },
                complete: function() {
                    progress.hide();
                }
            });
    	}

		$(this).attr('aria-expanded', 'true');
		$(this).addClass('js-order-collapse-in');
		$(this).addClass('in');

		var target = $(this).parent().find('.js-order-accordion-header');
		$(target).attr('aria-expanded', 'true');
		$(target).removeClass('collapsed');
    });
    
    $(document).off('hide.bs.collapse', '.js-order-accordion');
    $(document).on('hide.bs.collapse', '.js-order-accordion', function () {
    	$(this).html('');
		$(this).attr('aria-expanded', 'false');
		$(this).removeClass('js-order-collapse-in');
		$(this).removeClass('in');
		var target = $(this).parent().find('.js-order-accordion-header');
		$(target).attr('aria-expanded', 'false');
		$(target).addClass('collapsed');
    });

    $('.js-order-accordion-header').off('click').on('click', function (e) {
		e.preventDefault();
        e.stopPropagation();
		var expanded = $(this).attr('aria-expanded');
		
		var collapseContainer = $(this).parent().find('.js-order-accordion');
		
		if (expanded == 'true') {
			$(collapseContainer).trigger('hide.bs.collapse');
		} else {
			$(collapseContainer).trigger('show.bs.collapse');
		}
	});
    
}

function initMaxLengthEvents() {
	$(document).on('focusin', '.js-number-max-length', function(event) {
		$(event.target).attr('data-old-value', $(event.target).val());
	});

	$(document).on('input', '.js-number-max-length', function(event) {
        var currentElement = $(event.target);
		var maxLengthAttr = currentElement.attr('maxlength');
		if (typeof maxLengthAttr === typeof undefined || maxLengthAttr === false) {
			return;
		}
		
		var currentValueLength = currentElement.val().length;
		
		var currentValue = currentElement.val();
		
		if (currentValueLength > maxLengthAttr) {
			currentValue = currentElement.attr('data-old-value');
		}
		
		var validationMethod = $.validator.methods['numberscontent'];
		var result = validationMethod.call(this, currentValue, event.target);
		
		if (!result) {
			currentValue = currentElement.attr('data-old-value');
		}
		
		currentElement.val(currentValue);
		currentElement.attr('data-old-value', currentValue);
	});
}

function initPhoneCodeEvents() {
	$(document).off('change', '.js-phonecode');
	$(document).on('change', '.js-phonecode', function(e, param1) {
		//On Change Phone Code , clean the phone number 
		if (param1 != "init") {
			$('.js-phone').val('');
		} 
		var targetElement = $(e.target)
		var formElement = targetElement.parents('form');
		if (formElement.length == 0) {
			return true;
		}
        var phoneElement = $(formElement).find('.js-phone');
		if (phoneElement.length == 0) {
			return true;
		}
		
		var phoneCodeValue = targetElement.val();

		if (phoneCodeValue.length == 3) {
			if (param1 != "init") {
				$(phoneElement).val('');
			}
			$(phoneElement).attr('maxlength', "10");
			$(phoneElement).removeClass('phone').addClass('phonelong');
		} else {
			if (param1 != "init") {
				$(phoneElement).val('');
			}
			$(phoneElement).attr('maxlength', "8");
			$(phoneElement).removeClass('phonelong').addClass('phone');
		}

		$(phoneElement).trigger('change');
	});
	
	$('.js-phonecode').trigger('change', ["init"]);
}

function initPhoneFieldEvents() {
    $(document).on('keydown', '.js-phone', function(event) {
        var charCode = event.which || event.keyCode;
        if (charCode === 38 || charCode === 40) {
            event.preventDefault();
        }
    });
}

function initAnchorBankInfo(params) {
    const $anchor = $('#GTM_mi-cuenta_datos-bancarios');

    $anchor
        .show()
        .on('click', function(e) {
            console.log('click bank anchor');
            if (requestLogin($(this))) {
                e.preventDefault();
            }
        });
}

function initMyCredits(params) {
    const $anchor = $('.js-show-my-credits');
    $anchor
        .show()
        .on('click', function(e) {
            if (requestLogin($(this))) {
                e.preventDefault();
            }
        });
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    login.init();
    initChangePassword();
    googlePlaces.init();
    initChangePersonalDetails();
    initOrdersHistoryEvents();
    initChangeEmail();
    registration.initAloneRegistrationForm();
    initAnchorBankInfo();
    initMyCredits();

    window.dataLayer.push({
        'userID': userIdDataLayer,
        'page': window.location.pathname, 
        'pagename' : "Mi cuenta",
    });

    window.dataLayer.push({
        'event': 'page-virtual',
    });
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
    },
    initOrdersHistoryEvents: function() {
    	initOrdersHistoryEvents();
    },
    initPhoneCodeEvents: function() {
    	initPhoneCodeEvents();
    },
    initPhoneFieldEvents: function() {
        initPhoneFieldEvents();
    },
    initMaxLengthEvents: function() {
    	initMaxLengthEvents();
    },
    initNumberRestrictionEvents: function() {
    	initNumberRestrictionEvents();
    },
    initCartLogin: function () {
        login.init();
    },
    requestLogin,
    initAnchorBankInfo,
    initMyCredits,
};

module.exports = account;
