'use strict';

function ProductsMarking() {
  this.appended = false;
  this.HomeEventShowId = window.Resources.GTM_HOME_CAROUSEL_SHOW_EVENT;
  this.HomeEventClickId = window.Resources.GTM_HOME_CAROUSEL_CLICK_EVENT;

  this.defineListName = (name) => {
    this.listName = name;
  };

  this.defineIsInHome = (isInHome) => {
    this.isInHome = isInHome;
  };

  this.products = [];

  this.pushData = (event, products = []) => {
    const sendDataProducts = products.reduce((dataCollection, productData) => {
      const data = $(productData.product).data('product');
      let newData = {
        ...data,
        position: Number(productData.index) + 1,
        list: `${this.listName}: ${data.category.split('/')[0]}`,
      };
      if(this.isInHome){
        newData.list = this.listName;
      }
      return dataCollection.concat(newData);
    }, []);

    if (!sendDataProducts.length) {
      return;
    }

    if (event === 'eC-recommendationClick' || event.toLowerCase().indexOf('click') > 0) {
      let listName = sendDataProducts[0].list;
      if(event === this.HomeEventClickId){
        sendDataProducts.forEach((prod) => delete prod.list);
      }
      window.dataLayer.push({
        "event": event,
        "ecommerce": {
          "click": {
            "actionField": {
              "list": listName,
              "action": "click"
            },
            "products": sendDataProducts,
          },
        },
      });
    } else {
      window.dataLayer.push({
        "event": event,
        "ecommerce": {
          "currencyCode": "CLP",
          "impressions": sendDataProducts,
        },
      });
    }
  };

  this.getProductsToMark = (showedProducts) => {
    let productsToMark = [];

    this.products = this.products.map(currentDataProduct => {
      const product = currentDataProduct.product;
      const dataProduct = $(product).data('product');

      showedProducts.forEach(showedProduct => {
        const dataShowedProduct = $(showedProduct).data('product');

        if (dataProduct.id === dataShowedProduct.id && !currentDataProduct.sended) {
          delete currentDataProduct.sended;
          productsToMark = productsToMark.concat(currentDataProduct);

          currentDataProduct = {
            ...currentDataProduct,
            sended: true,
          };
        }
      });

      return currentDataProduct;
    });

    return productsToMark;
  };

  this.listenClickEvents = ($list) => {
    $list.find('.product-tile').each((i, product) => {
      const $product = $(product);
      const productData = $product.data('product');

      const productClicked = this.products.filter(product => {
        return $(product.product).data('product').id === productData.id;
      });

      const handleClick = () => {
        this.pushData(!this.isInHome ? 'eC-recommendationClick' : this.HomeEventClickId, productClicked);
      };

      $product.find('a[href]').on('click', handleClick);
    });
  };

  this.checkIsInViewport = (slick) => {
    const check = () => {
      const scrollTop = $(window).scrollTop();
      const winHeight = $(window).height();
      const elOffsetTop = slick.$slider.offset().top;

      if (scrollTop + winHeight > elOffsetTop) {
        this.sendShowedProducts(slick);
        this.appended = true;
      }
    }

    check();
    $(window).on('scroll', () => {
      if (!this.appended) {
        check();
      }
    });
  };

  this.sendShowedProducts = (slick) => {
    let showedProducts = [];
    slick.$list.find('.slick-slide[aria-hidden="false"]').each((i, slide) => {
      const product = $(slide).hasClass('product-tile') ? slide : $(slide).find('.product-tile').get(0);
      showedProducts = showedProducts.concat(product);
    });
    
    const productsToMark = this.getProductsToMark(showedProducts);
    this.pushData(!this.isInHome ? 'eC-recommendationView' : this.HomeEventShowId, productsToMark);
  };

  this.handleChangeSlide = (slick, currentSlide) => {
    if (currentSlide === null || currentSlide === undefined) {
      this.products = [];
      slick.$slides.each((i, slide) => {
        this.products = this.products.concat({
          index: i,
          product: $(slide).hasClass('product-tile') ? slide : $(slide).find('.product-tile').get(0),
          sended: false,
        });
      });

      this.listenClickEvents(slick.$list);

      this.checkIsInViewport(slick);
    } else {
      this.sendShowedProducts(slick);
    }
  };
};

module.exports = ProductsMarking;
