'use strict';

var dialog = require('../../dialog'),
	progress = require('../../progress'),
	minicart = require('../../minicart'),
	page = require('../../page'),
    util = require('../../util'),
    ajax = require('../../ajax'),
	Promise = require('promise'),
	_ = require('lodash'),
	image = require('../product/image'),
	variant = require('./variant'),
	newLogin = require('../../new-login'),
    pageInformation = require('../../components/pageInformation');
	
const warrantyModal = require('../../components/product/warranty-modal');
const isEmpty = require('lodash');
window.formNotification = require('../../components/commons/form-notification');

let deviceType = '';
const sampling = {
    update: function (targetURL, params) {
        const $target = $('#dialog-container');
        ajax.load({
            url : targetURL,
            data: params,
            target: $target,
            callback: function (data) {
                initializeSamplingEvents(deviceType);
                if (params.type === 'add') {
                    $('.js-continue-to-cart').removeAttr('disabled');
                } else {
                    $('.js-continue-to-cart').attr('disabled');
                }
            }
        });
    },
    addBonusProduct: function (item) {
        this.update(Urls.addBonusProduct, {'sku': item.attr('data-id'), source: 'pdp', type: 'add'});
        $('html').removeClass('ui-scroll-disabled');
    },
    removeProductFromCart: function (item) {
        this.update(Urls.cartRemoveProduct, {'lineItemUUID': item.attr('data-uuid'), source: 'pdp', type: 'remove'});
    }
};

/**
 * @function
 * @description Binds the click event to a given target for the sampling modal
 */
function initializeSamplingEvents(type) {
    $('#content-bonus-product-availible').addClass('cart-promo__body-show');
    deviceType = type;
    if (type === 'desktop') {
        loadCarousel();
    }

    $('.bonus-item-promo').on('click', '.js-add-bonus-product', function (e) {
        e.preventDefault();
    
        $(this).empty().addClass('btn--loading');
        $(this).append($('<div>'));
        $(this).append($('<div>'));
        $(this).append($('<div>'));
    
        sampling.addBonusProduct($(this).closest('.js-add-bonus-product'));
    
        $('html').removeClass('ui-scroll-disabled');

        const skuFather = $('#productID').val();
        const skuSampling = $(this).attr('data-id');
        const tag = `${skuFather} / ${skuSampling}`;
        window.dataLayer.push({
            'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
            'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_ADD, 
            'event-interactive-tag': tag,
            'event': 'event-interactive'
        });
    });
    
    $('.bonus-item-promo').on('click', '.js-remove-product', function (e) {
        e.preventDefault();

        $(this).empty().addClass('btn--loading');
        $(this).append($('<div>'));
        $(this).append($('<div>'));
        $(this).append($('<div>'));

        sampling.removeProductFromCart($(this).closest('.js-cart-lineitem'));
        $('html').removeClass('ui-scroll-disabled');
    });
    
    $('.bonus-item-promo').on('click', '.js-remove-bonus-product', function (e) {
        const skuFather = $('#productID').val();
        const skuSampling = $(this).attr('data-id');
        const tag = `${skuFather} / ${skuSampling}`;
        window.dataLayer.push({
            'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
            'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_REMOVE, 
            'event-interactive-tag': tag,
            'event': 'event-interactive'
        });
    });

    $('.bonus-item-promo').on('click', '.js-continue-to-cart', function (e) {
        const skuFather = $('#productID').val();
        const skuSampling = $('#remove-product-cart').attr('data-id');
        const tag = `${skuFather} / ${skuSampling}`;
        window.dataLayer.push({
            'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
            'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_ADD_CART, 
            'event-interactive-tag': tag,
            'event': 'event-interactive'
        });
        dialog.close();
    });

    $('.bonus-item-promo').on('click', '.slick-arrow', function (e) {
        const arrow = $(this).attr('aria-label').toLowerCase();
        const tag = `Arrow ${arrow === 'previous' ? 'Left' : 'Right'}`;
        window.dataLayer.push({
            'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
            'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_CLICK_ARROW, 
            'event-interactive-tag': tag,
            'event': 'event-interactive'
        });
    });
}

/**
 * @function
 * @description Initialice slider for sampling
 */
function loadCarousel() {
    $('.cart-promo__product-container-slider').not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        draggable: false
    });
}

/**
 * @function
 * @description return true or false to decide if the producy pdp must open the sampling modal
 */
function callShowSamplingModal(productID) {
    let mustOpenSamplingModal = false;
    ajax.getJson({
        url: Urls.showSamplingModalInPDP,
        data: { 'sku': productID },
        async: false,
        callback: function (data) {
            if (data !== undefined || data !== null) {
                if (data.success) {
                    mustOpenSamplingModal = data.mustOpenSamplingModal;
                }
            }
        }
    });
    return mustOpenSamplingModal;
}

/**
 * @function
 * @description open the actual sampling modal with corresponding options
 */
function showSamplingModal(type) {
    dialog.open({
        url: Urls.productSamplingCart,
        options: {
            title: '¡Tienes 1 muestra de regalo!',
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            dialogClass: 'ui-dialog-full',
            open: function () {
                $('.ui-dialog-titlebar').prepend(Resources.BONUS_PRODUCT_SVG);
                initializeSamplingEvents(type);
            },
            close: function () {
                $('#svg-gift').remove();
                if (type === 'desktop') {
                    $('.bonus-item-promo').off('click');
                    showMiniCart();
                } else {
                    $('.bonus-item-promo').off('click');
                    startModalAddedToCart();
                }
            },
        }
    });
}

/**
 * @function
 * @description open minicart
 */
function showMiniCart() {
    minicart = require('../../minicart');
    restorePdpButton();
    minicart.showAjax(true);
}

/**
 * @function
 * @description execute process for sampling modal
 */
function execSamplingModal(productID, type) {
    const mustOpenSamplingModal = callShowSamplingModal(productID);
    if (mustOpenSamplingModal) {
        showSamplingModal(type);
    } else {
        if (type === 'desktop') {
            showMiniCart();
        } else {
            startModalAddedToCart();
        }
    }
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
function addItemToCart(form) {
	var $form = $(form),
		$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}

	var addProductURL = Urls.addProduct;

	var pdpMain = $form.parents('#pdpMain');

	if (pdpMain.length > 0) {
		var sourceProductID = pdpMain.attr('data-pid');
		if (typeof sourceProductID != "undefined" && sourceProductID != null) {
			var searchIdentifier = '.js-cart-lineitem[data-pid="' + sourceProductID + '"]';
			var cartProductContainer = $(document).find(searchIdentifier);
			if (cartProductContainer.length > 0) {
				var UUID = cartProductContainer.attr('data-uuid');
				if ($('.js-quickview-lineitem-uuid').length > 0 && $('.js-quickview-lineitem-uuid').text().length > 0) {
					UUID = $('.js-quickview-lineitem-uuid').text();
				}
				addProductURL = util.appendParamToURL(addProductURL, 'uuid', UUID);
			}
		}
	}
	addProductURL = getAddToCartURLVerifyingCMediaSKU(addProductURL);

	return Promise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(addProductURL),
		data: $form.serialize()
	})).then(function (response) {
		// handle error in the response
		if (response.error) {
			throw new Error(response.error);
		} else {
			return response;
		}
	});
};

function getAddToCartURLVerifyingCMediaSKU(addProductURL) {
	let sku = $('#productID').val();
	if (document.cookie && sku) {
		let cookies = document.cookie.split(';');
		let skuCmediaCookie = cookies.filter((c) => c.indexOf(`isCmedia-${sku}`) >= 0);
		if (skuCmediaCookie.length > 0) {
			addProductURL = util.appendParamToURL(addProductURL, 'isCmedia', sku);
		}
	}
	return addProductURL;
}

function showModalPdpError(mensaje) {
	$('#messageStockErrorOld').hide(),
		$('#messageStockError').show(),
		$('#messageStockError').html(mensaje),
		$.when(
			showModalPdp()
		).done(function () {
		});
}

function showModalPdp() {
	var showModalBg = document.getElementById('alertStockQuantity');
	if (showModalBg) {
		showModalBg.classList.remove('hidden');
		setTimeout(showMessagePdp(), 1000);
	}
	return true;
}

function showMessagePdp() {
	var showModalMessage = document.querySelector('.feedback-complete');
	setTimeout(function () {
		hideFeedBackPdp();
	}, 10000);
}

function startModalAddedToCart(mensaje) {
	setFeedBackStylesToOpening();
	setTimeout(function () {
		showModalAddedToCart()
		minicart.updateCartBadge();
	}, 500);
}
function stopModalAddedToCart(mensaje) {
	setFeedBackStylesToClosed();
	$('span.add-to-cart__label').removeClass('hide');
	removeDisableButtonClass();
}
function showModalAddedToCart(mensaje) {
	var mobileAddToCartFeedback = document.querySelector('#mobileAddToCartFeedback');
	mobileAddToCartFeedback.classList.remove('closed');
	$('body').addClass('fixed');
	mobileAddToCartFeedback.classList.add('opened');
	var overlayAddToCartFeedback = document.querySelector('.overlay-addToCart-feedback');
	overlayAddToCartFeedback.classList.add('opened');
	overlayAddToCartFeedback.classList.remove('hidden');

	$('#mob-checkout-form').removeClass('hidden');

	$('.overlay-addToCart-feedback').unbind('click');
	$('.overlay-addToCart-feedback').on('click', function () {
		stopModalAddedToCart();
		//minicart.close();
		//$(document.body).css('overflow', 'auto');
		$('body').removeClass('fixed');
		$('.overlay-addToCart-feedback').removeClass('opened');
		$('#mobileAddToCartFeedback').removeClass('opened');
		$('#mobileAddToCartFeedback').addClass('closed');
		$('#mob-checkout-form').addClass('hidden');
	});

	document.addEventListener('touchstart', handleTouchStart, false);
	document.addEventListener('touchmove', handleTouchMove, false);

	var xDown = null;
	var yDown = null;

	function getTouches(evt) {
		return evt.touches ||             // browser API
			evt.originalEvent.touches; // jQuery
	}

	function handleTouchStart(evt) {
		const firstTouch = getTouches(evt)[0];
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY;
	};

	function handleTouchMove(evt) {
		if (!xDown || !yDown) {
			return;
		}

		var xUp = evt.touches[0].clientX;
		var yUp = evt.touches[0].clientY;

		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
			if (xDiff > 0) {
				/* left swipe */
			} else {
				/* right swipe */
			}
		} else {
			if (yDiff > 0) {
				/* up swipe */
			} else {
				/* down swipe */
				document.querySelector('#mobileAddToCartFeedback').classList.remove('opened');
				document.querySelector('.overlay-addToCart-feedback').classList.remove('opened');
				document.querySelector('#mobileAddToCartFeedback').classList.add('closed');
				$('body').removeClass('fixed');
				stopModalAddedToCart();
			}
		}
		/* reset values */
		xDown = null;
		yDown = null;
	};

	$('#mob-go-to-cart').unbind('click');
	$('#mob-go-to-cart').on('click', function () {
		page.redirect(Urls.cartShow);
	});

	$('#mob-continue-buying').unbind('click');
	$('#mob-continue-buying').on('click', function () {
		page.redirect(Urls.cartCheckout);
	});
}
function setFeedBackStylesToOpening() {
	// $("#add-to-cart").html(Resources.MOB_ADDINGTOCART);
	$('#add-to-cart').prop('disabled', true);
	$('.button-plus-pdp__right').prop('disabled', true);
	$('.button-minus-pdp__left').prop('disabled', true);
	$("#js-input-group-pdp").css({ opacity: 0.5 });
	$('#add-to-cart').css('font-size', '1em');
	$('form input[type="submit"]').prop("disabled", true);

}
function setFeedBackStylesToClosed() {
	// $("#add-to-cart").html(Resources.MOB_ADDTOCART);
	$('#add-to-cart').prop('disabled', false);
	$('.button-minus-pdp__left').prop('disabled', false);
	$('.button-plus-pdp__right').prop('disabled', false);
	$('#add-to-cart').css('font-size', '1.1em');
	$("#js-input-group-pdp").css({ opacity: 1 });
	$('form input[type="submit"]').prop("disabled", false);
	$('.loader-button').remove();
	$('span.add-to-cart__label').addClass('hide');
}
function hideFeedBackPdp() {
	var hideModalMessage = document.querySelector('.modal-content-bg');
	hideModalMessage.classList.add('hidden');
	$('.loader-button').remove();
	$('span.add-to-cart__label').removeClass('hide');
	removeDisableButtonClass();
}
function catchErrorMessage(response){
	var errorMsg = $(response).filter('.js-unavailable-stock');
	$('#messageStockError').html(errorMsg);
}

function getProductData(element) {
    var productData = {};
    if ($('.plp-wrapper').length > 0) {
        productData = $(element).closest('.product-tile').data('product');
    }
    if ($('.pdp-main').length > 0) {
        productData = $(element).data('product');
        if (Array.isArray(productData)) {
            productData = productData[0];
        }
    }
    return productData;
}

/**
 * @description Handler to handle the add to cart event
 */
function addToCart(e) {
	if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
	var $form = $(this).closest('form');
	var $options = $form.find('select.product-option');
	
	if ($options.length > 0 && $options.val().length == 0) {
		//error message
		$('.add-to-cart').attr('disabled', 'disabled');
		$('.add-to-cart').attr('title', $options.find(':selected').text());
		$options.addClass('error');
		return;
	}
    var productData = getProductData(this);
	addLoadingButtonPdp();
	addItemToCart($form).then(function (response) {
		if ($(response).find('#viewMixBasketError').length) {
			loadMixedBasketModal(productData);
		} else {
			addToItemToCartModal($form, response);
		}
	});
};

function buyItNow(e) {
	e.preventDefault();
	const $formPDP = $(this).closest('form');

	const dataProduct = $formPDP.data('product');
	dataProduct.quantity = 1;

	const productArr = [dataProduct];
	
	window.dataLayer.push({
		'event': 'addComprarAhora',
		'ecommerce': {
			'add': {
				'products': productArr,
			},
		},
	});
	addItemToCart($formPDP).then((response) => {
		if ($(response).find('#viewMixBasketError').length) {
			loadMixedBasketModal(dataProduct);
		} else {
			const isNoviosCart = SitePreferences.IS_NOVIOS_CART;
			const smartCustomerRegion = JSON.parse(localStorage.getItem('smart_customer_region'));
			if (!isNoviosCart && !smartCustomerRegion) {
				return $('#header-comuna-source-btn').click();
			}
			page.redirect(Urls.cartCheckout);
		}
	});
}

function renderLoginModal(origin, formData) {
	const url = window.Urls.getLoginForm;

	dialog.open({
		url,
		options: {
			title: window.Resources.ACCOUNT_LOGIN_MODAL_TITLE,
			draggable: false,
			autoOpen: false,
			responsive: true,
			modal: true,
			fluid: true,
			width: 395,
			dialogClass: 'ui-dialog-full ui-dialog-login-customer',
		},
		callback($modal) {
			const $form = $('#dwfrm_login');

			$form.find('a[data-action="register"]').on('click', function(e) {
				if ($modal && $modal.length) {
					$modal.dialog('close');
				}
			});

			$form.find('a[data-action="recovery-password"]').on('click', function(e) {
				if ($modal && $modal.length) {
					newLogin.loadRecoveryModal($(this));
				} else {
					newLogin.loadRecoveryModal($(this), false);
				}
			});

			const formValidation = $form.validateForm();
			let $notification = null;
			let isSending = false;

			formValidation.onErrorUpdated = function() {
				if (this.hasErrors()) {
					this.$submitButton.prop('disabled', 'disabled');
				} else {
					if (!isSending) {
						this.$submitButton.removeAttr('disabled');
					}
				}
			};

			$form.on('submit', (e) => {
				e.preventDefault();
				isSending = true;

				if ($notification) {
					$notification.remove();
				}

				formValidation.$submitButton.addClass('btn--loading').empty();
				formValidation.$submitButton.append($('<div></div>'));
				formValidation.$submitButton.append($('<div></div>'));
				formValidation.$submitButton.append($('<div></div>'));

				formValidation.validate()
					.then(valid => {
						if (valid) {
							return newLogin.submit($form);
						}
					})
					.then(response => {
						if (typeof response === 'object' && !response.success && response.error === Resources.CSRF_TOKEN_MISMATCH) {
							page.redirect(Urls.csrffailed);
						}
		
						if (typeof response !== 'string' && response.isMigrated) {
							newLogin.loadRecoveryModal($form.find('a[data-action="recovery-password"]'));
							return;
						}
		
						if (typeof response === 'string') {
							var $divResponse = $('<div>' + response + '</div>');
							var $errorElement = $divResponse.find('div.error-form');
							var $userInvalid = $divResponse.find('#dwfrm_login_user_invalid');

							if ($errorElement.length > 0) {
								formValidation.$submitButton.removeClass('btn--loading').empty().text('Iniciar sesión').blur();
								formValidation.$submitButton;
								$notification = window.formNotification('error', 'El correo o la contraseña no coinciden', 'Revisa que estén escritos correctamente y vuelve a intentar.');
								$form.prepend($notification.css('marginBottom', 20));
								return;
							}

							if ($userInvalid.length > 0 && $userInvalid[0].value === "true") {
								formValidation.$submitButton.removeClass('btn--loading').empty().text('Iniciar sesión').blur();
								formValidation.$submitButton;
								$notification = window.formNotification('error', '', 'Has introducido un correo o contraseña incorrecta.');
								$form.prepend($notification.css('marginBottom', 20));
								return;
							}
						}
						
						if ($modal && $modal.length) {
							$modal.dialog('close');
						}

						localStorage.setItem('smart_customer_login', 'success');

						if (origin === 'buyItNow') {
							addItemToCart(formData).then(() => {
								page.redirect(Urls.cartCheckout);
							});
						} else if (origin === 'addToCart') {
							progress.show();
							return Promise.resolve($.ajax({
								type: 'POST',
								url: location.href
							})).then((response) => {
								var newHTML = document.open("text/html", "replace");
								newHTML.write(response);
								newHTML.close();
								addItemToCart(formData).then(function (response){
									progress.hide();
									addToItemToCartModal(formData, response);
								});
							});
						} else if (origin === 'addToCartOneColumn') {
							progress.show();
							return Promise.resolve($.ajax({
								type: 'POST',
								url: location.href
							})).then((response) => {
								var newHTML = document.open("text/html", "replace");
								newHTML.write(response);
								newHTML.close();
								const addProductURL = Urls.addProduct;
								return Promise.resolve($.ajax({
									type: 'POST',
									url: util.ajaxUrl(addProductURL),
									data: formData
								}));
							}).then(function (response) {
								progress.hide();
								if (window.matchMedia('(min-width: 719px)').matches) {
									var $listItem = $(this).parents('.table-products-row-favourite');
									if ($listItem.length > 0) {
										if ($(response).filter('.js-add-to-cart-errors').length === 1) {
											$('.type-of-shipping').hide();
											showModalPdpError(response);
										} else {
											minicart.showAjax(true, $listItem);
										}
									} else {
										if ($(response).filter('.js-unavailable-stock').length === 1) {
											$(this).text($(response).find('span').text()).addClass('disabled');
											catchErrorMessage(response);
											$('.type-of-shipping').hide();
											showModalPdp();
										} else if ($(response).filter('.js-add-to-cart-errors').length === 1) {
											$('.type-of-shipping').hide();
											showModalPdpError(response);
										} else {
											var $modalProduct = document.getElementById('QuickViewDialog');
											if (!$modalProduct){
												minicart = require('../../minicart');
												restorePdpButton();
												minicart.showAjax(true);
											}
										}
									}
								} else {
									if ($(response).filter('.js-unavailable-stock').length === 1) {
										$(this).text($(response).find('span').text()).addClass('disabled');
										catchErrorMessage(response);
										showModalPdp();
									} else if ($(response).filter('.js-add-to-cart-errors').length === 1) {
										$('.type-of-shipping').hide();
										showModalPdpError(response);
									} else {
										if(showMessage) {
											startModalAddedToCart();
										} else {
											page.redirect(Urls.cartShow);
										}
									}
								}
								restorePdpButton();
							});
						} else if (origin === 'mini-cart-link-checkout') {
							window.location.href = formData;
						} else if (origin === 'mini-cart-link-cart') {
							window.location.href = formData;
						} else if (origin === 'addToCartFromPLP') {
							progress.show();
							return Promise.resolve($.ajax({
								type: 'POST',
								url: location.href
							})).then((response) => {
								var newHTML = document.open("text/html", "replace");
								newHTML.write(response);
								newHTML.close();

								const $btn = formData;

								const dataProduct = extractProductData($btn);
                                const categoryPath = dataProduct.category;

                                setButtonStateAdding($btn, true);

								progress.hide();
								addToCartFromPod(dataProduct).finally(() => {
									if(!categoryPath.toLowerCase().indexOf('chanel')=== -1){
										variant.hideSizeSelection($(e.target).parents('.size-selector-container'));
									}
									setButtonStateAdding($btn, false);
								});
							});
						}
					})
					.catch(() => {
						formValidation.$submitButton.removeClass('btn--loading').empty().text('Iniciar sesión');
						$notification = window.formNotification('error', 'Error', Resources.SERVER_ERROR);
						$form.prepend($notification.css('marginBottom', 20));
					})
					.finally(() => {
						isSending = false;
					});
			});
		},
	});
}

function restorePdpButton() {
	$('[id*="-add-to-cart"]').empty();
	$('[id*="-add-to-cart"]').text('Añadir al carro');
	$('[id*="-add-to-cart"]').removeClass('disabled-button');
}

function addLoadingButtonPdp() {
	var getProductId = document.getElementById("productID").value;
	var $modalProduct = document.getElementById('QuickViewDialog');

	if($('.loader-button').length === 0 && !$modalProduct){
		$('#' + getProductId + '-add-to-cart').append('<div class="loader-button"><span>Agregando al carro</span></div>').addClass('disabled-button');
	} else {
		$('#' + getProductId + '-add-to-cart').append('<div class="loader-button"><span>Actualizando el carro</span></div>').addClass('disabled-button');
	}
	$('span.add-to-cart__label').addClass('hide');
}

function removeDisableButtonClass(){
	var getProductId = document.getElementById("productID").value;
	$('#' + getProductId + '-add-to-cart').removeClass('disabled-button');
}

var addToItemToCartModal = function ( $form,response) {
	$(this).prop('disabled', false);
	var $updateCartButton = $form.find('.js-update-cart');
	var $uuid = $form.find('input[name="uuid"]');
	if ($updateCartButton.length > 0 || ($uuid.length > 0 && $uuid.val().length > 0 && ($uuid.val() == 'null' || $uuid.val() == ""))) {
		if ($updateCartButton.length > 0) {	
			page.redirect(Urls.cartShow);
		} else {
			page.refresh();
		}
	} else {
		// do not close quickview if adding individual item that is part of product set
		// @TODO should notify the user some other way that the add action has completed successfully
		if (!$(this).hasClass('sub-product-item')) {
			if (location.pathname.indexOf('/cart') > 0) {
				page.redirect(Urls.cartShow);
			} else {
				dialog.close();
			}
		}

		var product = {};
		var $qty = 1;
		if ($('body').hasClass('page-product')) {
			$qty = $form.find('input[name="Quantity"]').val() || 1;
			product = $('.pdpForm').data('product');
		} else if (Object.keys(productData).length > 0) {
			$qty = $form.find('select[name="Quantity"]') ? $form.find('select[name="Quantity"]').val() : 1;
			product = productData;
		} else {
			$qty = $form.find('select[name="Quantity"]') ? $form.find('select[name="Quantity"]').val() : 1;
			product = JSON.parse($form[0].dataset.product)[0];
		}

		var withOutWarranty = $('.product-options__item-input-wrap input:checkbox.chkclass:checked');
		if (isEmpty(withOutWarranty)) {
			delete product.dimension36;
			delete product.metric9;
		}

		$('.product-options__item-input-wrap input:checkbox.chkclass:checked').each(function(i, el) {	
			let warrantyName = $(el).data('warranty-name');
			let warrantyPrice = ($(el).data('warranty-price').replace('$','')).replace('.','');
			product.dimension36 = warrantyName;
			product.metric9 = parseInt(warrantyPrice);			
		}); 

		product.quantity = parseInt($qty);
		delete product.image_data;

		var productArr = [product];
		
		window.dataLayer.push({
			'event': 'addToCart',
			'ecommerce': {
				'add': {
					'products': productArr
				}
			}
		});

		if (window.matchMedia('(min-width: 719px)').matches) {
			var $listItem = $(this).parents('.table-products-row-favourite');
			if ($listItem.length > 0) {
				//minicart.show(response, null, $listItem);
				if ($(response).filter('.js-add-to-cart-errors').length === 1) {
					$('.type-of-shipping').hide();
					showModalPdpError(response);
				} else {
					minicart.showAjax(true, $listItem);
				}
			} else {
				if ($(response).filter('.js-unavailable-stock').length === 1) {
					$(this).text($(response).find('span').text()).addClass('disabled');
					catchErrorMessage(response);
					$('.type-of-shipping').hide();
					showModalPdp();
				} else if ($(response).filter('.js-add-to-cart-errors').length === 1) {
					$('.type-of-shipping').hide();
					showModalPdpError(response);
                } else {
                    execSamplingModal(product.id, 'desktop');
				}
			}
		} else {
			if ($(response).filter('.js-unavailable-stock').length === 1) {
				$(this).text($(response).find('span').text()).addClass('disabled');
				catchErrorMessage(response);
				showModalPdp();
			} else if ($(response).filter('.js-add-to-cart-errors').length === 1) {
				$('.type-of-shipping').hide();
				showModalPdpError(response);
            } else {
                execSamplingModal(product.id, 'mobile');
			}
		}
		$(window).click(function () {
			var closeModalFeedback = document.querySelector(".feedback-complete");
			if (closeModalFeedback) {
				hideFeedBackPdp();
			}
		});
	}
	if ($(response).filter('.js-unavailable-stock').length === 1) {
		$(this).prop('disabled', true);
	} else {
		$(this).prop('disabled', false);
	}

	$("input:checkbox.chkclass").each(function(){
			$(this).prop("checked", false);
	});
}.bind(this);

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
	e.preventDefault();
	var $productForms = $('#product-set-list').find('form').toArray();
	Promise.all(_.map($productForms, addItemToCart))
		.then(function (responses) {
			dialog.close();
			// show the final response only, which would include all the other items
			//minicart.show(responses[responses.length - 1]);
			minicart.showAjax();
		});
};

/**
 * @function
 * @description Esta funcion se invoca SOLO cuando el producto NO TIENE garantia asociada
 */
var addToCartFromPLP = function(e) {
	e.preventDefault();
	var sku = e.target.dataset.src;

	var dataProduct = {"pid": sku, "Quantity": 1, "cartAction": "update"};
	return addToCartOneColumn(sku, dataProduct, false, null, null, false);
};

/**
 * 
 * @param {string} sku EL SKU del producto que se agrego al carro
 * @param {object} dataProduct Objeto para agregar un producto en el carro
 * @param {boolean} hasWarrantySelected El usuario selecciono alguna garantia dentro de las opciones disponibles?
 * @param {string} warrantyName Nombre de la garantia
 * @param {} warrantyPrice Precio de la garantia
 * @param {boolean} hasWarrantyDefinition El producto tiene asociada alguna garantia
 */
function addToCartOneColumn(sku, dataProduct, hasWarrantySelected, warrantyName, warrantyPrice, hasWarrantyDefinition, showMessage){
	var addProductURL = Urls.addProduct;
	var addToCartID = `${sku}-add-to-cart`;
	var origin = $(`#${addToCartID}`).attr('data-origin');
	var quantity = 1;
	var index = null;
	var isVariant = false; 
	var products = JSON.parse($(`#${addToCartID}`).attr('data-product'));
	var product = null;

	if($(`#${addToCartID}`).attr('data-isvariant') === "true") isVariant = true;

	if (!$(`#${addToCartID}`).length) {
		addToCartID = `product-content .${warrantyModal.className}`;
		quantity = parseInt($('#Quantity').val(), 10);
		origin = $(`.${warrantyModal.className}`).attr('data-origin');
	}

	if (origin === 'pdp') {
		var productPDP = null;
		index = $(`#${addToCartID}`).attr('data-current-variant');
		quantity = parseInt($('#add-to-cart-quantity').val(), 10);
		dataProduct.Quantity = quantity;
		var recicambioSKU = $('#recicambioSKU').val();
		var armadoSKU = $('#armadoSKU').val();
		var instalacionSKU = $('#instalacionSKU').val();

		if (isVariant) {
			if (products.length && products.length === 1) {
				productPDP = products[0];
			} else {
				productPDP = products[index];
			}
			sku = productPDP.variant;
		}

		const recicambioID = `dwopt_${sku}_${recicambioSKU}`;
		const armadoID = `dwopt_${sku}_${armadoSKU}`;
		const instalacionID = `dwopt_${sku}_${instalacionSKU}`;

		if($(`#${recicambioID}`).prop('checked')) {
			dataProduct[recicambioID] = $(`#${recicambioID}`).val();
		}

		if($(`#${armadoID}`).prop('checked')) {
			dataProduct[armadoID] = $(`#${armadoID}`).val();
		}

		if($(`#${instalacionID}`).prop('checked')) {
			dataProduct[instalacionID] = $(`#${instalacionID}`).val();
		}
	} else if (origin === 'onecolumn') {
		const $itemVariant = $(`#${sku}-swatches`).find('.carousel-colors .selectable');

		$itemVariant.each((i, variant) => {
			const $variant = $(variant);
			if ($variant.hasClass('selected')) {
				const keyParam = `dwvar_${sku}_color`;
				const url = $variant.find('.swatch').attr('data-href');
				const urlParams = getParams(url);
				let variantColor = urlParams[keyParam];
				
				if(products.length) {
					for (var j = 0; j < products.length; j++) {
						if(!isEmpty(products[j].image_data) && !isEmpty(products[j].image_data.color) && products[j].image_data.color == variantColor) {
							$(`#${addToCartID}`).attr('data-current-variant', j);
							index = j;
							break;
						}
					}
				}
			}
		});
	}

	if(isVariant) {
		if(products.length && products.length === 1) {
			product = products;
		} else {
			product = products[index];
		}
	} else {
		if(Array.isArray(product)) {
			product = products[0];
		} else {
			product = products;
		}
	}
	
	if(Array.isArray(products)) {
		index = index || 0;
		product = products[index];
	} else {
		product = products;
	}
	
	dataProduct.pid = product.variant;
	product.quantity = quantity;

	if(hasWarrantySelected){
		product.dimension36 = warrantyName;
		product.metric9 = parseInt(warrantyPrice.toString().replace('$','').replace('.',''));
	} else {
		delete product.dimension36;
		delete product.metric9;
	}
	
	if (hasWarrantyDefinition) {
		product.dimension21 = "True";
	} else {
		delete product.dimension21;
	}

	delete product.image_data;

	var productArr = [product];
	window.dataLayer.push({
		'event': 'addToCart',
		'ecommerce': {
			'add': {
				'products': productArr
			}
		}
	});
	
	$('#' + addToCartID).empty();
	$('#' + addToCartID).addClass('disabled-button');
	$('#' + addToCartID).append('<div class="loader-button"><span>Agregando al carro</span></div>');

	addProductURL = getAddToCartURLVerifyingCMediaSKU(addProductURL);
	return Promise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(addProductURL),
		data: dataProduct
	})).then(function (response) {
		if ($(response).find('#viewMixBasketError').length) {
			return loadMixedBasketModal(dataProduct);
		}
		if (window.matchMedia('(min-width: 719px)').matches) {
			var $listItem = $(this).parents('.table-products-row-favourite');
			if ($listItem.length > 0) {
				if ($(response).filter('.js-add-to-cart-errors').length === 1) {
					$('.type-of-shipping').hide();
					showModalPdpError(response);
				} else {
					minicart.showAjax(true, $listItem);
				}
			} else {
				if ($(response).filter('.js-unavailable-stock').length === 1) {
					$(this).text($(response).find('span').text()).addClass('disabled');
					catchErrorMessage(response);
					$('.type-of-shipping').hide();
					showModalPdp();
				} else if ($(response).filter('.js-add-to-cart-errors').length === 1) {
					$('.type-of-shipping').hide();
					showModalPdpError(response);
				} else {
					var $modalProduct = document.getElementById('QuickViewDialog');
						if (!$modalProduct){
							minicart = require('../../minicart');
							restorePdpButton();
							minicart.showAjax(true);
					}
				}
			}
		} else {
			if ($(response).filter('.js-unavailable-stock').length === 1) {
				$(this).text($(response).find('span').text()).addClass('disabled');
				catchErrorMessage(response);
				showModalPdp();
			} else if ($(response).filter('.js-add-to-cart-errors').length === 1) {
				$('.type-of-shipping').hide();
				showModalPdpError(response);
			} else {
				if(showMessage) {
					startModalAddedToCart();
				} else {
					page.redirect(Urls.cartShow);
				}
			}
		}
		$('#' + addToCartID).empty();
		$('#' + addToCartID).text('Añadir al carro');
		$('#' + addToCartID).removeClass('disabled-button');
	});
}

function addWarrantyProduct ({type, warranty, product}) {
	const warrantyName = `${warranty.key} ${warranty.displayValue}`;
	const showMessage = type !== 'view-cart';
	var dataProduct = {"pid": product.id, "Quantity": 1, "cartAction": "update"};

	if (Number(warranty.ID)) {
		dataProduct[warrantyModal.radioInputName] = warranty.ID;
	}

	addToCartOneColumn(product.id, dataProduct, !!Number(warranty.ID), warrantyName, warranty.price, true, showMessage);
};

function observerHandler(mutationList) {
	for (var i = 0; i < mutationList.length; i++) {
		if ((mutationList[i].type == 'childList' || mutationList[i].type == 'subtree') && $(mutationList[i].target).is('[id^="product-content"]')) {
			if ($(mutationList[i].previousSibling).hasClass('container-fluid panel-mobile-add-to-car')) {
				warrantyModal.init();
			}
		}
	}
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
function initializeEvents($pdpMain) {
    $('body').off('click', '.novios-delete-basket');
    $('body').on('click', '.novios-delete-basket', handleNoviosDeleteBasket);

	setTimeout(function(){image.lazyLoad(); }, 100);
	if ($('.onecolumn').length) {
		const warrantyAddToCartClass = warrantyModal.className;
		warrantyModal.onSubmit = addWarrantyProduct;
		warrantyModal.init();
		$(`.add-to-cart-from-plp:not(.${warrantyAddToCartClass})`).off('click', addToCartFromPLP);
		$(`.add-to-cart-from-plp:not(.${warrantyAddToCartClass})`).on('click', addToCartFromPLP);
	} else if($('.js-plp-result.flex-grid .flex-grid__item').length || $('.js-plp-result .carousel-contenidos').length) {
		$(`.js-select-sizes-from-plp`).on('click', handleSelectSizesFromPLP);
		
		// removed click event if already binded
		$('.add-to-cart-from-plp').off('click', handleAddToCartFromPLP);

		// add click event
		$('.add-to-cart-from-plp').on('click', handleAddToCartFromPLP);
	} else {
		$('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
		if ($pdpMain) {
			const observer = new MutationObserver(observerHandler);

			$pdpMain.each(function () {
				observer.observe(this, { childList: true, subtree: true });
			});

			warrantyModal.onSubmit = addWarrantyProduct;
			
			$pdpMain.unbind('click');

			var $modalProduct = document.getElementById('QuickViewDialog');
        	if ($modalProduct) {
				$pdpMain.on('click', `.add-to-cart`, addToCart);
			} else {
				warrantyModal.init();

				$pdpMain.on('click', `.add-to-cart:not(.${warrantyModal.className})`, addToCart);

				$pdpMain.on('click', '.buy-it-now:not(.js-no-variation-attr-selected)', buyItNow);
			}
		}
		
		$('#add-all-to-cart').on('click', addAllToCart);
	}
}

function handleNoviosDeleteBasket(e) {
    let url = util.ajaxUrl(Urls.replaceNoviosCart);
    const productToAdd = $(this).data('src');
	url = getAddToCartURLVerifyingCMediaSKU(url);
    return Promise.resolve($.ajax({
        type: 'POST',
        url: url,
        data: {
            productToAdd: productToAdd
        }
    })).then(() => {
        const isLargeScreen = window.matchMedia('(min-width: 719px)').matches;

        dialog.close();

        if (isLargeScreen) {
            showMiniCart();
        } else {
            page.redirect(Urls.cartShow);
        }
    });
}

function handleSelectSizesFromPLP(e) {
	e.preventDefault();
	const $target = e.target.tagName === 'SPAN' ? $(e.target.parentElement) : $(e.target);
	const href = $target.data('href');
	const $productTile = $target.parents('.product-tile');

	$('.product-tile').each((i, el) => {
		const $sizesContainer = $(el).find('.size-selector-container');
		variant.hideSizeSelection($sizesContainer);
	});

	variant.updateContent(href, $productTile.find('.js-rollover-container').first(), true);
}

function extractProductData($btn) {
    return {
        pid: $btn.data('src'),
        masterId: $btn.data('id'),
        Quantity: 1,
        nombre: $btn.data('name'),
        category: $btn.data('category'),
        brand: $btn.data('brand'),
        price: $btn.data('price'),
        dimension2: $btn.data('dimension2'),
        dimension3: $btn.data('dimension3'),
        dimension32: $btn.data('dimension32'),
        dimension11: $btn.data('dimension11'),
        dimension33: $btn.data('dimension33'),
        dimension12: $btn.data('dimension12'),
        dimension18: $btn.data('dimension18'),
        dimension19: $btn.data('dimension19'),
        dimension20: $btn.data('dimension20'),
        cartAction: 'update',
        rating: $btn.data('rating'),
        reviewsCount: $btn.data('reviewsCount'),
    };
}

function setButtonStateAdding($btn, adding) {
    if (adding) {
        $btn.empty()
            .addClass('btn--adding')
            .append('<div class="loader-button"><span>Añadiendo al carro</span></div>');
    } else {
        $btn.empty()
            .removeClass('btn--adding')
            .text('Añadir al carro');
    }
}

function handleAddToCartFromPLP(e) {
    e.preventDefault();

    const $btn = $(e.target);
    const dataProduct = extractProductData($btn);
    const categoryPath = dataProduct.category;

    setButtonStateAdding($btn, true);

    addToCartFromPod(dataProduct).finally(() => {
        if(!categoryPath.toLowerCase().indexOf('chanel')=== -1){
            variant.hideSizeSelection($btn.parents('.size-selector-container'));
        }
        setButtonStateAdding($btn, false);
    });
}

function handleAddToCartFromLastMilleModal(e) {
	e.preventDefault();
    const $btn = $(e.target);
    const dataProduct = extractProductData($btn);

    setButtonStateAdding($btn, true);
	
	addToCartFromLastMillePod(dataProduct).finally(() => {
		variant.hideSizeSelection($btn.parents('.size-selector-container'));
        setButtonStateAdding($btn, false);
	});
	
    function addToCartFromLastMillePod(dataProduct) {
        return Promise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: dataProduct,
        })).then(function (response) {
            const $response = $(response);
            const isLargeScreen = window.matchMedia('(min-width: 719px)').matches;
            const $listItem = $(this).parents('.table-products-row-favourite');
            const isUnavailableStock = $response.filter('.js-unavailable-stock').length === 1;
            const hasAddToCartError = $response.filter('.js-add-to-cart-errors').length === 1;

            if (isLargeScreen) {
                if ($listItem.length > 0) {
                    if (hasAddToCartError) {
                        $('.type-of-shipping').hide();
                        showModalPdpError($response);
                    } else {
                        sendAnalyticsAddToCartPLP(dataProduct);
                        window.uiNotification('success', window.Resources.CART_ADD_LAST_MILLE_PRODUCT_TO_CART);
                    }
                } else {
                    if (isUnavailableStock) {
                        updateButtonStateAndShowModal($response, this);
                    } else if (hasAddToCartError) {
                        $('.type-of-shipping').hide();
                        showModalPdpError($response);
                    } else {
                        sendAnalyticsAddToCartPLP(dataProduct);
                        window.uiNotification('success', window.Resources.CART_ADD_LAST_MILLE_PRODUCT_TO_CART, '');
                    }
                }
            } else {
                if (isUnavailableStock) {
                    $(this).text($response.find('span').text()).addClass('disabled');
                    catchErrorMessage(response);
                    showModalPdp();
                } else if (hasAddToCartError) {
                    $('.type-of-shipping').hide();
                    showModalPdpError(response);
                } else {
                    sendAnalyticsAddToCartPLP(dataProduct);
                    window.uiNotification('success', window.Resources.CART_ADD_LAST_MILLE_PRODUCT_TO_CART, '');
                }
            }
    
        });
    }
}

function addToCartFromPod(dataProduct) {
	return Promise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addProduct),
		data: dataProduct
	})).then(function (response) {
        const $response = $(response);
        const isLargeScreen = window.matchMedia('(min-width: 719px)').matches;
        const $listItem = $(this).parents('.table-products-row-favourite');
        const isUnavailableStock = $response.filter('.js-unavailable-stock').length === 1;
        const hasAddToCartError = $response.filter('.js-add-to-cart-errors').length === 1;

        if ($response.find('#viewMixBasketError').length) {
            loadMixedBasketModal(dataProduct);
            return;
        }

        if (isLargeScreen) {
            handleLargeScreenResponse($response, $listItem, isUnavailableStock, hasAddToCartError, dataProduct);
        } else {
            handleSmallScreenResponse($response, isUnavailableStock, hasAddToCartError, dataProduct);
        }
	});
}

function handleLargeScreenResponse($response, $listItem, isUnavailableStock, hasAddToCartError, dataProduct) {
    if ($listItem.length > 0) {
        if (hasAddToCartError) {
            $('.type-of-shipping').hide();
            showModalPdpError($response);
        } else {
            sendAnalyticsAddToCartPLP(dataProduct);
            minicart.showAjax(true, $listItem);
        }
    } else {
        if (isUnavailableStock) {
            updateButtonStateAndShowModal($response, this);
        } else if (hasAddToCartError) {
            $('.type-of-shipping').hide();
        } else {
            sendAnalyticsAddToCartPLP(dataProduct);
            minicart = require('../../minicart');
            minicart.showAjax(true);
        }
    }
}

function handleSmallScreenResponse($response, isUnavailableStock, hasAddToCartError, dataProduct) {
    if (isUnavailableStock) {
        updateButtonStateAndShowModal($response, this);
    } else if (hasAddToCartError) {
        $('.type-of-shipping').hide();
        showModalPdpError($response);
    } else {
        sendAnalyticsAddToCartPLP(dataProduct);
        startModalAddedToCart();
    }
}

function updateButtonStateAndShowModal($response, element) {
    $(element).text($response.find('span').text()).addClass('disabled');
    catchErrorMessage($response);
    $('.type-of-shipping').hide();
    showModalPdp();
}

const sendAnalyticsAddToCartPLP = function(dataProduct){
	var {masterId,nombre,pid,category,brand,price,dimension2,dimension3,dimension32,dimension11,dimension33,dimension12,dimension18,dimension19,dimension20,rating,reviewsCount} = dataProduct;
	var event = {
		'event': 'plpAddToCart',
		'ecommerce': {
			'add': {
				'actionField': {
					'list': 'PLP Category: ' + category, // esto depende del tipo de PLP ( category /search / redirect / evento)
				},
				'products': [{
						'id': masterId,
						'name': nombre,
						'variant': pid,
						'category': category,
						'brand': brand,
						'price': isNaN(price) ? 0 : Number.parseInt(price),
						'dimension2': dimension2,
						'dimension3': dimension3,
						'dimension32': dimension32,
						'dimension11': dimension11,
						'dimension33': dimension33,
						'dimension12': dimension12,
						'dimension18': dimension18? dimension18: '',
						'dimension19': isNaN(dimension19) ? 0 : Number.parseInt(dimension19),
						'dimension20': isNaN(dimension20) ? 0 : Number.parseInt(dimension20),
						'dimension30': brand,
						'dimension41': rating? rating : 0,
						'dimension42': reviewsCount? reviewsCount : 0,
						'quantity': 1
				}]
			}
		}
	};
	
	window.dataLayer.push(event);
}

var getParams = function (url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

function loadMixedBasketModal(productToAdd) {
    const baseUrl = window.Urls.showMixedBasketModal;
    const productToAddParam = encodeURIComponent(JSON.stringify(productToAdd));
    const url = baseUrl + `?productToAdd=${productToAddParam}`;
    dialog.open({
        url: url,
        options: {
            title: window.Resources.GLOBAL_NOVIOS_MODAL_TITLE,
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            height: 618,
            dialogClass: 'ui-dialog-full ui-dialog-auto-height ui-dialog-novios',
            close() {
                $('html').removeClass('ui-scroll-disabled');
            }
        },
    });
    restorePdpButton();
}

exports.init = function ($pdpMain) {
	initializeEvents($pdpMain);
};

exports.addToCartFromPod = addToCartFromPod;
exports.handleAddToCartFromPLP = handleAddToCartFromPLP;
exports.renderLoginModal = renderLoginModal;
exports.handleAddToCartFromLastMilleModal = handleAddToCartFromLastMilleModal;
